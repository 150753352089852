<template>
    <svg id="Grupo_78" data-name="Grupo 78" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.122 12">
        <path id="Caminho_487" data-name="Caminho 487" d="M0,0H1.336V1.336H0Z" transform="translate(0 0)" />
        <path id="Caminho_488" data-name="Caminho 488"
            d="M4.517,1.336H16.932a.467.467,0,0,0,.366-.2.778.778,0,0,0,.152-.472A.778.778,0,0,0,17.3.2a.467.467,0,0,0-.366-.2H4.517a.467.467,0,0,0-.366.2A.778.778,0,0,0,4,.668a.778.778,0,0,0,.152.472A.467.467,0,0,0,4.517,1.336Z"
            transform="translate(-1.327 0)" />
        <path id="Caminho_489" data-name="Caminho 489" d="M0,6H1.336V7.336H0Z" transform="translate(0 -2.445)" />
        <path id="Caminho_490" data-name="Caminho 490"
            d="M16.932,6H4.517a.467.467,0,0,0-.366.2.812.812,0,0,0,0,.945.467.467,0,0,0,.366.2H16.932a.467.467,0,0,0,.366-.2.812.812,0,0,0,0-.945A.467.467,0,0,0,16.932,6Z"
            transform="translate(-1.327 -2.445)" />
        <path id="Caminho_491" data-name="Caminho 491" d="M0,12H1.336v1.336H0Z" transform="translate(0 -4.891)" />
        <path id="Caminho_492" data-name="Caminho 492"
            d="M16.932,12H4.517a.467.467,0,0,0-.366.2.812.812,0,0,0,0,.945.467.467,0,0,0,.366.2H16.932a.467.467,0,0,0,.366-.2.812.812,0,0,0,0-.945A.467.467,0,0,0,16.932,12Z"
            transform="translate(-1.327 -4.891)" />
        <path id="Caminho_493" data-name="Caminho 493" d="M0,18H1.336v1.336H0Z" transform="translate(0 -7.336)" />
        <path id="Caminho_494" data-name="Caminho 494"
            d="M16.932,18H4.517a.467.467,0,0,0-.366.2.812.812,0,0,0,0,.945.467.467,0,0,0,.366.2H16.932a.467.467,0,0,0,.366-.2.812.812,0,0,0,0-.945A.467.467,0,0,0,16.932,18Z"
            transform="translate(-1.327 -7.336)" />
    </svg>
</template>
