<template>
    <svg class="open-calendar-data" id="Icons_Navigation_icon_12_states_" data-name="Icons / Navigation icon (12 states)"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.193 17.193">
        <g id="ic-calendar">
            <rect id="Retângulo_608" data-name="Retângulo 608" width="11" height="11" rx="2" transform="translate(3 4)"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <line id="Linha_31" data-name="Linha 31" y2="3" transform="translate(11 2)" fill="none" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1" />
            <line id="Linha_32" data-name="Linha 32" y2="3" transform="translate(6 2)" fill="none" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1" />
            <line id="Linha_33" data-name="Linha 33" x2="11" transform="translate(3 8)" fill="none" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1" />
            <rect id="Retângulo_609" data-name="Retângulo 609" width="1" height="1" transform="translate(6 11)"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </g>
    </svg>
</template>
