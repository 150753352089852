<template>
    <modal id="add-currency" :show="openModal" :modalName="modalName">
        <template v-slot:header>
            <div class="row col-12">
                <div class="col-6 pl-4">
                    <h2>Currency Info</h2>
                </div>
                <!-- <div class="col-6 pl-5">
                    <h2>Company Info</h2>
                </div> -->
            </div>
            <!-- <h2 v-else>Account Info</h2> -->
        </template>
        <template v-slot:body>
            <div class="ah-wrapper">
                <div class="row body-header">
                    <div class="col-12">
                        <div class="form-row mb-2">
                            <div class="form-group col-md-12">
                                <label class="auth-labels text-uppercase small pl-1" for="name">Name*</label>
                                <div :class="{ error: v$.name.$errors.length }">
                                    <input type="text" class="form-control" :disabled="editingblock" name="name" v-model="v$.name.$model" @input="v$.name.$touch" @keyup="finalCheck" placeholder="ex: Euro">
                                    <div class="input-errors" v-for="error of v$.name.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="form-group col-md-12">
                                <label class="auth-labels text-uppercase small pl-1" for="code">Code*</label>
                                <div :class="{ error: v$.code.$errors.length }">
                                    <input type="text" class="form-control" :disabled="editingblock" name="code" v-model="v$.code.$model" @input="v$.code.$touch" @keyup="finalCheck" placeholder="ex: EUR">
                                    <div class="input-errors" v-for="error of v$.code.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="form-group col-md-12">
                                <label class="auth-labels text-uppercase small pl-1" for="symbol">Symbol*</label>
                                <div :class="{ error: v$.symbol.$errors.length }">
                                    <input type="text" class="form-control" :disabled="editingblock" name="symbol" v-model="v$.symbol.$model" @input="v$.symbol.$touch" @keyup="finalCheck" placeholder="ex: €">
                                    <div class="input-errors" v-for="error of v$.symbol.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div v-if="!editing" class="modal-footer" :style="`justify-content: flex-end'`">
              <button
                class="btn btn-primary btn-save-holiday"
                :class="'btn-add-currency'" v-show="enabledCreateButton"
                @click="onSaveCurrency(instances, true)">
                  Save
               </button>
          </div>
          <div v-else class="modal-footer" :style="`justify-content: flex-end'`">
              <button v-if="editingblock"
                class="btn btn-primary btn-save-holiday"
                :class="'btn-edit-currency'"
                @click="onEdit()">
                  Edit
               </button>
               <button v-else
                class="btn btn-primary btn-save-holiday"
                :class="'btn-add-currency'"
                @click="onSaveCurrency(instances, true)">
                  Save
               </button>
          </div>
        </template>
    </modal>
</template>

<script>

/* :disabled="!isHolidayValid(instances)"
v-model="holidayName" */
import eventHub from '../utils/eventHub'
import ModalVue from './Modal.vue'
import moment from 'moment'
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
/* import _ from 'lodash' */
import Utils from '../utils/Utils'

export default {
    name: 'add-currency-modal',
    props: ['openModal', 'modalName', 'editing'],
    components: {
        modal: ModalVue
    },
    data () {
        return {
            token: localStorage.getItem('accessToken'),
            dpConfig: {
                options: [],
                borderRadius: '5px',
                backgroundColor: 'transparent',
                placeholder: ''
            },
            stConfig: {
                options: [
                    { label: 'Active', value: 1 },
                    { label: 'Pending', value: 0 }
                ],
                borderRadius: '5px',
                backgroundColor: 'transparent',
                placeholder: ''
            },
            name: null,
            code: null,
            symbol: undefined,
            enabledCreateButton: false,
            editingblock: false,
            dataType: 'specific',
            rootCurrencys: null,
            count: 1,
            unAvailCounter: 1,
            operators: [],
            tempInstances: [],
            deleteInstances: [],
            errors: null,
            isFormInit: false,
            disableActions: false
        }
    },
    created () {
         eventHub.$on('edit-currency-data', (data, edit) => {
            this.populate(data, edit)
        })
        eventHub.$on('enable-actions', (condition) => {
            this.disableActions = !condition
        })
    },
    validations () {
    return {
      name: { required, minLength: minLength(2), maxLength: maxLength(100) },
      code: { required, minLength: minLength(1), maxLength: maxLength(4) },
      symbol: { required, minLength: minLength(1), maxLength: maxLength(3) }
    }
  },
  setup: () => ({ v$: useVuelidate() }),
    computed: {
        computeNumberDays () {
            return this.instances
        }
    },
    methods: {
        finalCheck () {
      var formErrors = document.getElementsByClassName('error-msg')
      if (formErrors.length > 0 || this.name === '' || this.code === '' || this.symbol === '' || this.name === null || this.code === null || this.symbol === undefined) {
        this.enabledCreateButton = false
      } else {
        this.enabledCreateButton = true
      }
    },
        async saveCurrencyRequest (payload) {
            const headers = {
                headers: {}
            }
            return await this.axios.post(process.env.VUE_APP_API + '/admin/currency/create', payload, headers)
                    .then(result => {
                        this.updateRates(result.data.data.id)
                        return { ok: true, data: result }
                    })
                    .catch(Utils.$handleHttpErrors)
        },
        async updateRates (id) {
            const headers = {
                headers: {}
            }
            this.axios.get(process.env.VUE_APP_API + '/admin/currency/rates/global_update/' + id, headers)
        },
         async putCurrencyRequest (payload) {
            const headers = {
                headers: {}
            }
            return await this.axios.post(process.env.VUE_APP_API + '/admin/currency/update', payload, headers)
                    .then(result => {
                        return { ok: true, data: result }
                    })
                    .catch(Utils.$handleHttpErrors)
        },
        onEdit () {
        this.editingblock = false
        },
        async onSaveCurrency (instances, close, event) {
            if (this.editing) {
               // payload.append('user_id', this.rootUsers.id)

                if (this.name !== this.rootCurrencys.name || this.code !== this.rootCurrencys.code || this.symbol !== this.rootCurrencys.symbol) {
                    const payload = this.generatePayloadUpdate(this.name, this.code, this.symbol, parseInt(this.rootCurrencys.id))
                    const newResult = await this.putCurrencyRequest(
                    payload
                     )
                    if (newResult?.ok) {
                    eventHub.$emit('request-currency', {
                      closeModal: close, request: true
                    })
                     } else {
                     eventHub.$emit('request-currency', {
                      closeModal: close, request: true
                      })
                    this.showAlert(newResult.error.response.data.msg)
                }
                }
            } else {
            const payload = this.generatePayload(instances, this.name, this.code, this.symbol)
            if (this.dataType === 'specific') {
                const newResult = await this.saveCurrencyRequest(
                    payload
                )
                if (newResult?.ok) {
                    eventHub.$emit('request-currency', {
                      closeModal: close, request: true
                    })
                } else {
                     eventHub.$emit('request-currency', {
                      closeModal: close, request: true
                      })
                    this.showAlert(newResult.error.response.data.msg)
                }
            }
            }
        },
        showAlert (layer) {
         return this.$swal({
          icon: 'warning',
          title: `Error: ${layer}`,
          confirmButtonText: 'Ok'
      })
    },
        generatePayload (instances, name, code, symbol) {
            var formData = new FormData()
            formData.append('name', name)
            formData.append('code', code)
            formData.append('symbol', symbol)
            return formData
        },
        generatePayloadUpdate (name, code, symbol, id) {
             var formData = new FormData()
             formData.append('name', name)
             formData.append('code', code)
             formData.append('symbol', symbol)
             formData.append('from_id', id)
             formData.append('_method', 'PUT')
            return formData
        },
        onChangeDateFrom (_instance, event) {
            const instanceIndex = this.instances.findIndex(inst => inst.id === _instance.id)
            if (instanceIndex > -1) {
                this.instances[instanceIndex].date = this.formatDate(event.target.value)
                this.instances[instanceIndex].availableDates.from = this.formatDate(event.target.value)
                this.instances[instanceIndex].availableDates.to = this.addDaysToDate(event.target.value, _instance.days)
            }
        },
        onChangeDateTo () {
            const diff = this.datesDiff(this.instances[0].availableDates.from, this.instances[0].availableDates.to)
            if (diff) this.instances[0].days = diff
        },
        formatDate (date) {
            return moment(date).format('YYYY-MM-DD')
        },
        addDaysToDate (date, daysToAdd) {
            return moment(date).add(daysToAdd, 'day').format('YYYY-MM-DD')
        },
        datesDiff (d1, d2) {
            const date1 = new Date(d1)
            const date2 = new Date(d2)
            const diffTime = Math.abs(date2 - date1)
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            return diffDays
        },
        async handleTempInstances () {
            if (this.editing) {
                if (this.tempInstances.length > 0) {
                    eventHub.$emit('start-finish-edit', true)
                    for (let index = 0; index < this.tempInstances.length; index++) {
                        const fInstance = this.rootCurrencys.instances.find(
                            (inst) => inst.reference === this.tempInstances[index].reference
                        )
                        const { id } = fInstance || {}
                        if (id) {
                            await this.onDeleteInstance(id)
                        }
                    }
                    eventHub.$emit('end-finish-edit', true)
                }
            }
        },
        populate (data, edit) {
            this.rootCurrencys = { ...data }
            // this.instances = []
            this.name = data.name
            this.code = data.code
            this.editingblock = edit
            this.symbol = data.symbol
        },
        reset () {
           this.rootCurrencys = null
            this.name = null
            this.code = null
            this.mail = null
            this.symbol = null
            this.editingblock = false
            this.status = null
            this.isFormInit = false
            this.disableActions = false
            this.tempInstances = []
            this.deleteInstances = []
        }
    },
    watch: {
        async openModal (newValue, oldValue) {
            if (!newValue) {
                this.reset()
            }
        },
        dataType (newValue, oldValue) {

        }
    },
    unmounted () {
        eventHub.$off('edit-currency-data')
        eventHub.$off('specific-status')
        eventHub.$off('enable-actions')
        eventHub.$off('add-temp-instance')
    }
}
</script>

<style lang="scss">
    #add-currency {

        .temp-hidden {
            display: none;
        }

        .modal {

          //  &__dialog {
                // min-width: 65vw;  // before
               // min-width: 55vw;
            // }

            &__body {

                .ah-wrapper {
                    width: 100%;

                    .instance-errors {
                        width: 100%;
                        font-size: 10px;
                        color: #EF6565;
                        padding: 5px 8px 0;
                        display: none;
                    }

                    .row.body-header {
                        margin: 0;
                    }

                    .holiday-type {
                        padding: 0;
                        display: flex;
                        justify-content: flex-end;
                    }
                    .holiday-name {
                        padding: 0;
                        display: inline-flex;

                        .holiday-commons {
                            display: flex;
                            flex-flow: column;
                            width: 100%;
                            margin-right: 10%;

                            input, select {
                              height: 40px;
                            }
                        }
                    }

                    .custom-input {
                        height: 45px;

                        input[type=radio] ~ label {
                            border: 1px solid transparent;
                            color: $secondary-gray;
                            font-size: 13px !important;
                        }
                    }
                    .custom-input > input[type=radio] ~ label {
                        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
                    }
                    .custom-input > input[type=radio]:checked ~ label {
                        background-color: $secondary-gray;
                        color: #ffffff;

                        svg.open-calendar-data {
                            fill: $secondary-gray !important;
                            stroke: #ffffff;
                        }
                        svg {
                            fill: #ffffff !important;
                        }
                    }

                    .company-logo {
                        background-color:#f9fafc;
                        height:120px;
                        border-radius:10px;
                        border:1px solid #ced4da;
                        padding: 12px;
                    }
                    .form-control {
                        background-color: #f9fafc;
                    }
                    .form-group.add-operator {
                        margin-right: 20px;

                        select {
                            width: 160px;
                            height: 40px;
                        }
                    }
                    .form-group label {
                        font-size: 12px !important;
                        color: $secondary-gray;
                    }
                    .form-control {
                        font-size: 14px;
                    }
                    .form-group .custom-input label {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        height: 45px;
                        background-color: #EFEDFD;
                    }
                    .form-group label.specific-data svg {
                        width: 16px;
                        fill: $secondary-gray;
                        margin-right: 4px;
                    }
                    .form-group label.open-calendar-data svg {
                        width: 22px;
                        stroke: $secondary-gray;
                        fill: #efedfd !important;
                        margin-right: 4px;
                    }

                    .r-action {
                        width: 33px;
                        height: 33px;
                        border-radius: 50%;
                        margin-left: 15px;
                        cursor: pointer;
                    }
                    .r-action.action-disabled {
                        opacity: 0.4;
                    }
                    .t-add-holidays {
                        margin: 25px 0 0;
                    }
                    .table-add-holidays {
                        width: 100%;

                        &-header {
                            display: flex;
                            flex-direction: row;
                            padding: 6px 10px;

                            .t-th:nth-child(1) {
                                // width: 19%;
                                width: 22% !important;
                            }
                            .t-th:not(.row-status) {
                                // width: 12%;
                                width: 16%;
                                padding: 0 5px;
                            }
                            .t-th.row-status {
                                width: 12%;
                                // margin-left: 20px;
                            }
                            .t-th.t-actions {
                                // width: 14%;
                                width: 18%;
                                padding: 0 5px;
                            }
                        }

                        &-body {
                            display: flex;
                            flex-direction: column;
                        }

                        &-row {
                            display: flex;
                            // flex-direction: row;
                            flex-direction: column;
                            border: 1px solid rgba(126, 132, 163, 10%);
                            border-radius: 6px;
                            padding: 6px 10px;
                            margin-bottom: 8px;
                            position: relative;

                            .t-row--wrapper {
                                width: 100%;
                                display: flex;
                                flex-flow: row;
                            }

                            .t-row, .t-actions {

                                .form-control {
                                    height: 40px;
                                    border-radius: 8px;
                                }
                                select.form-control {
                                    padding: 0;
                                    word-wrap: unset;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis;
                                    width: 100%;
                                    white-space: nowrap;
                                }
                            }

                            .t-row:nth-child(1) {
                                // width: 17%;
                                width: 22%;
                            }
                            .t-row {
                                // width: 12%;
                                width: 16%;
                                padding: 0 5px;
                            }
                            .t-row.row-status {
                                width: 12%;
                            }
                            .t-actions {
                                // width: 14%;
                                width: 18%;
                                padding: 0 5px;
                                display: inline-flex;
                                justify-content: flex-end;
                                align-items: center;

                                .a-save {
                                    border: 1px solid #60CE5B;
                                    background: url('../assets/img/true.svg');
                                    background-repeat: no-repeat;
                                    background-position-x: center;
                                    background-position-y: center;
                                }
                                .a-remove {
                                    border: 1px solid #EF6565;
                                    background: url('../assets/img/false.svg');
                                    background-repeat: no-repeat;
                                    background-position-x: center;
                                    background-position-y: center;
                                }
                                .a-clone {
                                    border: 1px solid $secondary-gray;
                                    background: url('../assets/img/clone.svg');
                                    background-repeat: no-repeat;
                                    background-position-x: center;
                                    background-position-y: center;
                                }
                            }
                        }

                        &-row.saved-row {
                            // box-shadow: 0px 0px 3px 0px #60ce5b;
                            // box-shadow: 0px 0px 3px 0px #49a649;
                            box-shadow: 0px 0px 2px 1px #0ebb0e;

                            .saving-msm {
                                position: absolute;
                                font-size: 12px;
                                color: #0ebb0e;
                                right: 7px;
                                bottom: -20px;
                            }
                        }
                    }

                    .open-calendar {
                        width: 100%;

                        label.text-uppercase {
                            color: $secondary-gray;
                            font-size: 12px !important;
                        }

                        .un-dates-wrapper {
                            min-height: 50px;
                            border-radius: 10px;
                            background-color: #f9f9f9;
                            padding: 12px;

                            .unavail-dates {
                                margin-bottom: 15px;

                                &-row {
                                    width: 100%;
                                    display: inline-flex;
                                    flex-direction: row;
                                    padding: 8px;
                                    background-color: #ffffff;
                                    border-radius: 8px;
                                    margin-top: 5px;
                                    border: 1px solid #d8d8d8;

                                    .ud-actions {
                                        width: 40%;
                                        display: inline-flex;
                                        align-items: center;

                                        .ud-clone {
                                            border: 1px solid #d8d8d8;
                                            background: url('../assets/img/clone.svg');
                                            background-repeat: no-repeat;
                                            background-position-x: center;
                                            background-position-y: center;
                                        }
                                        .ud-delete {
                                            border: 1px solid #d8d8d8;
                                            background: url('../assets/img/trash.svg');
                                            background-repeat: no-repeat;
                                            background-position-x: center;
                                            background-position-y: center;
                                        }
                                    }
                                }
                            }

                            .btn-addDate {
                                width: 100%;
                                height: 42px;
                                background-color: #EFEDFD;
                                color: $secondary-gray;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 15px;

                                svg {
                                    margin-right: 6px;
                                    width: 14px;
                                    height: 14px;
                                    fill: $secondary-gray;
                                }
                            }
                        }
                    }

                    input[type="date"] {
                        padding: 5px;
                    }
                    input[type="date"]::-webkit-calendar-picker-indicator {
                        width: 16px;
                        height: 22px;
                        background: url('../assets/img/calendar.svg');
                        background-repeat: no-repeat;
                    }

                    .deposit-header {
                        font-size: 10px;
                        text-transform: lowercase;
                    }
                }
            }

            &__footer {

                .modal-footer {
                    // justify-content: space-between;
                    padding: 0;
                    border-top: none;
                    margin-top: 25px;

                    .btn-add, .btn-primary {
                        font-size: 15px;
                    }
                    .btn-save-holiday,
                    .btn-add {
                        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
                    }
                    .btn-add {
                        background-color: #EFEDFD;
                        color: $secondary-gray;
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 6px;
                            width: 14px;
                            height: 14px;
                            fill: $secondary-gray;
                        }
                        div.i--add-instance {
                            -webkit-mask-image: url('../assets/img/add-holiday/add-instance.svg');
                            mask-image: url('../assets/img/add-holiday/add-instance.svg');
                            background-color: $secondary-gray;
                            background-repeat: no-repeat;
                            background-position-x: center;
                            background-position-y: center;
                            margin-right: 6px;
                            width: 14px;
                            height: 14px;
                        }
                    }
                    .btn-add:hover {
                        background-color: #e0e1ed;
                    }
                    .btn-edit-currency {
                        cursor: no-drop;
                    }
                    .btn-add-currency {
                        cursor: auto;
                    }
                }
            }
        }

        input.type-number::-webkit-outer-spin-button,
        input.type-number::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number].type-number {
            -moz-appearance: textfield;
        }
    }
</style>
