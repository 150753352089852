<template>
  <div class="user-item-content">
      <div class="avatar">
          <img v-if="user.logo" :src="user.logo" alt="user-logo">
          <div v-else class="empty-avatar">A</div>
      </div>
      <div class="title">
          {{ userToStr('company_name') }}
      </div>
      <div class="country">
          <img src="@/assets/img/map-mark.svg" alt="map-mark">
          <span class="text">{{ userToStr('country') }}</span>
      </div>
      <div class="view-profile" @click="onViewProfile">
          <img src="@/assets/img/access-profile.png" alt="access-profile">
          <span class="text">View Profile</span>
      </div>
      <div class="access-handler" :class="accessStatus(user)" @click="!isDisabled() ? handleAccess(user) : () => {}">
          <svg v-if="!hasAccess()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.975 14">
            <path id="Caminho_484" data-name="Caminho 484" d="M11.347,1.091a4.552,4.552,0,0,1,3.532,5.388,4.581,4.581,0,0,1-4.435,3.6,4.455,4.455,0,0,1-1.293-.19l-.8.938L7.972,11H7v1.5l-.5.5H5v1.5l-.5.5h-3L1,14.5V12.193l.146-.353L6.118,6.868a4.462,4.462,0,0,1-.2-1.4A4.526,4.526,0,0,1,11.347,1.09Zm1.318,7.2a3.567,3.567,0,0,0,1.239-2l0,0a3.534,3.534,0,1,0-6.989-.811,3.374,3.374,0,0,0,.239,1.33l-.11.543L2,12.4V14H4V12.5l.5-.5H6V10.5l.5-.5H7.743l.876-1.016.561-.14a3.468,3.468,0,0,0,1.269.238,3.567,3.567,0,0,0,2.217-.795Zm-.838-2.731a1,1,0,1,0-.633.4,1,1,0,0,0,.633-.4Z" transform="translate(-1 -0.999)" fill-rule="evenodd"/>
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path id="Caminho_486" data-name="Caminho 486" d="M8,0a8,8,0,1,0,8,8A8.009,8.009,0,0,0,8,0ZM8,1.231a6.766,6.766,0,0,1,5.154,11.154L3.711,2.75A6.738,6.738,0,0,1,8,1.231ZM2.846,3.615l9.442,9.634A6.773,6.773,0,0,1,2.846,3.615Z" />
          </svg>
          <span class="text">{{ `${userTypePrefix()} Access` }}</span>
      </div>
  </div>
</template>

<script>
import eventHub from '../../utils/eventHub'

export default {
    name: 'card-content',
    props: ['user', 'handleAccess'],
    components: {
    },
    data () {
        return {
            loggedUser: JSON.parse(localStorage.getItem('userData'))
        }
    },
    setup () {
    },
    mounted () {
        this.updateUser()
    },
    methods: {
        accessStatus (_user) {
            switch (_user?.access_status) {
                case 'ACTIVE':
                    return 'revoke'
                case 'NONE':
                    return 'grant'
                case 'PENDING':
                    return _user?.role?.toLowerCase() === 'agent' ? 'pending-agent' : 'pending'
                default:
                    return 'grant'
            }
        },
        isDisabled () {
            return this.user?.role?.toLowerCase() === 'operator' && this.user?.access_status === 'PENDING'
        },
        isPending () {
            return this.user?.access_status === 'PENDING'
        },
        hasAccess () {
            return this.user?.access_status === 'ACTIVE'
        },
        onViewProfile (event) {
            eventHub.$emit('set-access-profile', {
                user: this.user,
                showModal: true
            })
        },
        operatorTypes (data) {
            switch (data?.access_status.toLowerCase()) {
                case 'active':
                    return 'Remove'
                case 'pending':
                    return 'Pending'
                case 'none':
                default:
                    return 'Request'
            }
        },
        agentTypes (data) {
            switch (data?.access_status.toLowerCase()) {
                case 'active':
                    return 'Revoke'
                case 'pending':
                    return 'Grant'
                case 'none':
                default:
                    return 'Give'
            }
        },
        userTypePrefix () {
            switch (this.user?.role?.toLowerCase()) {
                case 'agent':
                    return this.agentTypes(this.user)
                case 'operator':
                    return this.operatorTypes(this.user)
            }
        },
        userToStr (prop) {
            return this.user && this.user[prop] ? this.user[prop] : ''
        },
        updateUser () {
            const user = JSON.parse(localStorage.getItem('userData'))
            this.loggedUser = user
        }
    }
}
</script>

<style lang="scss" scoped>
    .user-item-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .avatar {
            border-radius: 50%;
            margin: 0 auto;
            flex: 1;
            padding-top: 15px;

            img {
                width: 55px;
                height: 55px;
                border-radius: 50%;
            }
        }
        .empty-avatar {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            background-color: $primary;
            opacity: 0.7;
            color: #fff;
            display: flex;
            font-size: 2rem;
            font-weight: 100;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }

        .text {
            font-size: 14px;
            font-weight: 100;
            margin-left: 5px;
        }

        .title {
            font-size: 16px;
            text-align: center;
            flex: 0.4;
        }
        .country {
            text-align: center;
            flex: 0.4;

            img {
                width: 15px;
                height: auto;
            }
            span {

            }
        }
        .view-profile {
            flex: 0.7;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-bottom: 10px;
            cursor: pointer;

            img {
                width: 18px;
                height: auto;
                pointer-events: none;
            }
            span {
                color: #9089C1;
                pointer-events: none;
            }
        }
        .access-handler {
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg, span {
                pointer-events: none;
            }
        }
        .grant {
            background-color: $light-grant-access;

            svg {
                fill: $solid-grant-access;
            }
            .text {
                color: $solid-grant-access;
                font-weight: bold;
            }
        }
        .revoke {
            background-color: $light-revoke-access;

            svg {
                fill: $solid-revoke-access;
            }
            .text {
                color: $solid-revoke-access;
                font-weight: bold;
            }
        }
        .grant:hover {
            background-color: $solid-grant-access;
            svg {
                fill: #fff;
            }
            .text {
                color: #fff;
            }
        }
        .revoke:hover {
            background-color: $solid-revoke-access;
            svg {
                fill: #fff;
            }
            .text {
                color: #fff;
            }
        }

        .pending {
            // background-color: $light-pending-access;
            background-color: $solid-pending-access;

            svg {
                fill: #fff;
                // fill: $solid-pending-access;
            }
            .text {
                color: #fff;
                // color: $solid-pending-access;
                font-weight: bold;
            }
        }
        .pending:hover {
            background-color: $solid-pending-access;
            svg {
                fill: #fff;
            }
            .text {
                color: #fff;
            }
        }
        .pending-agent {
            background-color: $light-pending-access;
            // background-color: $solid-pending-access;

            svg {
                // fill: #fff;
                fill: $solid-pending-access;
            }
            .text {
                // color: #fff;
                color: $solid-pending-access;
                font-weight: bold;
            }
        }
        .pending-agent:hover {
            background-color: $solid-pending-access;
            svg {
                fill: #fff;
            }
            .text {
                color: #fff;
            }
        }
    }
</style>
