<template>
    <modal id="calendarize-holidays" :show="openModal" :modalName="modalName">
        <template v-slot:header>
            <h2>Calendarize Holidays</h2>
        </template>
        <template v-slot:body>
            <CalendarSelect v-model="calendarId" type="options" />
        </template>
        <template v-slot:footer>
            <div class="modal-footer" :style="`justify-content: ${dataType === 'specific' ? 'space-between' : 'flex-end'}`">
                <button class="btn btn-save" @click="onSave">
                    Save
                </button>
            </div>
        </template>
    </modal>
</template>

<script>
import eventHub from '../utils/eventHub'
import ModalVue from './Modal.vue'
import CalendarSelect from './CalendarSelect.vue'

export default {
    name: 'calendarize-holidays-modal',
    props: ['openModal', 'modalName', 'userRole', 'holidays'],
    components: {
        modal: ModalVue,
        CalendarSelect
    },
    data () {
        return {
            dataType: 'flex-end',
            companyCalendars: [],
            calendarId: null
        }
    },
    methods: {
        setHolidaysToCalendar (holiday) {
            const config = {
                method: 'PATCH',
                url: process.env.VUE_APP_API + `/holiday/${holiday.id}/calendar/${this.calendarId}`
            }

            this.axios(config)
                .then((response) => {
                    if (response?.status === 200) {
                        eventHub.$emit('calendarize-holidays-modal')
                        eventHub.$emit('refresh-holidays', true)
                        this.calendarId = null
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        onSave () {
            this.holidays.forEach((h) => this.setHolidaysToCalendar(h))
        }
    }
}
</script>
<style lang="scss" scoped>
.modal {
    &__body {
        //
    }

    &__footer {

        .modal-footer {
            // justify-content: space-between;
            padding: 0;
            border-top: none;
            margin-top: 25px;

            .btn-save {
                font-size: 15px;
            }

            .btn-save {
                box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
            }

            .btn-save {
                background-color: #EFEDFD;
                color: $secondary-gray;
                display: flex;
                align-items: center;
            }

            .btn-save:hover {
                background-color: #e0e1ed;
            }
        }
    }
}
</style>
