<template>
    <modal id="add-users" :show="openModal" :modalName="modalName">
        <template v-slot:header>
            <div class="row col-12">
                <div class="col-6 pl-4">
                    <h2>Account Info</h2>
                </div>
                <div class="col-6 pl-5">
                    <h2>Company Info</h2>
                </div>
            </div>
            <!-- <h2 v-else>Account Info</h2> -->
        </template>
        <template v-slot:body>
            <div class="ah-wrapper">
                <div class="row body-header">
                    <div class="col-6">
                        <div class="form-row mb-2">
                            <div class="form-group col-md-6">
                                <label class="auth-labels text-uppercase small pl-1" for="lastName">First name</label>
                                <div :class="{ error: v$.firstName.$errors.length }">
                                <!-- <input type="text"
                                    v-model="firstName"
                                    class="form-control"
                                    :style="`width: ${dataType === 'specific' ? '100%' : '310px'}`" :disabled="editingblock"> -->
                                    <input type="text" class="form-control" :disabled="editingblock" :style="`width: ${dataType === 'specific' ? '100%' : '310px'}`" name="firstName" v-model="v$.firstName.$model" @input="v$.firstName.$touch" placeholder="ex: John">
                                    <div class="input-errors" v-for="error of v$.firstName.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="auth-labels text-uppercase small pl-1" for="lastName">Last name</label>
                                    <div :class="{ error: v$.lastName.$errors.length }">
                                    <input type="text" class="form-control" :disabled="editingblock" name="lastName" v-model="v$.lastName.$model" @input="v$.lastName.$touch" placeholder="ex: Doe">
                                    <div class="input-errors" v-for="error of v$.lastName.$errors" :key="error.$uid">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="form-group col-md-12">
                                <label class="auth-labels text-uppercase small pl-1" for="mail">Email address</label>
                                <div :class="{ error: v$.mail.$errors.length }">
                                    <input type="mail" class="form-control" :disabled="editingblock" name="mail" v-model="v$.mail.$model" @input="v$.mail.$touch" placeholder="ex: john@example.com">
                                    <div class="input-errors" v-for="error of v$.mail.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="form-group col-md-12">
                                <label class="auth-labels text-uppercase small pl-1" for="passwd">Password</label>
                                <div :class="{ error: v$.passwd.$errors.length }">
                                    <input type="password" class="form-control" :disabled="editingblock" name="passwd" v-model="v$.passwd.$model" @input="v$.passwd.$touch"  placeholder="ex: Password123@">
                                    <div class="input-errors" v-for="error of v$.passwd.$errors" :key="error.$uid">
                                    <div v-if="error.$uid === 'passwd-strongPassword'" class="error-msg">Please use at least 1 lower case, 1 uppercase, 1 number and 1 special character.</div>
                                    <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="form-group col-md-12">
                                <label class="auth-labels text-uppercase small pl-1" for="pass_conf">Confirm Password</label>
                                <div :class="{ error: v$.pass_conf.$errors.length }">
                                    <input type="password" class="form-control" :disabled="editingblock" name="pass_conf" v-model="v$.pass_conf.$model" @input="v$.pass_conf.$touch" placeholder="ex: Password123@">
                                    <div class="input-errors" v-for="error of v$.pass_conf.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="form-group col-md-6">
                                <label class="text-uppercase small pl-1">ROLE</label>
                                <select :disabled="editingblock" class="form-control" v-model="role_id">
                                    <option
                                    v-for="option of dpConfig.options"
                                    :key="option"
                                    v-bind:value="option">{{ option }}
                                    </option>
                                </select>
                                <div class="input-errors" v-for="error of v$.role_id.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="text-uppercase small pl-1">STATUS</label>
                                <select :disabled="editingblock" class="form-control" v-model="status">
                                    <option
                                    v-for="option of stConfig.options"
                                    :key="option.value"
                                    v-bind:value="option.value">{{ option.label }}
                                    </option>
                                </select>
                                <div class="input-errors" v-for="error of v$.status.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-row mb-2">
                            <div class="form-group col-md-12">
                            <label class="auth-labels text-uppercase small pl-1" for="company_name">Company name</label>
                            <div :class="{ error: v$.company_name.$errors.length }">
                                <input :disabled="editingblock" type="text" class="form-control" name="company_name" v-model="v$.company_name.$model" @input="v$.company_name.$touch"
                                 placeholder="ex: Facebook Inc.">
                                <div class="input-errors" v-for="error of v$.company_name.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div v-if="editingblock" class="form-group col-md-12">
                                <label class="auth-labels text-uppercase small pl-1" for="country_id">Country</label>
                                <input type="text" :disabled="true"
                                    class="form-control"
                                    name="company_name"
                                    :value="country">
                            </div>
                            <div v-else class="form-group col-md-12">
                                <label class="auth-labels text-uppercase small pl-1" for="country_id">Country</label>
                                <autocomplete :disabled="editingblock" :value="country" :items="countries"/>
                            </div>
                        </div>
                        <div class="form-group mt--1" id="slim-cropper-wrapper">
                        <label class="auth-labels text-uppercase small pl-1" for="slim">Company logo</label>
                        <slim-cropper class="slim-wrapper form-control w-100 d-flex p-4 h-auto bg-white"  :options="slimOptions">
                            <div v-if="!imgLogo" ref="preview" class="img-preview rounded-circle mb-2">A</div>
                            <div v-else ref="preview" class="img-preview rounded-circle mb-2"><img :src="imgLogo" /></div>
                            <div class="d-flex flex-column ml-4">
                            <div class="position-relative custom-input w-100">
                                <input type="file" id="slim-image-container" accept="image/*" class="btn btn-primary" name="slim" ref="file">
                                <label for="slim" class="file-uploader"><i class="far fa-2x fa-image mr-2"></i><span>Upload or drop a file...</span></label>
                                <label for="slim" class="file-uploader-mobile"><i class="far fa-2x fa-image mr-2"></i></label>
                            </div>
                            <small class="mt-3 file-helper w-100">For optimum results please select a JPG or PNG that is square in proportions and a minimum of<br>300 x 300px.</small>
                            </div>
                        </slim-cropper>
                        </div>
                        <!-- <div class="form-row pl-2" style="margin-top:10px">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                <label class="text-uppercase small pl-1" for="exampleCheck1">VERIFY</label>
                            </div>
                        </div> -->
                        <!-- <div class="form-row pl-3">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label" for="exampleCheck1">Send Email</label>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div v-if="!editing" class="modal-footer" :style="`justify-content: flex-end'`">
              <button
                class="btn btn-primary btn-save"
                v-show="!formHasErrors"
                @click="onSaveUsers(instances, true)">
                  Save
               </button>
          </div>
          <div v-else class="modal-footer" :style="`justify-content: flex-end'`">
              <button v-if="editingblock"
                class="btn btn-primary btn-save"
                @click="onEdit()">
                  Edit
               </button>
               <button v-else
                class="btn btn-primary btn-save"
                @click="onSaveUsers(instances, true)">
                  Save
               </button>
          </div>
        </template>
    </modal>
</template>

<script>

/* :disabled="!isHolidayValid(instances)"
v-model="holidayName" */
import eventHub from '../utils/eventHub'
import Autocomplete from '../components/Autocomplete.vue'
import Slim from '../vendor/slim/slim.vue'
import ModalVue from './Modal.vue'
import moment from 'moment'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength, sameAs, helpers } from '@vuelidate/validators'
/* import _ from 'lodash' */
import Utils from '../utils/Utils'

export default {
    name: 'add-users-modal',
    props: ['openModal', 'modalName', 'editing'],
    components: {
        modal: ModalVue,
        'slim-cropper': Slim,
        autocomplete: Autocomplete
    },
    data () {
        return {
            token: localStorage.getItem('accessToken'),
            dpConfig: {
                options: [],
                borderRadius: '5px',
                backgroundColor: 'transparent',
                placeholder: ''
            },
            stConfig: {
                options: [
                    { label: 'Active', value: 1 },
                    { label: 'Pending', value: 0 }
                ],
                borderRadius: '5px',
                backgroundColor: 'transparent',
                placeholder: ''
            },
            firstName: null,
            lastName: null,
            countries: undefined,
            countriesObject: undefined,
            mail: null,
            passwd: '',
            pass_conf: '',
            editingblock: false,
            company_name: null,
            country: null,
            country_id: null,
            role_id: null,
            status: null,
            role: null,
            imgLogo: undefined,
            imgFile: null,
            imageName: null,
            dataType: 'specific',
            rootUsers: null,
            count: 1,
            unAvailCounter: 1,
            slimOptions: {
                ratio: '1:1',
                instantEdit: true,
                buttonEditLabel: '',
                buttonRemoveLabel: '',
                size: '300, 300',
                maxFileSize: 0.3,
                didRemove: this.didRemove,
                didConfirm: this.assignFile
            },
            instanceModel: {
                id: 1,
                deposit: 0,
                status: 1,
                availableDates: {
                    from: '',
                    to: ''
                },
                unavailableDates: []
            },
            operators: [],
            tempInstances: [],
            deleteInstances: [],
            errors: null,
            isFormInit: false,
            disableActions: false
        }
    },
    created () {
        this.axios
            .get(process.env.VUE_APP_API + '/aux/countries')
            .then((response) => {
                this.countries = Object.values(response.data.data)
                this.countriesObject = response.data.data
            })

        this.axios
            .get(process.env.VUE_APP_API + '/aux/roles')
            .then((response) => {
                // this.dpConfig.options = Object.values(response.data.data)
                this.dpConfig.options = response.data.data
            })
            eventHub.$on('specific-status', (values) => {
                const { value, id } = values
                if (id) {
                    const instIndex = this.instances.findIndex(inst => inst.id === id)
                    if (instIndex > -1) {
                        this.instances[instIndex].status = value ? 1 : 0
                    }
                }
            })

        eventHub.$on('edit-user-data', (data, edit) => {
            this.populate(data, edit)
        })
        eventHub.$on('enable-actions', (condition) => {
            this.disableActions = !condition
        })
    },
    validations () {
        return {
            firstName: { required, minLength: minLength(2), maxLength: maxLength(100) },
            lastName: { required, minLength: minLength(2), maxLength: maxLength(100) },
            company_name: { required, minLength: minLength(2), maxLength: maxLength(100) },
            mail: {
                required: helpers.withMessage('Email is required.', required),
                email: helpers.withMessage('This email format is not valid.', email),
                maxLength: maxLength(100)
            },
            passwd: {
                required,
                minLength: helpers.withMessage('Your password should be at least 8 long', minLength(8)),
                strongPassword (passwd) {
                    return (/[a-z]/.test(passwd) && /[A-Z]/.test(passwd) && /[0-9]/.test(passwd) && /\W|_/.test(passwd))
                }
            },
            pass_conf: {
                required,
                sameAsPassword: helpers.withMessage('Your password fields dont match', sameAs(this.passwd))
            },
            role_id: { required },
            status: { required },
            country_id: { required }
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    computed: {
        computeNumberDays () {
            return this.instances
        },
        formHasErrors () {
            return (
                (this.v$?.errors?.length ?? 0) > 0 ||
                (this.country ?? '') === '' ||
                this.imgFile === null
            )
        }
    },
    methods: {
        didRemove (data) {
            this.imgLogo = undefined
        },
        async assignFile (e) {
            this.imgFile = e.input.file
            this.imageName = e.input.name
        },
        async saveUsersRequest (payload, _option) {
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) return false

            const headers = {
                headers: {

                }
            }
            return await this.axios.post(process.env.VUE_APP_API + `/admin/usr/${_option || 'create'}`, payload, headers)
                    .then(result => {
                        return { ok: true, data: result }
                    })
                    .catch(error => {
                        const errorData = error.response.data
                        if (errorData?.data !== undefined) {
                            if (errorData.data?.pword !== undefined) {
                                this.showAlert(errorData.data.pword)
                            }
                        } else if (errorData?.msg) {
                            this.showAlert(errorData.msg)
                        } else {
                            return Utils.$handleHttpErrors
                        }
                    })
        },
        onEdit () {
            this.editingblock = false
        },
        async onSaveUsers (instances, close, event) {
            switch (this.role_id) {
                case 'SuperAdmin' : this.role = 1
                break
                case 'Admin' : this.role = 2
                break
                case 'Agent' : this.role = 3
                break
                case 'Operator' : this.role = 4
                break
                case 'Customer' : this.role = 5
                break
                case 'Guest' : this.role = 6
                break
                }
            if (this.editing) {
               // payload.append('user_id', this.rootUsers.id)

                if (this.role === null) {
                switch (this.role_id) {
                case 'SuperAdmin' : this.role = 1
                break
                case 'Admin' : this.role = 2
                break
                case 'Agent' : this.role = 3
                break
                case 'Operator' : this.role = 4
                break
                case 'Customer' : this.role = 5
                break
                case 'Guest' : this.role = 6
                break
                }
                }
                if (this.firstName !== this.rootUsers.first_name) {
                    const payload = this.generatePayloadUpdate(this.firstName, 'first_name', parseInt(this.rootUsers.id))
                    const newResult = await this.saveUsersRequest(
                    payload, 'update/' + this.rootUsers.id
                     )
                    if (newResult?.ok) {
                    eventHub.$emit('request-users', {
                      closeModal: close, request: true
                    })
                     } else {
                     eventHub.$emit('request-users', {
                      closeModal: close, request: true
                      })
                    this.showAlert(newResult.error.response.data.msg)
                }
                }
                 if (this.lastName !== this.rootUsers.last_name) {
                    const payload = this.generatePayloadUpdate(this.lastName, 'last_name', parseInt(this.rootUsers.id))
                    const newResult = await this.saveUsersRequest(
                    payload, 'update/' + this.rootUsers.id
                     )
                    if (newResult?.ok) {
                    eventHub.$emit('request-users', {
                      closeModal: close, request: true
                    })
                     } else {
                     eventHub.$emit('request-users', {
                      closeModal: close, request: true
                      })
                    this.showAlert(newResult.error.response.data.msg)
                }
                }
                 if (this.mail !== this.rootUsers.email) {
                    const payload = this.generatePayloadUpdate(this.mail, 'email', parseInt(this.rootUsers.id))
                    const newResult = await this.saveUsersRequest(
                    payload, 'update/' + this.rootUsers.id
                     )
                    if (newResult?.ok) {
                    eventHub.$emit('request-users', {
                      closeModal: close, request: true
                    })
                     } else {
                     eventHub.$emit('request-users', {
                      closeModal: close, request: true
                      })
                    this.showAlert(newResult.error.response.data.msg)
                }
                }
                 if (this.passwd !== '!1Pssword') {
                    const payload = this.generatePayloadUpdate(this.passwd, 'pword', parseInt(this.rootUsers.id), 'pword_confirmation')
                    const newResult = await this.saveUsersRequest(
                    payload, 'update/' + this.rootUsers.id
                     )
                    if (newResult?.ok) {
                    eventHub.$emit('request-users', {
                      closeModal: close, request: true
                    })
                     } else {
                     eventHub.$emit('request-users', {
                      closeModal: close, request: true
                      })
                    this.showAlert(newResult.error.response.data.msg)
                }
                }
                 if (this.company_name !== this.rootUsers.name) {
                    const payload = this.generatePayloadUpdate(this.company_name, 'company_name', parseInt(this.rootUsers.id))
                    const newResult = await this.saveUsersRequest(
                    payload, 'update/' + this.rootUsers.id
                     )
                    if (newResult?.ok) {
                    eventHub.$emit('request-users', {
                      closeModal: close, request: true
                    })
                     } else {
                     eventHub.$emit('request-users', {
                      closeModal: close, request: true
                      })
                    this.showAlert(newResult.error.response.data.msg)
                }
                }
                 if (this.country !== this.rootUsers.country) {
                    const payload = this.generatePayloadUpdate(this.country, 'company_country', parseInt(this.rootUsers.id))
                    const newResult = await this.saveUsersRequest(
                    payload, 'update/' + this.rootUsers.id
                     )
                    if (newResult?.ok) {
                    eventHub.$emit('request-users', {
                      closeModal: close, request: true
                    })
                     } else {
                     eventHub.$emit('request-users', {
                      closeModal: close, request: true
                      })
                    this.showAlert(newResult.error.response.data.msg)
                }
                }
                 if (this.role_id !== this.rootUsers.role) {
                    const payload = this.generatePayloadUpdate(this.role, 'role_id', parseInt(this.rootUsers.id))
                    const newResult = await this.saveUsersRequest(
                    payload, 'update/' + this.rootUsers.id
                     )
                    if (newResult?.ok) {
                    eventHub.$emit('request-users', {
                      closeModal: close, request: true
                    })
                     } else {
                     eventHub.$emit('request-users', {
                      closeModal: close, request: true
                      })
                    this.showAlert(newResult.error.response.data.msg)
                }
                }
                if (this.status !== this.rootUsers.status) {
                    let statusenv
                    switch (this.status) {
                        case 0 : statusenv = false
                        break
                        case 1 : statusenv = true
                        break
                    }
                   const payload = this.generatePayloadUpdate(statusenv, 'status', parseInt(this.rootUsers.id))
                    const newResult = await this.saveUsersRequest(
                    payload, 'update/' + this.rootUsers.id
                     )
                    if (newResult?.ok) {
                    eventHub.$emit('request-users', {
                      closeModal: close, request: true
                    })
                     } else {
                     eventHub.$emit('request-users', {
                      closeModal: close, request: true
                      })
                    this.showAlert(newResult.error.response.data.msg)
                }
                }
                if (this.imgFile !== null) {
                    const payload = this.generatePayloadUpdateLogo(this.imgFile, 'logo', parseInt(this.rootUsers.id), this.imageName)
                    const newResult = await this.saveUsersRequest(
                    payload, 'update/' + this.rootUsers.id
                     )
                    if (newResult?.ok) {
                    eventHub.$emit('request-users', {
                      closeModal: close, request: true
                    })
                     } else {
                     eventHub.$emit('request-users', {
                      closeModal: close, request: true
                      })
                    this.showAlert(newResult.error.response.data.msg)
                }
                }
            } else {
            const payload = this.generatePayload(instances, this.firstName, this.lastName, this.mail, this.passwd, this.company_name, this.country, this.role, this.imgFile, this.imageName)
            if (this.dataType === 'specific') {
                const newResult = await this.saveUsersRequest(
                    payload, 'create'
                )
                if (newResult?.ok) {
                    eventHub.$emit('request-users', {
                      closeModal: close, request: true
                    })
                } else {
                     eventHub.$emit('request-users', {
                      closeModal: false, request: true
                      })
                }
            }
            }
        },
        showAlert (layer) {
         return this.$swal({
          icon: 'warning',
          title: `Error: ${layer}`,
          confirmButtonText: 'Ok'
      })
    },
        generatePayload (instances, firstName, lastName, mail, password, companyName, country, roleId, imgLogo, imgName) {
            const userData = JSON.parse(localStorage.getItem('userData'))
            var formData = new FormData()
            formData.append('first_name', firstName)
            formData.append('last_name', lastName)
            formData.append('email', mail)
            formData.append('pword', password)
            formData.append('pword_confirmation', password)
            formData.append('status', this.status)
            formData.append('company_name', companyName)
            formData.append('logo', imgLogo, imgName)
            formData.append('country', country)
            formData.append('design', userData.design)
            formData.append('role_id', roleId)
            formData.append('verified', 1)
            formData.append('send_emails', 1)
            return formData
        },
        generatePayloadUpdate (dados, type, id, passconf) {
             var formData = new FormData()
             formData.append(type, dados)
             formData.append(passconf, dados)
             formData.append('type', type)
             formData.append('_method', 'PUT')
             formData.append('verified', 1)
             formData.append('send_emails', 1)
             formData.append('user_id', id)
            return formData
        },
        generatePayloadUpdateLogo (dados, type, id, namelogo) {
             var formData = new FormData()
             formData.append(type, dados, namelogo)
             formData.append('type', type)
             formData.append('_method', 'PUT')
             formData.append('send_emails', 1)
             formData.append('user_id', id)
            return formData
        },
        onChangeDateFrom (_instance, event) {
            const instanceIndex = this.instances.findIndex(inst => inst.id === _instance.id)
            if (instanceIndex > -1) {
                this.instances[instanceIndex].date = this.formatDate(event.target.value)
                this.instances[instanceIndex].availableDates.from = this.formatDate(event.target.value)
                this.instances[instanceIndex].availableDates.to = this.addDaysToDate(event.target.value, _instance.days)
            }
        },
        onChangeDateTo () {
            const diff = this.datesDiff(this.instances[0].availableDates.from, this.instances[0].availableDates.to)
            if (diff) this.instances[0].days = diff
        },
        formatDate (date) {
            return moment(date).format('YYYY-MM-DD')
        },
        addDaysToDate (date, daysToAdd) {
            return moment(date).add(daysToAdd, 'day').format('YYYY-MM-DD')
        },
        datesDiff (d1, d2) {
            const date1 = new Date(d1)
            const date2 = new Date(d2)
            const diffTime = Math.abs(date2 - date1)
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            return diffDays
        },
        async handleTempInstances () {
            if (this.editing) {
                if (this.tempInstances.length > 0) {
                    eventHub.$emit('start-finish-edit', true)
                    for (let index = 0; index < this.tempInstances.length; index++) {
                        const fInstance = this.rootUsers.instances.find(
                            (inst) => inst.reference === this.tempInstances[index].reference
                        )
                        const { id } = fInstance || {}
                        if (id) {
                            await this.onDeleteInstance(id)
                        }
                    }
                    eventHub.$emit('end-finish-edit', true)
                }
            }
        },
        populate (data, edit) {
            this.rootUsers = { ...data }
            // this.instances = []
            this.firstName = data.first_name
            this.lastName = data.last_name
            this.editingblock = edit
            this.mail = data.email
            this.passwd = '!1Pssword'
            this.pass_conf = '!1Pssword'
            // password: null,
            // pass_conf: null,
            this.company_name = data.name
            this.country = data.country
            this.imgLogo = data.logo
            this.role_id = data.role
            this.status = data.status
        },
        reset () {
           this.rootUsers = null
            this.firstName = null
            this.lastName = null
            this.mail = null
            this.passwd = null
            this.pass_conf = null
            this.company_name = null
            this.country = null
            this.imgLogo = undefined
            this.role_id = null
            this.editingblock = false
            this.status = null
            this.isFormInit = false
            this.disableActions = false
            this.tempInstances = []
            this.deleteInstances = []
        }
    },
    watch: {
        async openModal (newValue, oldValue) {
            if (!newValue) {
                this.reset()
            }
        }
    },
    mounted () {
        eventHub.$on('set-country', (e) => {
            for (const [key, value] of Object.entries(this.countriesObject)) {
                if (value === e.country) {
                    this.country_id = parseInt(key)
                    this.country = value
                    setTimeout(() => {}, 100)
                }
            }
        })
    },
    unmounted () {
        eventHub.$off('edit-user-data')
        eventHub.$off('specific-status')
        eventHub.$off('enable-actions')
        eventHub.$off('add-temp-instance')
    }
}
</script>

<style lang="scss">
    #add-users {

        .temp-hidden {
            display: none;
        }

        .modal {

            &__dialog {
                // min-width: 65vw;  // before
                min-width: 55vw;
            }

            &__body {

                .ah-wrapper {
                    width: 100%;

                    .instance-errors {
                        width: 100%;
                        font-size: 10px;
                        color: #EF6565;
                        padding: 5px 8px 0;
                        display: none;
                    }

                    .row.body-header {
                        margin: 0;
                    }

                    .holiday-type {
                        padding: 0;
                        display: flex;
                        justify-content: flex-end;
                    }
                    .holiday-name {
                        padding: 0;
                        display: inline-flex;

                        .holiday-commons {
                            display: flex;
                            flex-flow: column;
                            width: 100%;
                            margin-right: 10%;

                            input, select {
                              height: 40px;
                            }
                        }
                    }

                    .custom-input {
                        height: 45px;

                        input[type=radio] ~ label {
                            border: 1px solid transparent;
                            color: $secondary-gray;
                            font-size: 13px !important;
                        }
                    }
                    .custom-input > input[type=radio] ~ label {
                        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
                    }
                    .custom-input > input[type=radio]:checked ~ label {
                        background-color: $secondary-gray;
                        color: #ffffff;

                        svg.open-calendar-data {
                            fill: $secondary-gray !important;
                            stroke: #ffffff;
                        }
                        svg {
                            fill: #ffffff !important;
                        }
                    }

                    .company-logo {
                        background-color:#f9fafc;
                        height:120px;
                        border-radius:10px;
                        border:1px solid #ced4da;
                        padding: 12px;
                    }
                    .form-control {
                        background-color: #f9fafc;
                    }
                    .form-group.add-operator {
                        margin-right: 20px;

                        select {
                            width: 160px;
                            height: 40px;
                        }
                    }
                    .form-group label {
                        font-size: 12px !important;
                        color: $secondary-gray;
                    }
                    .form-control {
                        font-size: 14px;
                    }
                    .form-group .custom-input label {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        height: 45px;
                        background-color: #EFEDFD;
                    }
                    .form-group label.specific-data svg {
                        width: 16px;
                        fill: $secondary-gray;
                        margin-right: 4px;
                    }
                    .form-group label.open-calendar-data svg {
                        width: 22px;
                        stroke: $secondary-gray;
                        fill: #efedfd !important;
                        margin-right: 4px;
                    }

                    .r-action {
                        width: 33px;
                        height: 33px;
                        border-radius: 50%;
                        margin-left: 15px;
                        cursor: pointer;
                    }
                    .r-action.action-disabled {
                        opacity: 0.4;
                    }
                    .t-add-holidays {
                        margin: 25px 0 0;
                    }
                    .table-add-holidays {
                        width: 100%;

                        &-header {
                            display: flex;
                            flex-direction: row;
                            padding: 6px 10px;

                            .t-th:nth-child(1) {
                                // width: 19%;
                                width: 22% !important;
                            }
                            .t-th:not(.row-status) {
                                // width: 12%;
                                width: 16%;
                                padding: 0 5px;
                            }
                            .t-th.row-status {
                                width: 12%;
                                // margin-left: 20px;
                            }
                            .t-th.t-actions {
                                // width: 14%;
                                width: 18%;
                                padding: 0 5px;
                            }
                        }

                        &-body {
                            display: flex;
                            flex-direction: column;
                            max-height: 50vh;
                            overflow-y: auto;
                        }

                        &-row {
                            display: flex;
                            // flex-direction: row;
                            flex-direction: column;
                            border: 1px solid rgba(126, 132, 163, 10%);
                            border-radius: 6px;
                            padding: 6px 10px;
                            margin-bottom: 8px;
                            position: relative;

                            .t-row--wrapper {
                                width: 100%;
                                display: flex;
                                flex-flow: row;
                            }

                            .t-row, .t-actions {

                                .form-control {
                                    height: 40px;
                                    border-radius: 8px;
                                }
                                select.form-control {
                                    padding: 0;
                                    word-wrap: unset;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis;
                                    width: 100%;
                                    white-space: nowrap;
                                }
                            }

                            .t-row:nth-child(1) {
                                // width: 17%;
                                width: 22%;
                            }
                            .t-row {
                                // width: 12%;
                                width: 16%;
                                padding: 0 5px;
                            }
                            .t-row.row-status {
                                width: 12%;
                            }
                            .t-actions {
                                // width: 14%;
                                width: 18%;
                                padding: 0 5px;
                                display: inline-flex;
                                justify-content: flex-end;
                                align-items: center;

                                .a-save {
                                    border: 1px solid #60CE5B;
                                    background: url('../assets/img/true.svg');
                                    background-repeat: no-repeat;
                                    background-position-x: center;
                                    background-position-y: center;
                                }
                                .a-remove {
                                    border: 1px solid #EF6565;
                                    background: url('../assets/img/false.svg');
                                    background-repeat: no-repeat;
                                    background-position-x: center;
                                    background-position-y: center;
                                }
                                .a-clone {
                                    border: 1px solid $secondary-gray;
                                    background: url('../assets/img/clone.svg');
                                    background-repeat: no-repeat;
                                    background-position-x: center;
                                    background-position-y: center;
                                }
                            }
                        }

                        &-row.saved-row {
                            // box-shadow: 0px 0px 3px 0px #60ce5b;
                            // box-shadow: 0px 0px 3px 0px #49a649;
                            box-shadow: 0px 0px 2px 1px #0ebb0e;

                            .saving-msm {
                                position: absolute;
                                font-size: 12px;
                                color: #0ebb0e;
                                right: 7px;
                                bottom: -20px;
                            }
                        }
                    }

                    .open-calendar {
                        width: 100%;

                        label.text-uppercase {
                            color: $secondary-gray;
                            font-size: 12px !important;
                        }

                        .un-dates-wrapper {
                            min-height: 50px;
                            border-radius: 10px;
                            background-color: #f9f9f9;
                            padding: 12px;

                            .unavail-dates {
                                margin-bottom: 15px;

                                &-row {
                                    width: 100%;
                                    display: inline-flex;
                                    flex-direction: row;
                                    padding: 8px;
                                    background-color: #ffffff;
                                    border-radius: 8px;
                                    margin-top: 5px;
                                    border: 1px solid #d8d8d8;

                                    .ud-actions {
                                        width: 40%;
                                        display: inline-flex;
                                        align-items: center;

                                        .ud-clone {
                                            border: 1px solid #d8d8d8;
                                            background: url('../assets/img/clone.svg');
                                            background-repeat: no-repeat;
                                            background-position-x: center;
                                            background-position-y: center;
                                        }
                                        .ud-delete {
                                            border: 1px solid #d8d8d8;
                                            background: url('../assets/img/trash.svg');
                                            background-repeat: no-repeat;
                                            background-position-x: center;
                                            background-position-y: center;
                                        }
                                    }
                                }
                            }

                            .btn-addDate {
                                width: 100%;
                                height: 42px;
                                background-color: #EFEDFD;
                                color: $secondary-gray;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 15px;

                                svg {
                                    margin-right: 6px;
                                    width: 14px;
                                    height: 14px;
                                    fill: $secondary-gray;
                                }
                            }
                        }
                    }

                    input[type="date"] {
                        padding: 5px;
                    }
                    input[type="date"]::-webkit-calendar-picker-indicator {
                        width: 16px;
                        height: 22px;
                        background: url('../assets/img/calendar.svg');
                        background-repeat: no-repeat;
                    }

                    .deposit-header {
                        font-size: 10px;
                        text-transform: lowercase;
                    }
                }
            }

            &__footer {

                .modal-footer {
                    // justify-content: space-between;
                    padding: 0;
                    border-top: none;
                    margin-top: 25px;

                    .btn-add, .btn-primary {
                        font-size: 15px;
                    }
                    .btn-save,
                    .btn-add {
                        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
                    }
                    .btn-add {
                        background-color: #EFEDFD;
                        color: $secondary-gray;
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 6px;
                            width: 14px;
                            height: 14px;
                            fill: $secondary-gray;
                        }
                        div.i--add-instance {
                            -webkit-mask-image: url('../assets/img/add-holiday/add-instance.svg');
                            mask-image: url('../assets/img/add-holiday/add-instance.svg');
                            background-color: $secondary-gray;
                            background-repeat: no-repeat;
                            background-position-x: center;
                            background-position-y: center;
                            margin-right: 6px;
                            width: 14px;
                            height: 14px;
                        }
                    }
                    .btn-add:hover {
                        background-color: #e0e1ed;
                    }
                }
            }
        }

        input.type-number::-webkit-outer-spin-button,
        input.type-number::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number].type-number {
            -moz-appearance: textfield;
        }
    }
</style>
