<template>
    <modal id="embed-holidays" :show="openModal" :modalName="modalName">
        <template v-slot:header>
            <h2>Embed Holiday</h2>
        </template>
        <template v-slot:body>
            <div class="eh-wrapper">
                <div class="row settings">
                    <div class="row" style="align-items: flex-end;" :style="[this.openCalendar ? 'gap: 6px' : 'gap: 20px']">
                        <div class="form-group">
                            <label class="text-uppercase small pl-1">EMBED TYPE</label>
                            <div class="d-flex">
                                <div class="position-relative custom-input">
                                    <input type="radio" class="btn btn-primary" name="static" :checked="dataType === 'static'" @click="onChangeTypeEmbed('static')" @change="dataType = 'static'">
                                    <label for="static" class="static-data">
                                        <ListIcon /> Static Data
                                    </label>
                                </div>
                                <div class="position-relative custom-input">
                                    <input type="radio" class="btn btn-primary" name="live" :checked="dataType === 'live'" @click="onChangeTypeEmbed('live')" @change="dataType = 'live'">
                                    <label for="live" class="live-data">
                                        <CalendarIcon /> Live Booking
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="ref-container" :class="{'d-none': !rowData.calendar_id}">
                                <div :class="[this.openCalendar ? 'selected_open' : 'not_selected']" class="ref-badge size-to--label" @click="onChangeOpenCalendar(!this.openCalendar)" style="justify-content: center; gap: 5px; padding: 5px 10px; width: 180px; height: 45px;">
                                    <div :class="[this.openCalendar ? 'selected_icon' : 'not_selected__icon']"  class="calendar-icon">
                                        <CalendarDaysIcon />
                                    </div>
                                    <label style="margin-bottom: 0 !important;">OPEN CALENDAR</label>
                                </div>
                            </div>
                                <div v-if="this.openCalendar">
                                    <b-form-input
                                        style="-moz-appearance: textfield; width: 45px; font-size: 14px; text-align: center; margin-top: 10px; margin-left: 5px;"
                                        type="number"
                                        class="hide-arrows"
                                        @input="event => this.changeValue(event)"
                                        :value="this.min_value">
                                    </b-form-input>
                                </div>
                            <div class="col ref-container" :class="[this.openCalendar ? 'no-paddingLeft' : '']">
                                <div class="ref-badge">
                                    <span class="ref-badge--icon">ID</span>
                                    <span class="ref-badge--id">{{ rowData?.instances[0]?.reference }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row row-settings">
                        <div class="col--5">
                            <div class="form-group">
                                <div class="lang-selector--wrapper">
                                    <label class="lang-selector--title text-uppercase small pl-1">
                                        Embed Language
                                    </label>
                                </div>
                                <div class="language-container">
                                    <select
                                        class="form-control lang-selector"
                                        @change="onSetLanguage"
                                        style="height: 42px;">
                                        <option
                                            v-for="option of langProps.options"
                                            :key="option.value"
                                            :value="option.value"
                                            v-bind:selected="option.value === langProps?.selected?.lang?.value">
                                                {{ option.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col--7">
                            <div class="form-group">
                                <div class="holy-title--wrapper">
                                    <label class="holy-title--title text-uppercase small pl-1">
                                        Holidays Title Override
                                    </label>
                                    <div class="save-wrapper" v-if="holidayTitle.changed">
                                        <i class="far fa-save" @click="onSaveTitle"></i>
                                    </div>
                                    <div
                                        class="save-wrapper saving-title"
                                        v-if="!holidayTitle.changed && savingTitle">
                                        Saving...
                                    </div>
                                </div>
                                <div class="title-container">
                                    <b-form-input
                                        v-model="holidayTitle.override"
                                        @keyup="onOverrideTitle"
                                        :disabled="langProps.selected.lang.value === 'en'"
                                        placeholder="Enter holiday name">
                                    </b-form-input>
                                </div>
                            </div>
                        </div>
                        </div>
                    <hr>
                    <div class="row" style="height: 75px">
                        <div class="original-price">
                            <label class="text-uppercase small pl-1" for="">original currency & price</label>
                            <div class="currency-container">
                              <div v-for="item in this.getOriginalCurrency" :key="item.name" class="currency-wrapper" :id="item.code" @click="onChangeCurrency('')">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 35">
                                            <text
                                                id="_"
                                                data-name="£"
                                                font-size="18"

                                                font-weight="600"><tspan :x="item.symbol.length === 1 ? '13.893' : item.symbol.length === 2 ? '9' : '1'" y="24">{{ item.symbol }}</tspan></text>
                                        </svg>
                                </div>
                                <div class="currency-disp">{{ originalPrice.get() }}</div>
                            </div>
                        </div>
                        <span class="vertical-separator"></span>
                        <div class="embed-price">
                            <div class="col embed-currency" style="position: relative;">
                                <label class="text-uppercase small pl-1" for="">embed currency</label>
                                <div style="display: inline-flex">
                                    <div v-if="this.default_currency === 'diff'">
                                        <div v-for="item in this.selectedCurrency" :key="item.name" class="currency-wrapper" :id="item.code" :class="activeCurrency === item.code ? 'active' : ''">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 35">
                                            <text
                                                id="_"
                                                data-name="£"
                                                font-size="18"

                                                font-weight="600"><tspan :x="item.symbol.length === 1 ? '13.893' : item.symbol.length === 2 ? '9' : '1'" y="24">{{ item.symbol }}</tspan></text>
                                        </svg>
                                    </div>
                                    </div>
                                    <div v-for="item in this.othersCurrency.slice(0, this.default_currency === 'default' ? 3 : 2)" :key="item.name" class="currency-wrapper" :id="item.code" @click="onChangeCurrency($event, 'default')" :class="this.default_currency === 'default' ? activeCurrency === item.code ? 'active' : '' : ''">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 35">
                                            <text
                                                id="_"
                                                data-name="£"
                                                font-size="18"

                                                font-weight="600"><tspan :x="item.symbol.length === 1 ? '13.893' : item.symbol.length === 2 ? '9' : '1'" y="24">{{ item.symbol }}</tspan></text>
                                        </svg>
                                    </div>
                                    <div  v-if="this.currencies.length > 3" class="currency-wrapper curr_open_icon" @click="openDownModal">
                                      <svg v-bind:class="this.modalOpened ? 'icon_opened' : ''" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
                                    </div>
                                    <div  v-if="this.currencies.length > 3" v-bind:class="this.modalOpened ? 'curr_opened' : ''" class="currencies-modal">
                                        <div class="curr-padding">
                                            <div v-for="item in this.allDiffCurrencies" :key="item.name" class="currency-wrapper" :id="item.code" @click="onChangeCurrency($event ,item.code === 'EUR' || item.code === 'GBP' || item.code === 'USD' ? 'default' : 'diff')">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 35">
                                                <text
                                                    id="_"
                                                    data-name="£"
                                                    font-size="18"

                                                    font-weight="600"><tspan :x="item.symbol.length === 1 ? '13.893' : item.symbol.length === 2 ? '9' : '1'" y="24">{{ item.symbol }}</tspan></text>
                                            </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col embed-price-in">
                                <label class="text-uppercase small pl-1" for="">embed price</label>
                                <div class="currency-container">
                                    <div v-for="item in this.selectedCurrency" :key="item.name" class="currency-wrapper" :id="item.code" @click="onChangeCurrency('')">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 35">
                                            <text
                                                id="_"
                                                data-name="£"
                                                font-size="18"

                                                font-weight="600"><tspan :x="item.symbol.length === 1 ? '13.893' : item.symbol.length === 2 ? '9' : '1'" y="24">{{ item.symbol }}</tspan></text>
                                        </svg>
                                    </div>
                                    <div class="currency-disp">{{ embedPrice.get() }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row embed-code" id="code-to-copy" :class="codeCopied ? 'code-copied' : ''">
                    <div v-if="!isLoadingToken">
                        <div v-if="codeCopied" class="copy-msm">{{ copyMsm }}</div>
                        &lt;div style="width:100%;" id="surefooted-embed-div" base_url="{{ baseUrl }}"
                        surefooted="{{ eToken }}"&gt;&lt;/div&gt;
                        &lt;script&gt;window.onload = function () { var surveyAppIndex = null; var scrpitIndex = null;
                        document.querySelectorAll('#surefooted-embed-div-script').forEach(s => { if (s.id ===
                        "surefooted-embed-div-script") { scrpitIndex = true; } }); if (scrpitIndex === null) { var script =
                        document.createElement("script"); script.id = "surefooted-embed-div-script"; script.src =
                        "{{ embedLink }}"; script.type = "text/javascript";
                        document.head.appendChild(script); }; var stylesheetIndex = null;
                        document.querySelectorAll('#surefooted-embed-div-stylesheet').forEach(s => { if (s.id ===
                        "surefooted-embed-div-stylesheet") { stylesheetIndex = true; } }); if (stylesheetIndex === null) { var link
                        = document.createElement("link"); link.id = "surefooted-embed-div-stylesheet"; link.rel = "stylesheet";
                        link.type = "text/css"; link.href =
                        "{{ embedCSS }}"; document.head.appendChild(link);
                        }; };&lt;/script&gt;
            <!-- &lt;div class="surefooted-main-wrapper"&gt;<br>
            &lt;script id="surefooted-script" src="{{ embedLink }}?{{ eToken }}" type="text/javascript"&gt;
            &lt;/script&gt;<br>
            &lt;/div&gt; -->
                    </div>
                    <div class="embed-loading" v-else>
                        Generating<span>...</span>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="modal-footer">
              <button class="btn btn-cancel" @click="onCancel">Cancel</button>
              <button class="btn btn-primary" @click="onCopyCode" :disabled="copyDisabled">Copy Code</button>
          </div>
      </template>
  </modal>
</template>

<script>
import eventHub from '../utils/eventHub'
import ModalVue from './Modal.vue'
import { ref, computed, watch, reactive } from 'vue'
import Utils from '../utils/Utils'
import ListIcon from '../components/common/svg/ListIcon.vue'
import CalendarIcon from '../components/common/svg/CalendarIcon.vue'
import CalendarDaysIcon from '../components/common/svg/CalendarDaysIcon.vue'

export default {
    name: 'embed-holiday-modal',
    props: ['openModal', 'modalName', 'rowData', 'langs'],
    components: {
        modal: ModalVue,
        ListIcon,
        CalendarIcon,
        CalendarDaysIcon
    },
    data () {
        return {
            token: null,
            embedLink: process.env.VUE_APP_EMBED_LINK,
            embedCSS: process.env.VUE_APP_EMBED_CSS,
            baseUrl: process.env.VUE_APP_API,
            amount: 0,
            rate: 1,
            codeToken: null,
            dataType: 'live',
            default_currency: 'default',
            originalCurrency: 'GBP',
            activeCurrency: null,
            codeCopied: false,
            copyDisabled: false,
            modalOpened: false,
            copyMsm: 'Successfully copied!',
            isLoadingToken: false,
            savingTitle: false,
            openCalendar: false,
            url_condition: '',
            min_value: 1,
            selectedCurrency: [],
            othersCurrency: [],
            getOriginalCurrency: [],
            allDiffCurrencies: [],
            currencies: []
        }
    },
    created () {
        this.token = localStorage.getItem('accessToken')
        this.embedLink = process.env.VUE_APP_EMBED_LINK
        this.embedCSS = process.env.VUE_APP_EMBED_CSS
        this.baseUrl = process.env.VUE_APP_API
    },
    updated () {
        this.token = localStorage.getItem('accessToken')
        this.dataType = 'live'
    },
    setup (props) {
        const orignPrice = ref(0)
        const embPrice = ref(0)
        const convertedAmount = ref(0)
        const _userData = localStorage.getItem('userData')
        const userData = _userData ? JSON.parse(_userData) : null
        const userDesign = ref(_userData ? JSON.parse(_userData).design : null)
        const eToken = ref(null)
        const holidayTitle = reactive({
            original: props?.rowData?.holiday_name,
            override: null,
            setOverride: (value) => {
                holidayTitle.override = value
                holidayTitle.changed =
                    langProps.selected.lang.value !== 'en' &&
                    value !== holidayTitle.original
            },
            setLangTitle: (value) => {
                holidayTitle.override = value
            },
            changed: false,
            change: {
                value: false,
                get: () => {
                    return holidayTitle.changed
                },
                set: (value) => {
                    holidayTitle.changed = value
                }
            }
        })
        const langProps = reactive({
            options: computed(() => {
                if (props?.langs.length > 0) {
                    return [...props.langs]
                }
                return [
                    { value: 'en', label: 'English' },
                    { value: 'fr', label: 'Français _' }
                ]
            }),
            change: {
                value: false,
                get: () => {
                    return langProps.change.value
                },
                set: (value) => {
                    langProps.change.value = value
                }
            },
            selected: { //  default lang
                lang: { value: 'en', label: 'English' },
                get: () => {
                    return langProps?.selected?.lang?.value?.toUpperCase()
                },
                set: (event) => {
                    langProps.selected.lang = langProps.options.find(
                        lang => lang.value === event.target.value
                    )
                    langProps.change.set(true)
                }
            }
        })

        const originalPrice = computed(() => {
            return {
                get: () => {
                    return Number(orignPrice.value).toFixed(2)
                },
                set: (newValue) => {
                    orignPrice.value = Number(newValue).toFixed(2)
                }
            }
        })

        const embedPrice = computed(() => {
            return {
                get: () => {
                    return Number(embPrice.value).toFixed(2)
                },
                set: (newValue) => {
                    const roundedPrice = Math.ceil(newValue)
                    embPrice.value = Number(roundedPrice).toFixed(2)
                }
            }
        })

        const handleProps = (data) => {
            if (data?.holiday_name) {
                holidayTitle.override = data.holiday_name
                holidayTitle.original = data.holiday_name
            }
        }

        watch(() => props.rowData, handleProps)

        const convertion = computed(() => {
            return {
                get: () => {
                    return Number(convertedAmount.value).toFixed(2)
                },
                set: (newValue) => {
                    convertedAmount.value = Number(newValue).toFixed(2)
                }
            }
        })

        const embedToken = computed(() => {
            return {
                decode: (token) => {
                    return decodeURIComponent(escape(window.atob(token)))
                },
                encode: (data) => {
                    const rToken = window.btoa(unescape(encodeURIComponent(JSON.stringify(data))))
                    eToken.value = rToken
                }
            }
        })

        return {
            originalPrice,
            embedPrice,
            convertedAmount,
            convertion,
            embedToken,
            eToken,
            userDesign,
            userData,
            holidayTitle,
            langProps
        }
    },
    methods: {
      openDownModal () {
        this.modalOpened = !this.modalOpened
      },
      async requestCurrencyData (withLoading) {
      /* let tempHolidays = [] */
      const companyId = this.loggedUser.id
      const payload = new FormData()

      payload.append('company_id', companyId)
      if (withLoading) {
        this.loader = this.$loading.show()
        this.isLoading = true
      }

      await this.axios
        .get(process.env.VUE_APP_API + '/aux/currencys')
        .then(result => {
          const { status } = result
          if ((status === 200 || status === 201) && result?.data.data) {
            this.currencies = result?.data?.data
          }
        })
        .catch(Utils.$handleHttpErrors)
        if (withLoading) {
          this.loader.hide()
          this.isLoading = false
        }
    },
        async requestEmbedToken () {
            this.isLoadingToken = true
            // const { id } = this.userData
            const companyId = this.rowData.company_id
            const holidayId = this.rowData.id
            const reqLang = this.langProps.selected.get()
            const data = new FormData()
            data.append('holiday_id', holidayId)
            data.append('language', reqLang)
            data.append('currency', this.activeCurrency)
            data.append('company_id', companyId)
            data.append('type_embed', this.dataType)
            data.append('min_value', this.min_value)
            data.append('open_calendar', this.openCalendar)

            const config = {
                method: 'post',
                url: process.env.VUE_APP_API + '/holiday/embedcode',
                data: data
            }
            await this.axios(config).then(respData => {
                if (respData?.status === 201) {
                    const parsedData = JSON.parse(respData?.data?.data)
                    this.rate = parsedData?.rate || 1
                    this.embedToken.encode({
                        // currency: this.activeCurrency,
                        token: parsedData?.token
                    })
                }
            }).catch(Utils.$handleHttpErrors)
            this.isLoadingToken = false
        },
        async requestHolidayData () {
            const holidayId = this.rowData.id
            const lang = this.langProps.selected.get()
            const data = new FormData()
            const config = {
                method: 'GET',
                url: process.env.VUE_APP_API + `/holiday/title/${holidayId}/${lang}`,
                data: data
            }
            await this.axios(config).then(respData => {
                if (respData?.status === 201) {
                    const holyTitle = this.holidayTitle.original
                    const langTitle = respData?.data?.title || holyTitle
                    this.holidayTitle.setLangTitle(langTitle)
                }
            }).catch(Utils.$handleHttpErrors)
        },
        async saveHolidayNewData () {
            const holidayId = this.rowData.id
            const newLang = this.langProps.selected.get()
            const newTitle = this.holidayTitle.override
            const data = new FormData()
            data.append('holiday_id', holidayId)
            data.append('language', newLang)
            data.append('title', newTitle)

            const config = {
                method: 'post',
                url: process.env.VUE_APP_API + '/holiday/title/save',
                data: data
            }
            await this.axios(config)
                    .then(respData => {})
                    .catch(Utils.$handleHttpErrors)
        },
        onCopyCode () {
            this.copyDisabled = true
            const elemCopy = document.getElementById('code-to-copy')
            const range = document.createRange()
            range.selectNode(elemCopy)
            window.getSelection().removeAllRanges()
            window.getSelection().addRange(range)
            document.execCommand('copy')
            window.getSelection().removeAllRanges()
            this.codeCopied = true
            setTimeout(() => {
                this.codeCopied = false
                this.copyDisabled = false
            }, 2500)
        },
        async onSetLanguage (event) {
            this.langProps.selected.set(event)
            await this.requestHolidayData()
            await this.requestEmbedToken()
        },
        async onOverrideTitle (event) {
            this.holidayTitle.setOverride(event.target.value)
        },
        async onSaveTitle (event) {
            this.holidayTitle.change.set(false)
            this.savingTitle = true
            await this.saveHolidayNewData()
            this.savingTitle = false
        },
        async onChangeCurrency (event, type) {
            this.default_currency = type
            this.activeCurrency = event.target.id

            this.selectedCurrency = this.currencies.filter(el => {
                return el.code === event.target.id
            })
            if (type === 'diff') {
            this.othersCurrency = this.currencies.filter(el => {
              if (this.activeCurrency === 'GBP') {
                return el.code === 'EUR' || el.code === 'USD'
              } else if (this.activeCurrency === 'EUR') {
                return el.code === 'GBP' || el.code === 'USD'
              } else if (this.activeCurrency === 'USD') {
                return el.code === 'GBP' || el.code === 'EUR'
              } else {
                return el.code === 'GBP' || el.code === 'EUR'
              }
            })

            this.allDiffCurrencies = this.currencies.filter(el => {
              if (el.code !== 'EUR' && el.code !== 'GBP') {
                return this.activeCurrency !== el.code
              }
            })
            } else {
                this.othersCurrency = this.currencies.filter(el => {
                    return el.code === 'GBP' || el.code === 'EUR' || el.code === 'USD'
                })
                this.allDiffCurrencies = this.currencies.filter(el => {
                if (el.code !== 'EUR' && el.code !== 'USD' && el.code !== 'GBP') {
                return this.activeCurrency !== el.code
              }
            })
            }
            this.modalOpened = false
            await this.requestEmbedToken()
        },
        async onChangeOpenCalendar (type) {
            this.openCalendar = type
            await this.requestEmbedToken()
        },
        async changeValue (event) {
            this.min_value = event
            await this.requestEmbedToken()
        },
        async onChangeTypeEmbed (type) {
            this.dataType = type

            await this.requestEmbedToken()
        },
        onCancel () {
            eventHub.$emit('embed-holidays-modal', false)
        },
        initVariables (values) {
            this.modalOpened = false
            this.openCalendar = false
            // eslint-disable-next-line camelcase
            const { holiday_currency, instances, embed_code, rate } = values
            // eslint-disable-next-line camelcase
            this.amount = 0
            // eslint-disable-next-line camelcase
            this.originalCurrency = holiday_currency || 'GBP'
            this.getOriginalCurrency = this.currencies.filter(el => {
              return el.code === this.originalCurrency
            })
            // eslint-disable-next-line camelcase
            this.activeCurrency = holiday_currency || 'GBP'
            if (this.activeCurrency === 'GBP' || this.activeCurrency === 'USD' || this.activeCurrency === 'EUR') {
                this.default_currency = 'default'
            } else {
                this.default_currency = 'diff'
            }
            this.currencies.sort((a, b) => {
              return a.id - b.id
            })
            this.selectedCurrency = this.currencies.filter(el => {
              return el.code === this.activeCurrency
            })
            if (this.activeCurrency !== 'GBP' && this.activeCurrency !== 'USD' && this.activeCurrency !== 'EUR') {
                this.othersCurrency = this.currencies.filter(el => {
                if (this.activeCurrency === 'GBP') {
                    return el.code === 'EUR' || el.code === 'USD'
                } else if (this.activeCurrency === 'EUR') {
                    return el.code === 'GBP' || el.code === 'USD'
                } else if (this.activeCurrency === 'USD') {
                    return el.code === 'GBP' || el.code === 'EUR'
                } else {
                    return el.code === 'GBP' || el.code === 'EUR'
                }
                })
            } else {
                this.othersCurrency = this.currencies.filter(el => {
                    return el.code === 'GBP' || el.code === 'EUR' || el.code === 'USD'
                })
            }

            if (this.currencies.length > 3) {
              this.allDiffCurrencies = this.currencies.filter(el => {
              if (this.default_currency === 'diff') {
                return el.code !== this.originalCurrency && el.code !== 'EUR' && el.code !== 'GBP'
              } else {
                return el.code !== 'EUR' && el.code !== 'USD' && el.code !== 'GBP'
              }
            })
            }
            // eslint-disable-next-line camelcase
            this.codeToken = embed_code || null
            this.originalPrice.set(instances[0].price)
            this.embedPrice.set(instances[0].price)
            this.rate = rate
            this.requestEmbedToken()

            this.embedToken.encode({
                // currency: this.activeCurrency,
                token: this.codeToken
            })
        }
    },
    watch: {
        rowData (newValue, oldValue) {
            if (newValue) {
                this.initVariables(newValue)
            }
        },
        openModal (newValue, oldValue) {
          const userData = localStorage.getItem('userData')
            if (userData) {
              const parsedData = JSON.parse(userData)
              this.url_condition = parsedData.url_condition
            }
            if (!newValue) {
                this.codeToken = null
                this.isInit = false
                this.rate = 1
            }
        },
        rate (newRate, oldRate) {
            const price = Number(this.originalPrice.get())
            this.embedPrice.set(newRate * price)
        }
    },
    async mounted () {
      this.loggedUser = JSON.parse(localStorage.getItem('userData'))
      const { status } = this.loggedUser || {}
      if (status === 1 || status === '1') await this.requestCurrencyData(true)
    },
    unmounted () {

    }
}
</script>

<style lang="scss">
    #embed-holidays {
        .modal__dialog {
            width: 42vw;
            padding: 30px 25px 0;
        }

        .modal_body {
            margin-top: 5px;
        }

        .modal__footer .modal-footer {
            border: none;
            padding: 25px 0.75rem;

            .btn {
                padding: 0.4rem 1.2rem;
                height: 45px;
                font-size: 15px;
            }
            .btn-cancel {
                background-color: #ececec;
                margin-right: 10px;
            }
        }

        .eh-wrapper {
            width: 100%;
            // min-height: 368px;

            *.row {
                margin-left: 0;
                margin-right: 0;
            }
            .settings {
                display: flex;
                flex-direction: column;
                border: 1px solid #d5d5d5;
                min-height: 200px;
                border-radius: 6px;
                padding: 16px 15px;
                margin-bottom: 25px;

                hr {
                    margin-top: 0.7rem;
                    margin-bottom: 0.7rem;
                }

                .row {
                    padding: 0 0 10px 0;
                }
                .row-settings {
                    padding-bottom: 0;
                    padding-top: 5px;

                    .holy-title,
                    .lang-selector {

                        &--wrapper {
                          display: flex;
                          flex-flow: row;

                          .save-wrapper {
                              width: 15%;
                              display: flex;
                              align-items: baseline;
                              justify-content: flex-end;

                              i {
                                  color: #49a649;
                                  font-size: 1rem;
                                  margin-top: 1px;
                              }
                          }
                          .save-wrapper.saving-title {
                              font-size: 0.8rem;
                              color: #49a649;
                              filter: drop-shadow(2px 0px 3px #49a649);
                          }
                        }
                        &--title {
                            width: 85%;
                        }
                    }

                    .form-control {
                        font-size: 14px;
                    }
                    .form-control:disabled {
                        background-color: transparent;
                        cursor: not-allowed;
                    }
                    .lang-selector {
                        color: #6254ea;
                        letter-spacing: 1.5px;
                        border: 0;
                        border-right: 16px solid transparent;
                        outline: 1px solid #ced4da;
                    }
                    .lang-selector::after {
                        padding-right: 20px;
                    }
                }
                .col--5 {
                    width: 35%;
                    margin-right: 15%;
                }
                .col--7 {
                    width: 50%;
                }
                .custom-input {
                    height: 45px;
                }
                .custom-input > input[type=radio]:checked ~ label {
                    background-color: $primary;

                    svg {fill: #ffffff;}
                    svg.live-data {
                        fill: $primary !important;
                        stroke: #ffffff;
                    }
                }

                .currency-container {
                    display: inline-flex;
                    align-items: center;

                    .currency-disp {
                        font-size: 22px;
                        margin-left: 10px;
                        color: #7E84A3;
                    }
                }
                .currency-wrapper {
                    width: 40px;
                    height: 40px;
                    border: 1px solid #e6e6e6;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    svg {
                        width: 40px;
                        fill: #7E84A3;
                        pointer-events: none;
                        margin-right: 1px;
                    }
                }
                .currency-wrapper.active {
                    background-color: $primary !important;
                    pointer-events: none;

                    svg {
                        fill: #ffffff !important;
                    }
                }

                .text-uppercase {
                    font-size: 12px !important;
                    font-weight: bold !important;
                    color: #7E84A3;
                }

                .hide-arrows::-webkit-outer-spin-button,
                .hide-arrows::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                .no-paddingLeft {
                  padding-left: 5px !important;
                  padding-right: 0 !important;
                }
                .form-group .custom-input label {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 45px;
                    background-color: #EFEDFD;
                }
                .form-group label.static-data svg {
                    width: 16px;
                    fill: $primary;
                    margin-right: 4px;
                }
                .form-group label.live-data svg {
                    width: 22px;
                    stroke: $primary;
                    fill: #efedfd !important;
                    margin-right: 4px;
                }

                hr {
                    border-top: 1px solid #e6e6e6;
                    width: 100%;
                }
                .original-price {
                    width: 40%;
                }
                .vertical-separator {
                    height: auto;
                    border-left: 1px solid #e6e6e6;
                }
                .embed-price {
                    width: calc(60% - 1px);
                    display: inline-flex;

                    .embed-currency {
                        display: flex;
                        flex-direction: column;
                    }

                    .currency-wrapper {
                        background-color: #efedfd;
                        margin-right: 8px;

                        svg {
                            fill: $primary;
                        }
                    }

                    .embed-price-in {
                        display: flex;
                        flex-direction: column;
                        padding-right: 0;

                        input {
                            height: 42px;
                            width: 100%;
                            border-radius: 8px;
                            border: 1px solid #E2E2E2;
                            font-size: 16px;
                            font-weight: 600;
                            padding: 0 15px;
                        }
                        input:focus-visible {
                            outline: none;
                        }
                    }
                }

                .ref-container {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .ref-badge {
                        min-width: 100px;
                        height: 25px;
                        border: 1px solid #b3c7d1;
                        border-radius: 6px;
                        color: #b3c7d1;
                        font-size: 11px;
                        display: flex;
                        align-items: center;
                        margin-top: 10px;

                        &--icon {
                            background-color: #b3c7d1;
                            color: #FFF;
                            padding: 5px;
                        }
                        &--id {
                            width: 100%;
                            padding: 5px 15px 5px 5px;
                        }
                        &--id,
                        &--icon {
                            height: 100%;
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
            .embed-code {
                height: 29vh;
                display: flex;
                align-items: center;
                background: #000;
                border-radius: 6px;
                margin-top: 10px;
                color: #ffffff;
                padding: 25px 20px;
                word-break: break-all;
                position: relative;
                overflow: scroll;

                .embed-loading {
                    width: 100%;
                    text-align: center;
                    font-size: 1.2rem;
                    letter-spacing: 0.1rem;

                    span {
                        letter-spacing: 0.3rem;
                    }
                }
            }
        }
        .size-to--label {
            cursor: pointer;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
        .size-to--label label {
            font-size: 0.8rem !important;
            cursor: pointer;
        }

        .selected_open {
            background-color: #614fe8 !important;
            color: white !important;
            border: 1px solid #3922e2 !important;
        }
        .not_selected {
            color: #614fe8 !important;
            border: 1px solid #3922e2 !important;
        }
        .selected_icon {
            fill: white !important;
        }
        .not_selected__icon {
            fill: #614fe8 !important;
        }

        .calendar-icon {
            font-size: 17px;
        }

        .copy-msm {
            position: absolute;
            top: -22px;
            right: 5px;
            color: #49a649;
        }

        .currencies-modal {
          width: 100%;
          max-height: 0px;
          background-color: white;
          border-radius: 5px;
          overflow: hidden !important;
          position: absolute;
          top: 75px;
          -webkit-transition: all 0.8s ease;
          -moz-transition: all 0.8s ease;
          -ms-transition: all 0.8s ease;
          -o-transition: all 0.8s ease;
          transition: all 0.8s ease;
          z-index: 10000;
          border: 1px solid #c3c2c2;
          opacity: 0;
        }

        .curr-padding {
            padding: 15px;
            display: flex;
            flex-wrap: wrap;
        }

        .curr_open_icon svg {
          transition: all 0.5s ease;
          transform: rotate(0deg);
        }

        .icon_opened {
          transform: rotate(-180deg) !important;
        }

        .curr_opened  {
            opacity: 1;
          border: 1px solid #c3c2c2;
          max-height: 1000px;
          transition: all 0.8s ease;
        }

        .code-copied {
            -webkit-box-shadow: 0px 0px 4px 0px #ADFF2F;
            box-shadow: 0px 0px 4px 0px #ADFF2F;
        }
    }
</style>
