<template>
    <modal ref="access-profile-modal" :show="openModal" modalName="access-profile-modal">
        <template v-slot:header>&nbsp;</template>
        <template v-slot:body>
            <div class="body-wrapper">
                <div class="row logo">
                    <img v-if="data.logo" :src="data.logo" alt="profile-logo">
                    <div v-else class="empty-logo">A</div>
                </div>
                <div class="row user-name">
                    <span>{{ transformToStr('company_name') }}</span>
                </div>
                <div class="row country">
                    <img src="@/assets/img/map-mark.svg" alt="map-mark">
                    <span class="text">{{ transformToStr('country') }}</span>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="footer-wrapper">
                <button v-if="isAccessTypePositive()" :class="accessStatus(data)" class="btn" @click="handleAccess(data)">
                    <img src="@/assets/img/white-key.png" alt="white-key">
                    <span>{{ userTypePrefix() }} Access</span>
                </button>
                <button v-else-if="isAccessTypeNegative()" class="btn btn-revoke" @click="handleAccess(data)">
                    <img src="@/assets/img/revoke-key.png" alt="revoke-key">
                    <span>{{ userTypePrefix() }} Access</span>
                </button>
                <div v-else class="btn btn-pending" @click="closeModal">
                    <img src="@/assets/img/revoke-key.png" alt="revoke-key">
                    <span>Waiting Access</span>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import eventHub from '../../utils/eventHub'
import ModalVue from '../Modal.vue'

export default {
    name: 'access-profile-modal',
    props: ['data', 'openModal'],
    components: {
        modal: ModalVue
    },
    methods: {
        accessStatus (data) {
            switch (data?.role.toLowerCase()) {
                case 'agent':
                    if (data.access_status === 'PENDING') return 'btn-pending'
                    return 'btn-grant'
                default:
                    return 'btn-grant'
            }
        },
        operatorTypes (data) {
            switch (data?.access_status.toLowerCase()) {
                case 'active':
                    return 'Remove'
                case 'pending':
                    return 'Pending'
                case 'none':
                default:
                    return 'Request'
            }
        },
        agentTypes (data) {
            switch (data?.access_status.toLowerCase()) {
                case 'active':
                    return 'Revoke'
                case 'pending':
                    return 'Grant'
                case 'none':
                default:
                    return 'Give'
            }
        },
        userTypePrefix () {
            switch (this.data.role.toLowerCase()) {
                case 'agent':
                    return this.agentTypes(this.data)
                case 'operator':
                    return this.operatorTypes(this.data)
            }
        },
        transformToStr (prop) {
            return this.data && this.data[prop] ? this.data[prop] : ''
        },
        isAccessTypePositive () {
            const accType = this.userTypePrefix()
            return accType === 'Grant' || accType === 'Request' || accType === 'Give'
        },
        isAccessTypeNegative () {
            const accType = this.userTypePrefix()
            return accType === 'Revoke' || accType === 'Remove'
        },
        handleAccess (user, accessType) {
            eventHub.$emit('modal-handle-access', { ...user })
        },
        closeModal () {
            eventHub.$emit('modal-access-close', true)
        }
    },
    watch: {
    }
}
</script>

<style lang="scss" scoped>
    .body-wrapper {
        margin-top: -40px;

        *.row {
            margin-left: 0;
            margin-right: 0;
        }

        .text {
            font-size: 17px;
            font-weight: 100;
            margin-left: 8px;
        }

        .logo {
            justify-content: center;
            margin-bottom: 20px;
            position: relative;
            z-index: 5;

            .empty-logo, img {
                width: 114px;
                height: 114px;
                border-radius: 50%;
                border: 1px solid #E7E8ED;
            }
            .empty-logo {
                background-color: $primary;
                opacity: 0.7;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 45px;
                font-weight: 100;
                color: #fff;
            }
        }
        .user-name {
            justify-content: center;
            margin-bottom: 15px;

            span {
                font-size: 36px;
            }
        }
        .country {
            justify-content: center;
            margin-bottom: 15px;

            img {
                width: 18px;
            }
        }
    }

    .footer-wrapper {
        text-align: center;
        margin-top: 40px;
        margin-bottom: -15px;

        .btn-grant {
            background-color: $grant-access;
            padding: 0.8rem 1.4rem;

            img {
                width: 14px;
            }
            span {
                color: #fff;
                margin-left: 8px;
            }
        }
        .btn-revoke {
            background-color: $revoke-access;
            padding: 0.8rem 1.4rem;

            img {
                width: 14px;
            }
            span {
                color: #fff;
                margin-left: 8px;
            }
        }
        .btn-pending {
            background-color: $pending-access;
            padding: 0.8rem 1.4rem;

            img {
                width: 14px;
            }
            span {
                color: #fff;
                margin-left: 8px;
            }
        }
    }
</style>
