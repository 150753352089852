<template>
  <div class="wrapper w-100" ref="accessViewTabs">
    <div class="row w-100 p-3 access-header">
        <div class="access-header-section col-sm-12 col-md-5 col-lg-3 col-xl-3">
          <h1 class="page-title">{{ prefix }}</h1>
        </div>
        <div class="d-flex ml-auto access-header-section col-sm-12 col-md-7 col-lg-3 col-xl-3 order-lg-12">
          <div class="input-group">
            <input
              class="form-control search-input h-48 rounded-right-0 rounded-left-0 w-auto"
              v-model="searchQuery"
              type="text"
              name="search"
              placeholder="Search by anything...">
            <div class="input-group-append h-48">
              <span class="input-group-text">
                <i class="fas fa-search"></i>
              </span>
            </div>
          </div>
          <a class="btn btn-outline-primary h-48 w-auto mx-3 btn-inner shadow-sm">
            <i class="fas fa-filter mr-2"></i>Filters
          </a>
        </div>
        <div class="access-header-section-2 col-sm-12 col-md-12 col-lg-6 col-lg-6">
          <div class="tab access-tabs">
            <button id="all" class="tablinks" :class="activeTab == 'all' ? 'active-tab' : ''" @click="onTabClicked">
              {{ 'All ' + prefix }}
            </button>
            <button id="my" class="tablinks" :class="activeTab == 'my' ? 'active-tab' : ''" @click="onTabClicked">
              {{ 'My ' + prefix }}
            </button>
            <button id="awaiting" class="tablinks" :class="activeTab == 'awaiting' ? 'active-tab' : ''" @click="onTabClicked">
              Awaiting Access
            </button>
          </div>
        </div>
      </div>
      <div class="px-4 pb-4 s-body">
        <accessTable :users="currentUsers" :loggedUser="loggedUser"></accessTable>
        <div class="table-footer">
          <pagination v-model="page" :records="filteredData.length" :per-page="pageSize" @paginate="paginate" :options="pagOptions">
          </pagination>
        </div>
      </div>
  </div>
</template>

<script>
import TableViewVue from '../components/AcessView/TableView.vue'
import eventHub from '../utils/eventHub'

export default {
    name: 'access-view',
    props: [],
    components: {
      accessTable: TableViewVue
    },
    data () {
        return {
          token: localStorage.getItem('accessToken'),
          loggedUser: JSON.parse(localStorage.getItem('userData')),
          appRole: null,
          prefix: null,
          activeTab: 'all',
          searchQuery: null,
          data: [],
          filteredData: [],
          currentUsers: [[], [], []],
          pageSize: 15,
          page: 1,
          pagOptions: {
            chunk: 12,
            chunksNavigation: 'scroll',
            texts: {
              count: 'Showing {from} - {to} of {count}'
            }
          },
          loader: null
        }
    },
    created () {
    },
    updated () {
      this.token = localStorage.getItem('accessToken')
    },
    methods: {
      paginate (page) {
        this.page = page
      },
      onTabClicked (event) {
        if (event?.target?.id) {
          this.activeTab = event.target.id
        }
      },
      async getAccessUsers () {
        this.loader = this.$loading.show({
          container: this.$refs.accessViewTabs,
          onCancel: this.cancelLoader
        })
        this.axios
          .get(process.env.VUE_APP_API + '/access')
          .then((response) => {
            const { status } = response
            if ((status === 201 || status === 200) && response?.data?.data) {
              // this.data = [...response.data.data]
              this.data = [...response.data.data].filter(user => {
                return user?.role.toLowerCase() === this.appRole.toLowerCase()
              })
            } else if (status === 401) {
              // this.handleUnAuth()
            }
            this.cancelLoader()
          // eslint-disable-next-line handle-callback-err
          })
          .catch(() =>
            this.cancelLoader()
            // this.handleUnAuth()
          )
      },
      cancelLoader () {
        if (this.loader) this.loader.hide()
      },
      capFirst (name) {
          return name.charAt(0).toUpperCase() + name.slice(1)
      },
      getRandomInt (min, max) {
          return Math.floor(Math.random() * (max - min)) + min
      },
      setPageUsers () {
        const pageData = this.filteredData.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
        this.currentUsers[0] = pageData.slice(0, 5)
        this.currentUsers[1] = pageData.slice(5, 10)
        this.currentUsers[2] = pageData.slice(10, 15)
      },
      setDataByTab (activeTab) {
        return this.data.filter(item => {
          if (activeTab === 'my') return item?.access_status?.toLowerCase() === 'active'
          // else if (activeTab === 'awaiting') return item?.access_status?.toLowerCase() !== 'active'
          else if (activeTab === 'awaiting') return item?.access_status?.toLowerCase() === 'pending'
          else return true
        })
      },
      setDataByStr (str, data) {
        return data.filter(item => {
          if (!!str && str !== '') {
            if (Object.keys(item).length > 0) {
              let flag = false
              Object.keys(item).forEach(prop => {
                if (item[prop] && typeof item[prop] === 'string' && item[prop].toLowerCase().includes(str.toLowerCase())) flag = true
              })
              return flag
            } else {
              return true
            }
          } else {
            return true
          }
        })
      },
      handleUnAuth () {
        eventHub.$emit('logout', true)
      }
    },
    mounted () {
      if (localStorage.getItem('userData')) {
        const user = JSON.parse(localStorage.getItem('userData'))
        this.loggedUser = user
        this.prefix = user?.role.toLowerCase() === 'operator' ? 'Agents' : 'Operators'
        this.appRole = user?.role.toLowerCase() === 'operator' ? 'agent' : 'operator'
        this.getAccessUsers()
      }
      this.token = localStorage.getItem('accessToken')

      eventHub.$on('request-access-users', async (requestData) => {
        if (requestData) await this.getAccessUsers()
      })
    },
    unmounted () {
      eventHub.$off('request-access-users')
    },
    watch: {
      data (newValue, oldValue) {
        const data = this.setDataByTab(this.activeTab)
        this.filteredData = this.searchQuery && this.searchQuery !== '' ? this.setDataByStr(this.searchQuery, data) : data
      },
      page (newValue) {
        this.setPageUsers()
      },
      filteredData (newValue, oldValue) {
        this.setPageUsers()
      },
      activeTab (newValue, oldValue) {
        const data = this.setDataByTab(newValue)
        this.filteredData = this.searchQuery && this.searchQuery !== '' ? this.setDataByStr(this.searchQuery, data) : data
      },
      searchQuery (newValue, oldValue) {
        const data = this.setDataByTab(this.activeTab)
        this.filteredData = this.setDataByStr(newValue, data)
        this.page = 1
      }
    }
}
</script>

<style lang="scss" scoped>

  .access-header {
    display: inline-flex;
    flex-direction: row;
    margin: 0;
    position: absolute;
    background-color: #f9fafc;
    z-index: 5;

    &-section {
      justify-content: flex-end;

      .page-title {
        margin-bottom: 0 !important;
      }
      .input-group {
        flex-wrap: unset;
        justify-content: flex-end;
      }
      input {
        width: 60% !important;
        flex: initial;
      }
      a {
        display: none;
      }
    }
    &-section-2 {

    }
    .form-control:focus {
      box-shadow: none;
    }

    .access-tabs {
      height: 100%;
      text-align: center;
      padding-bottom: 3px;

      .tablinks {
        background-color: inherit;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 17px;
        height: 100%;
        padding: 0 5px 3px;
        margin: 0 10px;
        display: inline-flex;
        align-items: flex-end;
      }
      .tablinks.active-tab {
        color: $primary;
        border-bottom: 3px solid $primary;
      }
    }
  }

  .s-body {
    margin-top: 11vh;
  }

  .table-footer {

  }
</style>
