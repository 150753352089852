<template>
  <div class="switch-container">
    <label class="switch">
      <input type="checkbox" v-model="checked">
      <span class="slider round"></span>
    </label>
    <label v-if="checked">LIVE</label>
    <label v-else>DRAFT</label>
  </div>
</template>

<script>
import eventHub from '../../utils/eventHub'
export default {
    name: 'custom-switch',
    props: ['name', 'id', 'status'],
    data () {
        return {
          checked: this.status === 1
        }
    },
    watch: {
      checked (newValue, oldValue) {
        eventHub.$emit(this.name, {
          value: newValue,
          id: this.id
        })
      }
    }
}
</script>

<style lang="scss" scoped>
.switch-container {
    height: 100%;
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0;
    }
}
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 17px;
  margin-right: 8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EF6565;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $switch;
}

input:focus + .slider {
  box-shadow: 0 0 1px $switch;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
