<template>
  <div v-if="!showNoUsersMsm()" class="access-table-container">
      <div class="users-row">
          <div class="user-item" v-for="item of users[0]" :key="item.id">
              <card-content :user="item" :handleAccess="handleAccess"></card-content>
          </div>
      </div>
      <div class="users-row">
          <div class="user-item" v-for="item of users[1]" :key="item.id">
              <card-content :user="item" :handleAccess="handleAccess"></card-content>
          </div>
      </div>
      <div class="users-row">
          <div class="user-item" v-for="item of users[2]" :key="item.id">
              <card-content :user="item" :handleAccess="handleAccess"></card-content>
          </div>
      </div>
  </div>
  <div v-else class="access-table-container">
      <div class="access-table-msm">
          {{ getUserDisplayMsm(loggedUser?.role) }}
      </div>
  </div>
  <modal :openModal="showModal" :data="currentProfile"></modal>
</template>

<script>
import eventHub from '../../utils/eventHub'
import utils from '../../utils/Utils'
import CardContentVue from './CardContent.vue'
import ProfileModalVue from './ProfileModal.vue'

export default {
    name: 'table-view',
    props: ['users', 'loggedUser'],
    components: {
        'card-content': CardContentVue,
        modal: ProfileModalVue
    },
    data () {
        return {
            token: localStorage.getItem('accessToken'),
            showModal: false,
            currentProfile: null
        }
    },
    updated () {
        this.token = localStorage.getItem('accessToken')
    },
    methods: {
        async handleAccess (user) {
            const accessType = this.accessType(user)
            this.$swal({
                icon: 'warning',
                title: `Do you really want to ${accessType} access?`,
                showCancelButton: true,
                confirmButtonText: `${accessType} Access`,
                confirmButtonColor: accessType === 'Request' || accessType === 'Give' ? '#59c154' : (accessType === 'Grant' ? '#fcba03' : '#ef6565')
            }).then(async (result) => {
                if (this.makeRequest(result)) {
                    this.loader = this.$loading.show()
                    const configData = this.computPath(user)
                    if (configData) {
                        const reqPayload = configData.payload ? this.createPayload(user) : null
                        var config = {
                            method: configData.method,
                            url: process.env.VUE_APP_API + configData.path
                        }
                        if (reqPayload) config.data = reqPayload

                        await this.axios(config).then((resp) => {
                            if (resp?.status === 200) eventHub.$emit('request-access-users', true)
                        }).catch(async (err) => {
                            const errResult = await utils.$handleAccessErrors(err)
                            if (errResult?.error?.to) {
                                var errStr = Array.isArray(errResult.error.to) ? this.handleToErrors(errResult.error.to) : errResult.error.to
                                this.$swal({
                                    icon: 'error',
                                    title: errStr,
                                    showCancelButton: false,
                                    confirmButtonText: 'Ok',
                                    confirmButtonColor: '#59c154'
                                })
                            }
                        })
                    }
                    this.loader.hide()
                }
            })
        },
        handleToErrors (toErrors) {
            var str = ''
            toErrors.forEach((err) => {
                str += err
            })
            return str
        },
        createPayload (user) {
            const payload = new FormData()
            switch (user.role.toLowerCase()) {
                case 'agent':
                    payload.append('type', 'full')
                    payload.append('from', this.loggedUser.company_id)
                    payload.append('to', user.company_id)
                    return payload
                case 'operator':
                    payload.append('type', 'full')
                    payload.append('from', this.loggedUser.company_id)
                    payload.append('to', user.company_id)
                    return payload
                default:
                    return null
            }
        },
        operatorTypes (data) {
            switch (data?.access_status.toLowerCase()) {
                case 'active':
                    return 'Remove'
                case 'pending':
                    return 'Pending'
                case 'none':
                default:
                    return 'Request'
            }
        },
        agentTypes (data) {
            switch (data?.access_status.toLowerCase()) {
                case 'active':
                    return 'Revoke'
                case 'pending':
                    return 'Grant'
                case 'none':
                default:
                    return 'Give'
            }
        },
        accessType (user) {
            switch (user.role.toLowerCase()) {
                case 'agent':
                    return this.agentTypes(user)
                case 'operator':
                    return this.operatorTypes(user)
            }
        },
        computPath (user) {
            switch (user.role.toLowerCase()) {
                case 'agent':
                    return {
                        path: user.access_status === 'NONE' ? '/access/give' : (user.access_status === 'ACTIVE' ? `/access/revoke/${user.access_id}` : `/access/grant/${user.access_id}`),
                        method: user.access_status === 'NONE' ? 'post' : (user.access_status === 'ACTIVE' ? 'delete' : 'put'),
                        payload: user.access_status === 'NONE'
                    }
                case 'operator':
                    return {
                        path: user.access_status === 'NONE' ? '/access/request' : `/access/revoke/${user.access_id}`,
                        method: user.access_status === 'NONE' ? 'post' : 'delete',
                        payload: user.access_status === 'NONE'
                    }
                default:
                    return null
            }
        },
        makeRequest (data) {
            const { isConfirmed } = data
            return isConfirmed
        },
        getUserDisplayMsm (userRole) {
            if (userRole && userRole !== '') {
                return userRole.toLowerCase() === 'operator' ? 'No agents yet...' : 'No operators yet...'
            }
            return ''
        },
        showNoUsersMsm () {
            if (this.users && this.users.length > 0) {
                if (this.users[0].length > 0) return false
            }
            return true
        }
    },
    created () {
        eventHub.$on('set-access-profile', (data) => {
            const { showModal, user } = data
            this.currentProfile = user
            this.showModal = showModal
        })

        eventHub.$on('access-profile-modal', (data) => {
            this.showModal = data
        })

        eventHub.$on('modal-handle-access', (data) => {
            this.showModal = false
            setTimeout(() => {
                this.handleAccess(data)
            }, 200)
        })

        eventHub.$on('modal-access-close', (data) => {
            this.showModal = false
        })
    },
    unmounted () {
       eventHub.$off('modal-handle-access')
       eventHub.$off('access-profile-modal')
       eventHub.$off('set-access-profile')
       eventHub.$off('modal-access-close')
    }
}
</script>

<style lang="scss" scoped>
    .access-table-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        min-height: 65vh;
    }
    .access-table-msm {
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #b8babc;
        font-size: 18px;
    }
    .users-row {
        display: inline-flex;
        flex-direction: row;
        margin: 0 auto;
        width: calc((182px + 1.2em) * 5);
    }
    .user-item {
        width: 182px;
        height: 248px;
        margin-right: 0;
        padding: 0;
        margin: 0.6em;
        border: 1px solid rgb(173, 173, 173);
        border-radius: 6px;
        box-shadow: 2px 1px 8px 1px rgba(0,0,0,0.18);
        -webkit-box-shadow: 2px 1px 8px 1px rgba(0,0,0,0.18);
        -moz-box-shadow: 2px 1px 8px 1px rgba(0,0,0,0.18);
    }
</style>
