<template>
    <div class="card">
        <div class="card-header" v-if="title">{{ title }}</div>
        <div class="card-body">
            <p class="card-title"></p>
            <div
                id="dataTables-holidays_wrapper"
                class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
                <div class="row"></div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="row"></div>
                        <div v-if="isLoading" class="loading-mask">
                        <div class="loading-content">
                            <span style="color: white">Loading...</span>
                        </div>
                        </div>
                        <table
                        class="table table-hover table-bordered table-responsive-sm"
                        id="dataTables-holidays"
                        >
                        <thead class="thead-dark">
                            <tr>
                                <th v-if="hasCheckbox" class="checkbox-th" style="width: 2%;">
                                <div class="checkbox-th-wrapper" :class="`${userRole}-type`">
                                    <input type="checkbox" v-model="setting.isCheckAll" />
                                </div>
                            </th>
                            <th
                                v-for="(col, index) in columns"
                                :key="index"
                                :style="{ width: col.width ? col.width : 'auto' }"
                            >
                                <div
                                :class="{
                                    sortable: col.sortable,
                                    both: col.sortable,
                                    asc: setting.order === col.sort_path && setting.sort === 'asc',
                                    desc: setting.order === col.sort_path && setting.sort === 'desc',
                                }"
                                @click="col.sortable ? doSort(col.sort_path) : false"
                                >
                                {{ col.label }}
                                </div>
                            </th>
                            </tr>
                        </thead>
                         <tbody v-if="rows.length > 0" class="vtl-tbody">
                    <template v-for="(row, i) in localRows" :key="i">
                    <tr>
                    <td v-if="hasCheckbox" class="vtl-tbody-td">
                        <div>
                        <input
                            type="checkbox"
                            class="vtl-tbody-checkbox"
                            :ref="
                            (el) => {
                                rowCheckbox[i] = el;
                            }
                            "
                            :value="row[setting.keyColumn]"
                            @click="checked"
                        />
                        </div>
                    </td>
                    <td
                    v-for="(col, j) in columns"
                    :key="j"
                    class="vtl-tbody-td"
                    :class="col.columnClasses"
                    :style="col.columnStyles"
                  >
                    <div class="h-status--container" v-if="col.display" v-html="col.display(row)"></div>
                    <div v-else>
                      <div v-if="setting.isSlotMode && slots[col.field]">
                        <slot :name="col.field" :value="row"></slot>
                      </div>
                      <span v-else>{{ row[col.field] }}</span>
                    </div>
                    <div v-if="col.field === 'actions'" class="holidays-actions">
                        <a :id="`edit-${row.id}`" data-id="edit"
                        @click="handleHolidayActionClick(row, 'edit', $event)"
                        :class="`btn btn-secondary-outline btn-icon w-auto p-1 mx-2 action-com`">
                        <i class="fas fa-pen"></i>
                        </a>
                        <a :id="`delete-${row.id}`" data-id="delete"
                        @click="handleHolidayActionClick(row, 'delete', $event)"
                        :class="`btn btn-secondary-outline btn-icon w-auto p-1 mx-2 action-delete`">
                        <i class="fas fa-trash-alt"></i>
                        </a>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row table-footer" v-if="rows.length > 0">
                    <div class="col-sm-12 col-md-4">
                        <div role="status" aria-live="polite">
                        {{
                            stringFormat(messages.pagingInfo, setting.offset, setting.limit, total)
                        }}
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <span>{{ messages.pageSizeChangeLabel }}</span>
                        <select v-model="setting.pageSize">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        </select>
                        <span>{{ messages.gotoPageLabel }}</span>
                        <select v-model="setting.page">
                        <option v-for="n in setting.maxPage" :key="n">{{ n }}</option>
                        </select>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div
                        class="dataTables_paginate paging_simple_numbers"
                        id="dataTables-example_paginate"
                        >
                        <ul class="pagination">
                            <li class="page-item" :class="{ disabled: setting.page <= 1 }">
                            <a
                                class="page-link"
                                href="javascript:void(0)"
                                aria-label="Previous"
                                @click="setting.page = 1"
                            >
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">First</span>
                            </a>
                            </li>
                            <li class="page-item" :class="{ disabled: setting.page <= 1 }">
                            <a
                                class="page-link"
                                href="javascript:void(0)"
                                aria-label="Previous"
                                @click="prevPage"
                            >
                                <span aria-hidden="true">&lt;</span>
                                <span class="sr-only">Prev</span>
                            </a>
                            </li>
                            <li
                            class="page-item"
                            v-for="n in setting.paging"
                            :key="n"
                            :class="{ disabled: setting.page === n }"
                            >
                            <a class="page-link" href="javascript:void(0)" @click="movePage(n)">{{
                                n
                            }}</a>
                            </li>
                            <li
                            class="page-item"
                            :class="{ disabled: setting.page >= setting.maxPage }"
                            >
                            <a
                                class="page-link"
                                href="javascript:void(0)"
                                aria-label="Next"
                                @click="nextPage"
                            >
                                <span aria-hidden="true">&gt;</span>
                                <span class="sr-only">Next</span>
                            </a>
                            </li>
                            <li
                            class="page-item"
                            :class="{ disabled: setting.page >= setting.maxPage }"
                            >
                            <a
                                class="page-link"
                                href="javascript:void(0)"
                                aria-label="Next"
                                @click="setting.page = setting.maxPage"
                            >
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Last</span>
                            </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-sm-12 text-center">
                        {{ messages.noDataAvailable }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        ref,
        reactive,
        computed,
        watch,
        onBeforeUpdate,
        nextTick
    } from 'vue'
    import eventHub from '../../utils/eventHub'
    import moment from 'moment'
    import _ from 'lodash'

    export default {
        name: 'my-table',
        emits: ['return-checked-rows', 'do-search', 'is-finished', 'get-now-page'],
        props: {
            isLoading: {
                type: Boolean,
                require: true
            },
            isReSearch: {
                type: Boolean,
                require: true
            },
            hasCheckbox: {
                type: Boolean,
                default: false
            },
            userRole: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: ''
            },
            columns: {
                type: Array,
                default: () => {
                    return []
                }
            },
            rows: {
                type: Array,
                default: () => {
                    return []
                }
            },
            rowClasses: {
            type: [Array, Function],
            default: () => {
                return []
            }
            },
            pageSize: {
                type: Number,
                default: 10
            },
            total: {
                type: Number,
                default: 100
            },
            page: {
                type: Number,
                default: 1
            },
            sortable: {
                type: Object,
                default: () => {
                    return {
                        order: 'id',
                        sort: 'desc'
                    }
                }
            },
            messages: {
                type: Object,
                default: () => {
                    return {
                    pagingInfo: 'Showing {0}-{1} of {2}',
                    pageSizeChangeLabel: 'Row count:',
                    gotoPageLabel: 'Go to page:',
                    noDataAvailable: 'No data'
                    }
                }
            },
            isStaticMode: {
                type: Boolean,
                default: false
                }
        },
        data () {
            return {
            }
        },
        watch: {
            title (newValue, oldValue) {
                if (newValue) this.title__ = newValue
            }
        },
        methods: {
            collapsedRow (row, event) {
                if (row.instances.length > 1) {
                    const isRefsArray = Array.isArray(this.$refs[`btn-collapse-${row.id}`])
                    const btnCollapseRef = isRefsArray ? this.$refs[`btn-collapse-${row.id}`][0] : this.$refs[`btn-collapse-${row.id}`]
                    const collapseRef = isRefsArray ? this.$refs[`collapse-${row.id}`][0] : this.$refs[`collapse-${row.id}`]
                    if (btnCollapseRef) {
                        btnCollapseRef.classList.toggle('expanded-row')
                    }
                    if (collapseRef) {
                        collapseRef.parentNode.classList.toggle('show-height')
                        collapseRef.classList.toggle('show')

                        if (collapseRef.classList.contains('show')) {
                            this.setSwitchControl(collapseRef, row)
                        }
                    }
                }
            },
            formatDates (data) {
                const { instances } = data
                if (instances && instances.length > 0) {
                    let dates = []
                    instances.forEach((instance, index) => {
                    if (index === 0 || index === instances.length - 1) dates.push(instance?.date_start)
                    })
                    dates = dates.map(date => moment(date).format('DD/MM/yyyy'))
                    return dates.join(' - ')
                }
                return ''
            },
            formateDate (strDate) {
                return moment(strDate).format('yyyy-MM-DD')
            },
            hasDiffItems (places) {
                const itemA = places[0]
                const itemB = places[places.length - 1]
                return itemA === itemB
            },
            formatSeats (data) {
                const { instances } = data
                if (instances && instances.length > 0) {
                    let places = instances.map(instance => instance.seats)
                    places = places.sort((itemA, itemB) => {
                    if (itemA > itemB) return 1
                    if (itemA < itemB) return -1
                    return 0
                    })
                    return places.length > 1 && !this.hasDiffItems(places)
                    ? `${places[0]} / ${places[places.length - 1]}`
                    : places[0]
                }
                return ''
            },
            formatPrice (data) {
                // eslint-disable-next-line camelcase
                const { instances, holiday_currency } = data
                if (instances && instances.length > 0) {
                    const symbol = this.processCurrency(holiday_currency)
                    let prices = instances.map(instance => instance.price)
                    prices = prices.sort((priceA, priceB) => {
                        if (priceA > priceB) return 1
                        if (priceA < priceB) return -1
                        return 0
                    })
                    return prices.length > 1 && !this.hasDiffItems(prices)
                    ? `${symbol}${prices[0]} - ${symbol}${prices[prices.length - 1]}`
                    : `${symbol}${prices[0]}`
                }
                return ''
            },
            setSwitchControl (element, row) {
                eventHub.$emit('switch-control', {
                    element: element,
                    row: row
                })
            },
            handleHolidayActionClick (rowData, action, event) {
                eventHub.$emit(`on-${action}-users`, rowData)
            }
        },
        setup (props, { emit }) {
            const setting = reactive({
                isCheckAll: false,
                keyColumn: computed(() => {
                        let key = ''
                        Object.assign(props.columns).forEach((col) => {
                            if (col.isKey) {
                                key = col.field
                            }
                        })
                        return key
                }),
                page: props.page,
                pageSize: props.pageSize,
                maxPage: computed(() => {
                    if (props.total <= 0) {
                        return 0
                    }
                    let maxPage = Math.floor(props.total / setting.pageSize)
                    const mod = props.total % setting.pageSize
                    if (mod > 0) {
                        maxPage++
                    }
                    return maxPage
                }),
                offset: computed(() => {
                    return (setting.page - 1) * setting.pageSize + 1
                }),
                limit: computed(() => {
                    const limit = setting.page * setting.pageSize
                    return props.total >= limit ? limit : props.total
                }),
                paging: computed(() => {
                    let startPage = setting.page - 2 <= 0 ? 1 : setting.page - 2
                    if (setting.maxPage - setting.page <= 2) {
                        startPage = setting.maxPage - 4
                    }
                    startPage = startPage <= 0 ? 1 : startPage
                    const pages = []
                    for (let i = startPage; i <= setting.maxPage; i++) {
                        if (pages.length < 5) {
                            pages.push(i)
                        }
                    }
                    return pages
                }),
                order: props.sortable.order,
                sort: props.sortable.sort
            })

            const localRows = computed(() => {
                const property = setting.order
                // eslint-disable-next-line camelcase
                let sort_order = 1
                if (setting.sort === 'desc') {
                    // eslint-disable-next-line camelcase
                    sort_order = -1
                }
                const rows = props.rows
                rows.sort((a, b) => {
                    // if (a[property] < b[property]) {
                    if (_.get(a, property) < _.get(b, property)) {
                        // eslint-disable-next-line camelcase
                        return -1 * sort_order
                    // } else if (a[property] > b[property]) {
                    } else if (_.get(a, property) > _.get(b, property)) {
                        // eslint-disable-next-line camelcase
                        return sort_order
                    } else {
                        return 0
                    }
                })

                const result = []
                for (let index = setting.offset - 1; index < setting.limit; index++) {
                    if (rows[index]) {
                        result.push(rows[index])
                    }
                }
                return result
            })

            const rowCheckbox = ref([])
            if (props.hasCheckbox) {
                onBeforeUpdate(() => {
                    rowCheckbox.value = []
                })

                watch(
                    () => setting.isCheckAll,
                    (state) => {
                        const isChecked = []
                        rowCheckbox.value.forEach((val) => {
                            if (val) {
                                val.checked = state
                                if (val.checked) {
                                    isChecked.push(val.value)
                                }
                            }
                        })
                        emit('return-checked-rows', isChecked)
                    }
                )
            }

            const checked = () => {
                const isChecked = []
                rowCheckbox.value.forEach((val) => {
                    if (val && val.checked) {
                        isChecked.push(val.value)
                    }
                })
                emit('return-checked-rows', isChecked)
            }

            const clearChecked = () => {
                rowCheckbox.value.forEach((val) => {
                    if (val && val.checked) {
                        val.checked = false
                    }
                })
                emit('return-checked-rows', [])
            }

            const doSort = (order) => {
                let sort = 'asc'
                // eslint-disable-next-line eqeqeq
                if (order == setting.order) {
                    // eslint-disable-next-line eqeqeq
                    if (setting.sort == 'asc') {
                        sort = 'desc'
                    }
                }
                const offset = (setting.page - 1) * setting.pageSize
                const limit = setting.pageSize
                setting.order = order
                setting.sort = sort
                emit('do-search', offset, limit, order, sort)

                if (setting.isCheckAll) {
                    setting.isCheckAll = false
                } else {
                    if (props.hasCheckbox) {
                        clearChecked()
                    }
                }
            }

            const changePage = (page, prevPage) => {
                setting.isCheckAll = false
                const order = setting.order
                const sort = setting.sort
                const offset = (page - 1) * setting.pageSize
                const limit = setting.pageSize
                // eslint-disable-next-line eqeqeq
                if (!props.isReSearch || page > 1 || page == prevPage) {
                    emit('do-search', offset, limit, order, sort)
                }
            }

            watch(() => setting.page, changePage)

            const changePageSize = () => {
                if (setting.page === 1) {
                    changePage(setting.page, setting.page)
                } else {
                    setting.page = 1
                    setting.isCheckAll = false
                }
            }

            watch(() => setting.pageSize, changePageSize)

            const prevPage = () => {
                // eslint-disable-next-line eqeqeq
                if (setting.page == 1) {
                    return false
                }
                setting.page--
                emit('get-now-page', setting.page)
            }

            const movePage = (page) => {
                setting.page = page
                emit('get-now-page', setting.page)
            }

            const nextPage = () => {
                if (setting.page >= setting.maxPage) {
                    return false
                }
                setting.page++
                emit('get-now-page', setting.page)
            }

            watch(
                () => props.rows,
                () => {
                    if (props.isReSearch) {
                        setting.page = 1
                    }
                    nextTick(function () {
                        const localElement = document.getElementsByClassName('is-rows-el')
                        emit('is-finished', localElement)
                    })
                }
            )

            const stringFormat = (template, ...args) => {
                return template.replace(/{(\d+)}/g, function (match, number) {
                    // eslint-disable-next-line eqeqeq
                    return typeof args[number] != 'undefined' ? args[number] : match
                })
            }

            if (props.hasCheckbox) {
                return {
                    localRows,
                    setting,
                    rowCheckbox,
                    clearChecked,
                    checked,
                    doSort,
                    prevPage,
                    movePage,
                    nextPage,
                    stringFormat
                }
            } else {
                return {
                    localRows,
                    setting,
                    clearChecked,
                    doSort,
                    prevPage,
                    movePage,
                    nextPage,
                    stringFormat
                }
            }
        },
        created () {
            eventHub.$on('clear-checked', (value) => {
                if (value) this.clearChecked()
            })
        },
        unmounted () {
            eventHub.$off('clear-checked')
        }
    }
</script>

<style scoped>
.checkbox-th {
  width: 2%;
}
.checkbox-th-wrapper {
    position: relative;
    top: 2px;
}

.both {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
}

.sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 30px !important;
}

.h-status--container {
            width: 100%;
            display: inline-flex;
            align-items: center;
}

.asc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}

.desc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}

.loading-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column;
  transition: opacity 0.3s ease;
}

.loading-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*.loading-icon {*/
/*  display: flex;*/
/*  margin-left: auto;*/
/*  margin-right: auto;*/
/*}*/

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  padding: 20px;
}

select {
  width: auto;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  height: auto;
  padding: 0;
  margin-bottom: 0;
}

table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.hide-padding-table td {
  padding: 0 !important;
  height: 0 !important;
  border-top: 0 !important;
  /* transition: height 0.5s linear; */
}
.hide-padding-table td.show-height {
    /* height: 40px !important; */
}

.hide-padding-table td div.show {
    /* background-color: #f0f8ff; */
    background-color: #eff4f9;
    box-shadow: inset 0px 3px 10px -8px #000000;
}
.hide-padding-table td div.show *.row {
    margin: 0;
    width: 100%;
}
.hide-padding-table td div.show .row:not(:last-child) {
    border-bottom: 1px solid #dee2e6;
}

.hide-padding-table td div.show .sub-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem;
}
.hide-padding-table td div.show .sub-row div {
    width: 100%;
}
.hide-padding-table td div.show .sub-row.sub--id {
    width: 5%;
}
.hide-padding-table td div.show .sub-row.sub--holiday_name {
    width: 17%;
}
.hide-padding-table td div.show .sub-row.sub--date_start {
    width: 14%;
}
.hide-padding-table td div.show .sub-row.sub--seats {
    width: 9%;
}
.hide-padding-table td div.show .sub-row.sub--holiday_duration {
    width: 9%;
}
.hide-padding-table td div.show .sub-row.sub--price {
    width: 12%;
}
.hide-padding-table td div.show .sub-row.sub--company_name {
    width: 11%;
}
.hide-padding-table td div.show .sub-row.sub--holiday_status {
    width: 9%;
}
.hide-padding-table td div.show .sub-row.sub--holiday_status div {
    margin-left: 2px;
}
.hide-padding-table td div.show .sub-row.sub--actions {
    width: 14%;
    justify-content: flex-end;
}
.action-disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

th {
  text-align: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

tr.accordion-toggle {
    height: 63px;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

/*.table-border {*/
/*  border: 1px solid #dee2e6;*/
/*}*/

.table td,
.table th {
  vertical-align: middle;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

input.type-number::-webkit-outer-spin-button,
input.type-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number].type-number {
  -moz-appearance: textfield;
}

</style>
