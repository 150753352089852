<template>
  <div class="wrapper w-100" id="sf-users-table" ref="userslistView">
    <div class="d-flex w-100 p-4">
      <h1 class="page-title">Logs List</h1>
      <div class="d-flex ml-auto">
          <!-- <button class="btn btn-white h-48 w-auto btn-inner shadow-sm mx-2" style="color:#614fe8"><i class="fas fa-filter mr-2" aria-hidden="true"></i>FILTERS</button> -->
        <div class="input-group search-control">
          <input name="search"
            v-model="searchInput" type="text"
            class="form-control search-input h-48 rounded-right-0 rounded-left-0 w-auto"
            placeholder="Search by anything...">
          <div class="input-group-append h-48">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
        </div>
         <!-- <div>
          <button class="btn btn-primary h-48 w-auto btn-inner shadow-sm" style="width: 135px !important" @click="onAddUsers()"><i class="fas fa-plus-circle mr-2" aria-hidden="true"></i>NEW USER</button>
        </div> -->
      </div>
    </div>
    <div class="px-4 pb-4">
      <table-lite
        :columns="tableData.columns"
        :rows="filteredData"
        :messages="tableData.messages"
        :sortable="tableData.sortable"
        :total="tableData.totalRecords"
        @get-now-page="onPageChange">
      </table-lite>
    </div>
  </div>
  <addUserModal :openModal="openAddUsers" modalName="add-users-modal" :editing="editing"></addUserModal>
</template>

<script>
import TableLite from '../components/table/TableLiteUsers.vue'
/* import TableLite from '../components/table/TableLiteUsers.vue' */
import _ from 'lodash'
import AddUserVue from '../components/AddUsers.vue'
import Utils from '../utils/Utils'
import eventHub from '../utils/eventHub'

export default {
name: 'users',
props: [],
components: {
   addUserModal: AddUserVue,
  'table-lite': TableLite
},
data () {
  const userData = JSON.parse(localStorage.getItem('userData'))
  return {
    token: localStorage.getItem('accessToken'),
    openAddUsers: false,
    searchInput: '',
    filterSelect: '',
    filterInput: '',
    editing: false,
    observer: null,
    loggedUser: userData,
    userRole: null,
    isLoading: false,
    rowData: null,
    selectedRows: [],
    msg: null,
    dpConfig: {
      options: [
        { label: 'No Filter', value: '' },
        { label: 'SuperAdmin', value: 'superadmin' },
        { label: 'Operator', value: 'operator' },
        { label: 'Agent', value: 'agent' }
              ],
        borderRadius: '5px',
        backgroundColor: 'transparent',
        placeholder: 'No Filter'
          },
    tableData: {

      columns: [
        {
          label: 'Code error',
          field: 'cod_error',
          sort_path: 'cod_error',
          width: '14%',
          sortable: true,
          isKey: false
        },
        {
          label: 'Name',
          field: 'name',
          sort_path: 'name',
          width: '11%',
          sortable: true,
          isKey: false
        },
        {
          label: 'User Email',
          field: 'email',
          sort_path: 'email',
          width: '14%',
          sortable: true,
          isKey: false
        },
        {
          label: 'Phone',
          field: 'phone',
          sort_path: 'phone',
          width: '10%',
          sortable: true,
          isKey: false
        },
        {
          label: 'Message Error',
          field: 'message_error',
          sort_path: 'message_error',
          width: '14%',
          sortable: true,
          isKey: false
        },
        {
          label: 'Date',
          field: 'created_at',
          sort_path: 'created_at',
          width: '11%',
          sortable: true,
          isKey: false
        },
        {
          label: 'Seats',
          field: 'seats',
          sort_path: 'seats',
          width: '5%',
          sortable: true,
          isKey: false
        },
        {
          label: 'Currency',
          field: 'currency',
          sort_path: 'currency',
          width: '5%',
          sortable: true,
          isKey: false
        },
        {
          label: 'Amount',
          field: 'amount',
          sort_path: 'amount',
          width: '6%',
          sortable: true,
          isKey: false
        },
        // {
        //   label: 'Status',
        //   field: 'status',
        //   sort_path: 'status',
        //   width: '9%',
        //   sortable: true,
        //   isKey: false,
        //   display: function (row) {
        //     if (row.status === 1) {
        //       return (
        //       '<div class="h-status live"></div><span>ACTIVE</span>'
        //     )
        //     } else {
        //        return (
        //       '<div class="h-status draft"></div><span>PENDING</span>'
        //        )
        //     }
        //   }
        // },
        // {
        //   label: 'ACTIONS',
        //   field: 'actions',
        //   width: '2%'
        // }
      ],
      rows: [],
      totalRecords: 0,
      messages: {
      pagingInfo: 'Showing {0}-{1} of {2}',
      pageSizeChangeLabel: 'Row count:',
      gotoPageLabel: 'Go to page:',
      noDataAvailable: 'No data'
      },
      sortable: {
        order: 'id',
        sort: 'asc'
      }
    },
    filteredData: [],
    originalData: [],
    langs: null,
    formatedLangs: [],
    expandedRows: []
  }
},
methods: {
  onFilter (event) {
    this.filterSelect = event.target.value
    const filteredData = this.tableData.rows.filter(row => {
      if (Object.keys(row).length > 0) {
        for (let index = 0; index < Object.keys(row).length; index++) {
          if (row[Object.keys(row)[index]] !== null && row[Object.keys(row)[index]].toString().toLowerCase().includes(this.filterSelect)) return true
        }
      }
      return false
    })
    this.filteredData = !this.filterSelect || this.filterSelect === '' ? this.tableData.rows : filteredData
  },
  async requestUsersData (withLoading) {
    /* let tempHolidays = [] */
    const companyId = this.loggedUser.id
    const payload = new FormData()

    if (withLoading) {
      this.loader = this.$loading.show()
      this.isLoading = true
    }

    payload.append('company_id', companyId)

    await this.axios
      .get(process.env.VUE_APP_API + '/admin/usr/listlogs')
      .then(result => {
        const { status } = result
        if ((status === 200 || status === 201) && result?.data.data) {
          result.data.data.forEach((el) => {
            el.created_at = `${new Date(el.created_at).getFullYear()}-${new Date(el.created_at).getMonth() + 1 < 10 ? `0${new Date(el.created_at).getMonth() + 1}` : new Date(el.created_at).getMonth() + 1}-${new Date(el.created_at).getDate() < 10 ? `0${new Date(el.created_at).getDate()}` : new Date(el.created_at).getDate()}`
          })
          this.msg = result.data.msg
          this.tableData.rows = result.data.data
          this.filteredData = result.data.data
          this.tableData.totalRecords = result.data.data.length
        }
      })
      .catch(Utils.$handleHttpErrors)

    if (withLoading) {
      this.loader.hide()
      this.isLoading = false
    }
  },
  async handleDeleteUsers (users) {
    this.loader = this.$loading.show()
    this.isLoading = true
    const resp = await this.requestDelete(users.id)
    if (resp?.ok) {
      await this.requestUsersData(false)
    }
    this.loader.hide()
    this.isLoading = false
  },
  async requestDelete (userid) {
    return await this.axios
      .delete(`${process.env.VUE_APP_API}/admin/usr/delete/${userid}`)
      .then(response => {
        if (response?.status === 200 || response?.status === 201) return { ok: true, response }
        return { ok: false, response }
      })
      .catch(Utils.$handleHttpErrors)
  },
  onAddUsers () {
    this.editing = false
    this.openAddUsers = true
  },
  showAlert (type, layer) {
    const title = type === 'update' ? 'Save' : 'Delete'
    return this.$swal({
        icon: 'warning',
        title: `Do you really want to ${title} ${layer}?`,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        confirmButtonColor: type === 'update' ? '#59c154' : '#ef6565'
    })
  },
  onPageChange (pageNo) {
    eventHub.$emit('clear-checked', true)
    this.closeExpandedRows()
  },
  onEditUsers (rowData) {
    this.editing = true
    this.openAddUsers = true
    eventHub.$emit('edit-user-data', rowData, true)
    /* const selectedRow = this.originalData.find(
      holiday => holiday.id === rowData.id
    )
    if (selectedRow)  */
  },
  scanControllersRow (instanceId) {
    return document.querySelectorAll(`[data-row-id="${instanceId}"]`)
  },
  scanElementsRow (instanceId) {
    return document.querySelectorAll(`[data-row="${instanceId}"]`)
  },
  scanDataRow (instanceId) {
    const values = {}
    const elemFields = document.querySelectorAll(`[data-row="${instanceId}"]`)
    if (elemFields) {
      elemFields.forEach((elem) => {
        const field = elem.getAttribute('data-field')
        const mapResult = this.scanRowMapper(field, elem)
        values[mapResult.prop] = mapResult.value
      })
    }
    return values
  },
  closeExpandedRows () {
    const elementsShow = document.querySelectorAll('.show')
    const elementsShowHeight = document.querySelectorAll('.show-height')
    const iconElemRefs = document.querySelectorAll('.ref-badge--icon')
    if (elementsShow) {
      elementsShow.forEach(elem => {
        elem.classList.toggle('show')
      })
    }
    if (elementsShowHeight) {
      elementsShowHeight.forEach(elem => {
        elem.classList.toggle('show-height')
      })
    }
    if (iconElemRefs) {
      iconElemRefs.forEach(elem => {
        const refElem = elem.parentNode.querySelector('.ref-badge--id')
        if (refElem) {
          elem.classList.remove('ref-badge--icon')
          refElem.classList.add('ref-hidden')
        }
      })
    }
  },
  prepareLangs (values) {
    return Object.keys(values).map((key) => {
      return {
        value: key.toLowerCase(),
        label: values[key]
      }
    })
  }
},
created () {
 /*  eventHub.$on('embed-holidays-modal', () => {
    this.openModal = false
  }) */

    eventHub.$on('clear-checked', (value) => {
              if (value) this.clearChecked()
          })

  eventHub.$on('add-users-modal', () => {
    this.openAddUsers = false
  })

  eventHub.$on('refresh-users', (refresh) => {
    if (refresh) this.requestUsersData()
  })

  eventHub.$on('start-finish-edit', () => {
    this.loader = this.$loading.show()
    this.isLoading = true
  })

  eventHub.$on('end-finish-edit', async () => {
    await this.requestUsersData(false)
    this.loader.hide()
    this.isLoading = false
  })

  eventHub.$on('request-users', async ({ closeModal, request }) => {
    if (closeModal) this.openAddUsers = false
    if (request) await this.requestUsersData(true)
  })

  eventHub.$on('on-edit-users', (users) => {
    this.onEditUsers(_.cloneDeep(users))
  })

  eventHub.$on('on-delete-users', (users) => {
    this.showAlert('delete', `"${users.name}"`)
      .then(async (result) => {
        const { isConfirmed } = result
        if (isConfirmed) this.handleDeleteUsers(users)
      })
  })
},
watch: {
  searchInput (newValue, oldValue) {
    const filteredData = this.tableData.rows.filter(row => {
      if (Object.keys(row).length > 0) {
        for (let index = 0; index < Object.keys(row).length; index++) {
          if (row[Object.keys(row)[index]] !== null && row[Object.keys(row)[index]].toString().toLowerCase().includes(this.searchInput)) return true
          this.filterInput = ''
        }
      }
      return false
    })
    this.filteredData = !this.searchInput || this.searchInput === '' ? this.tableData.rows : filteredData
  },
  loggedUser (newUser, oldUser) {
    const { role } = newUser
    this.userRole = role.toLowerCase()
  },
  langs (newValues, oldValues) {
    this.formatedLangs = this.prepareLangs(newValues)
  }
},
async mounted () {
  this.loggedUser = JSON.parse(localStorage.getItem('userData'))
  const { status } = this.loggedUser || {}
  if (status === 1 || status === '1') await this.requestUsersData(true)
  this.token = localStorage.getItem('accessToken')
},
updated () {

},
unmounted () {
  if (this.observer) this.observer.disconnect()
  if (this.isLoading && this.loader) this.loader.hide()
  this.selectedRows = []
  this.userRole = null

  eventHub.$off('refresh-users')
  eventHub.$off('request-users')
  eventHub.$off('embed-generate-code')
  eventHub.$off('on-delete-users')
  eventHub.$off('change-instance--status')
  eventHub.$off('save--instance--clicked')
  eventHub.$off('clone--instance--clicked')
  eventHub.$off('delete--instance--clicked')
  eventHub.$off('edit-clone--instance')
  eventHub.$off('start-finish-edit')
  eventHub.$off('end-finish-edit')
}
}
</script>

<style lang="scss">

#sf-users-table {

.btn-secondary-outline i {
  pointer-events: none;
}
.search-control {
  margin-right: 25px;
}
.card {
  padding: 0;
  border: none;
  background-color: transparent;

  .card-body {
    padding: 0;

    .card-title {
      display: none;
    }

    #dataTables-holidays_wrapper {
      .thead-dark th {
        background-color: #eff2f7;
        color: #6c757d;
        border-color: transparent;
      }

      .row:nth-child(2) {
          .col-sm-12 {
              min-height: 65vh;
          }
      }
      .row.table-footer:nth-child(3) {
        margin-top: 20px;

        div:nth-child(1){
          order: 2;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          div {
            color: #131523;
            opacity: 0.35;
          }
        }
        div:nth-child(2){
          order: 3;
          text-align: right;

          span:nth-child(1) {
            font-size: 14px;
            color: #7E84A3;
          }
          select:nth-child(2) {
            margin-left: 10px;
            width: 57px;
            height: 36px;
            border: 1px solid #E2E2E2;
            border-radius: 5px;
          }
          span:nth-child(3), select:nth-child(4) {
            display: none;
          }
        }
        div:nth-child(3){
          order: 1;
          display: flex;
          justify-content: flex-start;

          .pagination {

            .page-item a {
              border: none;
              margin-left: 4px;
              background-color: transparent;
            }

            .page-item.disabled a:not([aria-label="Previous"]):not([aria-label="Next"]) {
              background-color: $primary;
              color: #ffffff;
              border-radius: 5px;
            }
            .page-item {
              display: flex;
              align-items: center;

              .page-link:focus {
                outline: none;
                box-shadow: none;
              }
            }
            .page-item .page-link {
              padding: .5rem .85rem;
              color: #707070;
            }
            .page-item .page-link[aria-label="Previous"] {
              height: 30px;
              background-image: url('../assets/img/chevron-left.svg');
              background-repeat: no-repeat;
              margin-top: 5px;

              span {display: none;}
            }
            .page-item .page-link[aria-label="Next"] {
              height: 30px;
              background-image: url('../assets/img/chevron-right.svg');
              background-repeat: no-repeat;
              margin-top: 5px;

              span {display: none;}
            }

            li:first-child,
            li:last-child {
              display: none;
            }
          }
        }
      }

      .h-status-wrapper {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: flex-start;

        .h-status--container {
          width: 100%;
          display: inline-flex;
          align-items: center;
        }

        .h-status {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 8px;
        }
        .live {
          background-color: #60CE5B;
        }
        .draft {
          background-color: #EF6565;
        }
        span {
          width: 100%;
          font-size: 0.65rem;

          .inst-mark {
            letter-spacing: 1px;
            color: #c7c7c7;
            margin-left: 4px;
          }
        }
      }
      .sub-status-wrapper {
          display: inline-flex;
          align-items: center;
          margin-top: 5px;

          .h-status {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 8px;
          }

          .live {
            background-color: #60CE5B;
          }
          .draft {
            background-color: #EF6565;
          }
          span {
            font-size: 0.6rem;
          }

          .switch--wrapper {

            .switch {
              position: relative;
              display: inline-block;
              width: 32px;
              height: 14px;
              margin-bottom: 0;
            }
            .switch input {
              opacity: 0;
              width: 0;
              height: 0;
            }
            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #EF6565;
              -webkit-transition: .4s;
              transition: .4s;
            }
            .slider:before {
              position: absolute;
              content: "";
              height: 12px;
              width: 12px;
              left: 3px;
              bottom: 1px;
              background-color: white;
              -webkit-transition: .4s;
              transition: .4s;
            }
            input:checked + .slider {
              background-color: #60CE5B;
            }
            input:focus + .slider {
              box-shadow: 0 0 1px #55595c;
            }
            input:checked + .slider:before {
              -webkit-transform: translateX(13.5px);
              -ms-transform: translateX(13.5px);
              transform: translateX(13.5px);
            }
            .slider.round {
              border-radius: 34px;
            }
            .slider.round:before {
              border-radius: 50%;
            }

            .state--wrapper {
              position: relative;
              bottom: 1px;
              margin-left: 5px;
            }
          }

      }

      .r-operator-logo {
        display: flex;
        width: fit-content;
        background-color: #e1e8edad;
        padding: 5px 10px;
        border-radius: 6px;

        img {
          width: 1.05rem;
          height: 1.05rem;
          margin-right: 5px;
          border-radius: 50%;
        }
        span {
          font-size: 0.65rem;
          display: flex;
          align-items: flex-end;
          color: #202020;
        }
      }

      tbody {

        .r-date-wrapper {
          width: 70%;
        }

        .collapse-icon {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 18px;
            transform: rotate(0deg);
            transition-duration: 0.2s;
            transition-property: transform;
          }
        }
        .collapse-icon.w-instances {
          cursor: pointer;
        }

        .collapse-icon.w-instances:hover {
          background-color: #afb2b530;
        }
        .expanded-row.collapse-icon i {
          transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
        }
        .holy-name--wrapper,
        .inst-name--wrapper {
          display: inline-flex;
          overflow: hidden;
        }
        .ref-badge {
          margin-top: 3px;
          margin-left: 6px;
          font-size: 0.55rem;
          display: flex;
          align-items: center;
          cursor: pointer;

          .h-name-mark {
            color: #b3c7d1;
          }
          .h-id-mark {
            width: 15px;
            height: 15px;
            border-radius: 5px;
            border: 1px solid #b3c7d1;
            padding: 2px 3px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .ref-badge--icon {
            background-color: #b3c7d1;
            color: #FFF;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          .ref-badge--id {
            height: 15px;
            width: auto;
            padding: 2px 12px 2px 5px;
            color: #b3c7d1;
            border: 1px solid #b3c7d1;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            margin-left: -2px;
            display: flex;
            align-items: center;
          }
          .ref-hidden {
            display: none;
          }
        }

        .h-int {
          font-size: 0.7rem;
          letter-spacing: 1px;
          margin-left: 6px;
          color: #c7c7c7;
          display: flex;
          align-items: center;
        }

        .m-left-5 {
          margin-left: 7px;
          position: relative;
        }
        .m-left-4 {
          margin-left: 4px;
          position: relative;
        }
        .m-left-3 {
          margin-left: 3px;
          position: relative;
        }
        .m-left-2 {
          margin-left: 2px;
          position: relative;
        }

        .form-control {
          height: 38px;
          font-size: 0.75rem;
          font-weight: 600 !important;
        }
        .form-control.date-input {
          width: 130px;
          padding: 5px 12px;
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
          font-size: 12px;
          margin-left: 0;
          padding-left: 0;
          filter: opacity(0.4);
        }
        .form-control.price-input {
          width: 80px;
          position: relative;
          z-index: 1;
          padding-left: 22px;
        }
        .input-symbol {
          height: 100%;
          display: flex;
          align-items: center;
          position: absolute;
          left: 10px;
          z-index: 2;
        }
        .form-control.places-input {
          width: 45px;
        }

      }

      .agent-type {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        input[type=checkbox] {
          visibility: hidden;
        }
      }

    }

    .table-bordered td, .table-bordered th {
      border: none;
      border-top: 1px solid #dee2e6;
      vertical-align: middle;
      background-color: #ffffff;

      .holidays-actions {
        display: flex;
        justify-content: flex-end;

        .btn-secondary-outline {
          border-radius: 50%;
          color: #919fab;
          border: 1px solid #919fab;
        }
        .btn-secondary-outline.action-delete:not(.action-disabled):hover {
          color: #EF6565;
          border-color: #EF6565;
        }
        .btn-secondary-outline.action-com:not(.action-disabled):hover {
          color: #425c70;
          border-color: #425c70;
        }
        .btn-secondary-outline.action-save {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.9rem;
        }
        .btn-secondary-outline.action-save:hover {
          color: #60CE5B;
          border-color: #60CE5B;
        }
        .btn-secondary-outline.action-hidden {
          display: none;
        }
        .btn-secondary-outline.custom-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .a-clone {
          width: 13px;
          height: 13px;
          background: url('../assets/img/clone.svg');
          background-repeat: no-repeat;
          background-position-x: center;
          background-position-y: center;
        }
        .btn-secondary-outline:not(.action-disabled):hover .a-clone {
          background: url('../assets/img/clone-hover.svg');
          background-repeat: no-repeat;
          background-position-x: center;
          background-position-y: center;
        }
      }
    }
  }
}

.btn-outline-delete {
  color: #ef6565;

  i {
    color: #ef6565;
  }
}
.btn-outline-delete:hover {
  background-color: #ef6565;
  color: #ffffff;

  i {
    color: #ffffff;
  }
}
 .h-status {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 8px;
        }
        .live {
          background-color: #60CE5B;
        }
        .draft {
          background-color: #EF6565;
        }

}
</style>
