<template>
  <modal id="add-holidays" :show="openModal" :modalName="modalName">
      <template v-slot:header>
          <h2 v-if="!editing">Add Holiday</h2>
          <h2 v-else>Edit Holiday</h2>
      </template>
      <template v-slot:body>
          <div class="ah-wrapper px-3">
              <div class="row body-header mx-n4">
                  <div class="col-3 holiday-commons px-2" :class="{ 'col-5': dataType === 'open-calendar' }">
                      <!-- <div class="form-group add-operator" v-if="filteredData.company !== null">
                          <label for="add-operator" class="text-uppercase small pl-1">OPERATOR</label>
                          <select name="add-operator" id="add-operator" class="form-control">
                              <option
                                  :value="operator.name"
                                  v-for="operator of operators"
                                  :key="operator.id">
                                  {{operator.name}}
                              </option>
                          </select>
                      </div> -->
                      <!--  <div class="form-group mb-3">
                      <label class="auth-labels text-uppercase small pl-1" for="country_id">Country*</label>
                      <autocomplete :items="companyName" />
                      </div> -->
                      <div class="form-group">
                          <label class="text-uppercase small pl-1">HOLIDAY NAME</label>
                          <input type="text"
                              v-model="holidayName"
                              class="form-control"
                              :style="`width: ${dataType === 'specific' ? '100%' : '310px'}`"
                              placeholder="Enter holiday name">
                              <div :show="errormsg" class="error-msg2">{{ msgerror }}</div>
                      </div>
                  </div>
                  <div class="col-2 holiday-commons px-2" :class="{ 'd-none': dataType === 'open-calendar' }">
                      <div class="form-group">
                          <label class="text-uppercase small pl-1">DAYS</label>
                          <input
                              type="number"
                              min="0"
                              class="form-control type-number"
                              placeholder="Days..."
                              v-model="numberDays">
                      </div>
                  </div>
                  <div class="col-3 holiday-commons px-2">
                      <div class="form-group">
                          <label class="text-uppercase small pl-1">CURRENCY</label>
                          <select class="form-control" v-model="currentCurrency">
                              <option
                                v-for="option of currencies"
                                :key="option.code"
                                v-bind:value="option.code">{{ option.name }}
                              </option>
                          </select>
                      </div>
                  </div>
                  <div v-if="userRole === 'superadmin'" class="col-4 holiday-commons px-2">
                      <div class="form-group" style="margin-right: 0">
                          <label class="text-uppercase small pl-1">COMPANY</label>
                          <autocomplete :items="companyName" :name="comName"/>
                      </div>
                  </div>
                  <div class="col-6 holiday-commons mt-3 px-2 open-calendar">
                      <label class="text-uppercase small pl-1">OPEN CALENDAR</label>
                      <CalendarSelect v-model="openCalendarId" />
                  </div>
                  <div class="col-12 holiday-commons mt-3 px-2 temp-hidden">
                      <div class="form-group">
                          <label class="text-uppercase small pl-1">DATES TYPE</label>
                          <div class="d-flex">
                              <div class="position-relative custom-input">
                                  <input
                                      type="radio"
                                      class="btn btn-primary"
                                      name="specific"
                                      :checked="dataType === 'specific'"
                                      @change="dataType = 'specific'">
                                  <label for="specific" class="specific-data">
                                      <ListIcon /> Specific Data
                                  </label>
                              </div>
                              <div class="position-relative custom-input">
                                  <input
                                      type="radio"
                                      class="btn btn-primary"
                                      name="open-calendar"
                                      :disabled="true"
                                      @change="dataType = 'open-calendar'"
                                      :checked="dataType === 'open-calendar'">
                                  <label for="open-calendar" class="open-calendar-data">
                                      <CalendarIcon /> Open Calendar
                                  </label>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row mt-4">
                  <div class="table-add-holidays" v-if="dataType === 'specific'">
                      <div class="table-add-holidays-header">
                          <div class="t-th">DATE</div>
                          <div class="t-th">PLACES</div>
                          <!-- <div class="t-th">DAYS</div> -->
                          <div class="t-th">PRICE</div>
                          <!-- <div class="t-th" style="width: 15%;">CURRENCY</div> -->
                          <div class="t-th">
                              DEPOSIT <span class="deposit-header">(%)</span>
                          </div>
                          <div class="t-th row-status" style="padding: 0 3px">STATUS</div>
                          <div class="t-th t-actions"></div>
                      </div>
                      <div class="table-add-holidays-body">
                          <div
                              class="table-add-holidays-row"
                              :class="handleSavedInstance(instance)"
                              v-for="instance of computeNumberDays"
                              :key="instance.id">
                              <div v-if="instance?.saving" class="saving-msm">Saving...</div>
                              <div class="t-row--wrapper">
                                  <div class="t-row">
                                      <input
                                          type="date"
                                          class="form-control"
                                          v-model="instance.availableDates.from"
                                          @change="onChangeDateFrom(instance, $event)">
                                  </div>
                                  <div class="t-row">
                                      <input
                                          type="number"
                                          min="0"
                                          class="form-control type-number"
                                          placeholder="Places..."
                                          v-model="instance.places">
                                  </div>
                                  <!-- <div class="t-row">
                                      <input
                                          type="number"
                                          min="0"
                                          class="form-control type-number"
                                          placeholder="Days..."
                                          v-model="numberDays">
                                  </div> -->
                                  <div class="t-row">
                                      <input
                                          type="number"
                                          min="0"
                                          class="form-control type-number"
                                          placeholder="Price..."
                                          v-model="instance.price">
                                  </div>
                                  <!-- <div class="t-row" style="width: 15%;">
                                      <select class="form-control" v-model="currentCurrency">
                                          <option
                                              v-for="option of dpConfig.options"
                                              :key="option.value"
                                              v-bind:value="option.value">{{ option.label }}</option>
                                      </select>
                                  </div> -->
                                  <div class="t-row">
                                      <input
                                          type="number"
                                          min="0"
                                          class="form-control type-number"
                                          placeholder="Enter Deposit..."
                                          v-model="instance.deposit"
                                      >
                                  </div>
                                  <div class="t-row row-status" style="width: 12%;">
                                      <customSwitch name="specific-status" :id="instance.id" :status="instance.status"></customSwitch>
                                  </div>
                                  <div class="t-row t-actions">
                                      <!-- <div v-if="false" class="r-action a-save" @click="onSaveHolidayInstance(instance)"></div> -->
                                      <div v-if="this.instances.length > 1"
                                          class="r-action a-remove"
                                          :class="disableActions ? 'action-disabled' : ''"
                                          @click="onRemoveHolidayInstance(instance)">
                                      </div>
                                      <div
                                          class="r-action a-clone"
                                          :class="disableActions ? 'action-disabled' : ''"
                                          @click="onCloneHolidayInstance(instance)">
                                      </div>
                                  </div>
                              </div>
                              <div class="instance-errors">{{ getErrors(instance) }}</div>
                          </div>
                      </div>
                  </div>
                  <div class="open-calendar temp-hidden" v-else>
                      <div class="row">
                          <div class="col-7">
                              <div class="form-row">
                                  <div class="col-md-6">
                                      <label class="text-uppercase small pl-1" for="in-from">AVAILABLE FROM</label>
                                      <input type="date" id="in-from" class="form-control" v-model="openCalendarData.availableDates.from" @change="onOpenCalendarDateFrom">
                                  </div>
                                  <div class="col-md-6">
                                      <label class="text-uppercase small pl-1" for="in-to">AVAILABLE TO</label>
                                      <input type="date" id="in-to" class="form-control" v-model="openCalendarData.availableDates.to">
                                  </div>
                              </div>
                              <div class="form-row" style="margin-top: 10px;">
                                  <div class="col-md-6">
                                      <label class="text-uppercase small pl-1" for="in-days">DAYS</label>
                                      <input type="number" id="in-days" class="form-control" v-model="openCalendarData.days">
                                  </div>
                                  <div class="col-md-6">
                                      <label class="text-uppercase small pl-1" for="in-places">PLACES</label>
                                      <input type="number" id="in-places" class="form-control" v-model="openCalendarData.places">
                                  </div>
                              </div>
                              <div class="form-row" style="margin-top: 10px;">
                                  <div class="col-md-6">
                                      <label class="text-uppercase small pl-1" for="in-price">PRICE</label>
                                      <input type="number" id="in-price" class="form-control" v-model="openCalendarData.price">
                                  </div>
                                  <div class="col-md-6">
                                      <label class="text-uppercase small pl-1" for="in-places">CURRENCY</label>
                                      <select class="form-control" @change="onOpenCalendarSetCurrency" style="height: 42px;">
                                          <option v-for="option of dpConfig.options" :key="option.value" v-bind:selected="option.value === openCalendarData.currency">{{ option.label }}</option>
                                      </select>
                                  </div>
                              </div>
                              <div class="form-row" style="margin-top: 10px;">
                                  <div class="col-md-6">
                                      <label class="text-uppercase small pl-1" for="in-price">DEPOSIT<span class="deposit-header" style="margin-left: 2px;">(in %)</span></label>
                                      <input type="number" id="in-price" class="form-control" v-model="openCalendarData.deposit">
                                  </div>
                              </div>
                              <div class="form-row" style="margin-top: 15px;">
                                  <label class="text-uppercase small pl-1" for="in-status" style="width: 100%;">STATUS</label>
                                  <customSwitch2 id="in-status" name="open-calendar-status" :status="openCalendarData.status"></customSwitch2>
                              </div>
                          </div>
                          <div class="col-5">
                              <div class="un-dates-wrapper">
                                  <label class="text-uppercase small pl-1">UNAVAILABLE DATES</label>
                                  <div class="unavail-dates">
                                      <div class="unavail-dates-row" v-for="dates of openCalendarData.unavailableDates" :key="dates.id">
                                          <input type="date" class="form-control" v-model="dates.date">
                                          <div class="ud-actions">
                                              <div class="r-action ud-clone" @click="onCloneUnAvailDate(dates)"></div>
                                              <div class="r-action ud-delete" @click="onDeleteUnAvailDate(dates.id)"></div>
                                          </div>
                                      </div>
                                  </div>
                                  <button class="btn btn-addDate" @click="onAddDate">
                                      <AddIcon /> Add date
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </template>
      <template v-slot:footer>
          <div class="modal-footer" :style="`justify-content: ${dataType === 'specific' ? 'space-between' : 'flex-end'}`">
            <button class="btn btn-add" @click="onAddInstance" v-if="dataType === 'specific'">
                <div class="i--add-instance"></div>
                Add instance
            </button>
            <button
              class="btn btn-primary btn-save-holiday"
              :class="!isHolidayValid(instances) ? 'btn-add-holiday' : ''"
              @click="onSaveHoliday(instances, true)"
              :disabled="!isHolidayValid(instances)">
                Save Holiday
             </button>
        </div>
      </template>
  </modal>
</template>

<script>
import eventHub from '../utils/eventHub'
import ModalVue from './Modal.vue'
import Switch2Vue from './switch/Switch-2.vue'
import SwitchVue from './switch/Switch.vue'
import moment from 'moment'
import Autocomplete from '../components/AutocompleteCompany.vue'
import _ from 'lodash'
import Utils from '../utils/Utils'
import CalendarSelect from '../components/CalendarSelect.vue'
import AddIcon from '../components/common/svg/AddIcon.vue'
import ListIcon from '../components/common/svg/ListIcon.vue'
import CalendarIcon from '../components/common/svg/CalendarIcon.vue'

export default {
  name: 'add-holidays-modal',
  props: ['openModal', 'modalName', 'editing', 'companies', 'userRole'],
  components: {
      modal: ModalVue,
      customSwitch: SwitchVue,
      customSwitch2: Switch2Vue,
      autocomplete: Autocomplete,
      CalendarSelect,
      CalendarIcon,
      ListIcon,
      AddIcon
  },
  data () {
      return {
          token: localStorage.getItem('accessToken'),
          currencies: [],
          dpConfig: {
              options: [
                  { label: 'British Pound', value: 'GBP' },
                  { label: 'US Dollar', value: 'USD' },
                  { label: 'Euro', value: 'EUR' }
              ],
              borderRadius: '5px',
              backgroundColor: 'transparent',
              placeholder: ''
          },
          dataType: 'specific',
          rootHoliday: null,
          holidayName: null,
          numberDays: 0,
          companyName: [],
          comName: null,
          companyObject: undefined,
          companyId: 0,
          openCalendarId: null,
          msgerror: '',
          errormsg: false,
          currentCurrency: 'GBP',
          count: 1,
          unAvailCounter: 1,
          instanceModel: {
              id: 1,
              date: '',
              places: 0,
              days: 0,
              price: 0,
              currency: 'GBP',
              deposit: 0,
              status: 1,
              availableDates: {
                  from: '',
                  to: ''
              },
              unavailableDates: []
          },
          instances: [{
              id: -1,
              date: moment().format('YYYY-MM-DD'),
              places: 0,
              days: 0,
              price: 0,
              currency: 'GBP',
              deposit: 0,
              status: 1,
              availableDates: {
                  from: moment().format('YYYY-MM-DD'),
                  to: ''
              },
              unavailableDates: []
          }],
          openCalendarData: {
              id: -1,
              date: '',
              places: 0,
              days: 0,
              price: 0,
              currency: 'GBP',
              deposit: 0,
              status: 1,
              availableDates: {
                  from: '',
                  to: ''
              },
              unavailableDates: []
          },
          operators: [],
          tempInstances: [],
          deleteInstances: [],
          errors: null,
          isFormInit: false,
          disableActions: false
      }
  },
  created () {
      eventHub.$on('specific-status', (values) => {
          const { value, id } = values
          if (id) {
              const instIndex = this.instances.findIndex(inst => inst.id === id)
              if (instIndex > -1) {
                  this.instances[instIndex].status = value ? 1 : 0
              }
          }
      })
      eventHub.$on('open-calendar-status', (value) => {
          this.instances[0].status = value
      })

      eventHub.$on('edit-holiday-data', (data) => {
          this.populate(data)
          this.compoundOpenCalendar(data)
      })

      eventHub.$on('enable-actions', (condition) => {
          this.disableActions = !condition
      })

      eventHub.$on('add-temp-instance', ({ instance }) => {
          if (instance) this.tempInstances.push(instance)
      })
  },
  computed: {
      computeNumberDays () {
          return this.instances
      }
  },
  methods: {
      async saveHolidayRequest (payload, _option) {
          return await this.axios.post(process.env.VUE_APP_API + `/holiday/${_option || 'create'}`, payload)
                  .then(result => {
                      return { ok: true, data: result }
                  })
                  .catch((error) => {
                  this.errormsg = true
         if (error.response) {
    // Request made and server responded
    this.msgerror = error?.response?.data?.data?.name_holiday[0]
    this.$toast.error('Unable to find error, check the filled data again.', {
              position: 'top-right',
              duration: 2000
            })
  }
    })
      },
      async requestCurrencyData (withLoading) {
    /* let tempHolidays = [] */
    const companyId = this.loggedUser.id
    const payload = new FormData()

    payload.append('company_id', companyId)
    if (withLoading) {
      this.loader = this.$loading.show()
      this.isLoading = true
    }

    await this.axios
      .get(process.env.VUE_APP_API + '/aux/currencys')
      .then(result => {
        const { status } = result
        if ((status === 200 || status === 201) && result?.data.data) {
          this.currencies = result?.data?.data
        }
      })
      .catch(Utils.$handleHttpErrors)
      if (withLoading) {
        this.loader.hide()
        this.isLoading = false
      }
  },
      async onDeleteInstance (instanceId) {
          return await this.axios.delete(
              `${process.env.VUE_APP_API}/holiday/deleteavail/${instanceId}`, {
              headers: {}
          })
          .then(response => {
              if (response?.status === 200 || response?.status === 201) return { ok: true, response }
              return { ok: false, response }
          })
          .catch(Utils.$handleHttpErrors)
      },
      async onSaveHoliday (instances, close, event) {
          const payload = this.dataType === 'specific'
                          ? this.generatePayload(this.holidayName, instances)
                          : this.generatePayloadopenCalendar(this.holidayName, this.openCalendarData)

          if (this.editing) {
              payload.append('holiday_id', this.rootHoliday.id)
          }

          if (this.dataType === 'specific') {
              const newHolidayResult = await this.saveHolidayRequest(
                  payload, this.editing ? 'update' : 'create'
              )

              if (this.editing && this.tempInstances.length > 0) {
                  for (let index = 0; index < this.tempInstances.length; index++) {
                      await this.onSaveHolidayInstance(this.tempInstances[index])
                  }
              }

              if (this.editing && this.deleteInstances.length > 0) {
                for (let index = 0; index < this.deleteInstances.length; index++) {
                    await this.onDeleteInstance(this.deleteInstances[index].id)
                }
              }

              if (newHolidayResult?.ok) {
                  eventHub.$emit('request-holidays', {
                    closeModal: close, request: true
                  })
              }
          } else {
              // console.log('Open Calendar Data::  ', this.openCalendarData)
          }
      },
      generatePayload (holidayName, instances) {
          const userData = JSON.parse(localStorage.getItem('userData'))
          var formData = new FormData()
          formData.append('name_holiday', holidayName)
          formData.append('status', 1)
          if (userData.role === 'SuperAdmin') {
          formData.append('company_id', this.companyId)
          } else {
          formData.append('company_id', userData.company_id)
          }
          formData.append('activity_id', 1)
          formData.append('duration_days', this.numberDays)
          formData.append('currency', this.currentCurrency)
          formData.append('calendar_id', this.openCalendarId ?? '')
          if (this.editing) {
            formData.append('_method', 'PUT')
            instances = instances.filter(
              (inst) => !inst.id.toString().includes('temp-')
            )
          }

          if (instances && instances.length > 0) {
              for (let index = 0; index < instances.length; index++) {
                  Object.keys(instances[index]).forEach(instKey => {
                      const mapProp = this.mapHoliday(instKey)
                      if (mapProp) {
                          switch (mapProp) {
                              case 'currency':
                                  formData.append(`instances[${index}][${mapProp}]`, this.currentCurrency)
                                  break
                              case 'number_days':
                                  formData.append(`instances[${index}][${mapProp}]`, this.numberDays)
                                  break
                              default:
                                  formData.append(`instances[${index}][${mapProp}]`, instances[index][instKey])
                                  break
                          }
                      }
                  })
                  formData.append(`instances[${index}][multiple_dates]`, 0)
              }
          }
          return formData
      },
      generatePayloadopenCalendar (holidayName, data) {
          const userData = JSON.parse(localStorage.getItem('userData'))
          var formData = new FormData()
          formData.append('name_holiday', holidayName)
          formData.append('status', 1)
          formData.append('company_id', userData.company_id)
          formData.append('activity_id', 1)
          formData.append('duration_days', Number(data.days))
      },
      mapHoliday (prop) {
          switch (prop) {
              case 'places':
                  return 'seats'
              case 'days':
                  return 'number_days'
              case 'price':
              case 'status':
              case 'currency':
              case 'deposit':
                  return prop
              case 'date':
                  return 'date_start'
              case 'id':
                  return this.editing ? prop : null
              default:
                  // eslint-disable-next-line no-useless-return
                  return
          }
      },
      onAddInstance (event) {
          const instanceClone = this.getInstance()
          this.instances.push(instanceClone)
          if (this.editing) {
            this.tempInstances.push(instanceClone)
          }
      },
      onOpenCalendarSetCurrency (event) {

      },
      getErrors (instance) {
          var errorStr = ''
          return errorStr
      },
      async onRemoveHolidayInstance (instance, event) {
          if (!this.disableActions) {
              if (this.editing) {
                  this.disableActions = true
                  if (instance?.id?.toString().includes('temp-')) {
                      this.instances = this.instances.filter(
                        inst => inst.id !== instance.id
                      )
                  } else {
                      // await this.onDeleteInstance(instance.id)
                      this.instances = this.instances.filter(
                        inst => inst.id !== instance.id
                      )
                      this.deleteInstances.push(instance)
                      // eventHub.$emit('refresh-holidays', true)
                  }
                  if (this.tempInstances.length > 0) {
                      this.tempInstances = this.tempInstances.filter(
                        inst => inst.id !== instance.id
                      )
                  }
              } else {
                  this.instances = this.instances.filter(inst => inst.id !== instance.id)
              }
          }
          this.disableActions = false
      },
      onSaveHolidayInstance (instance) {
          const instanceClone = _.cloneDeep(instance)
          if (this.editing) {
              if (instanceClone.id.toString().includes('temp-')) {
                  eventHub.$emit('edit-clone--instance', {
                      holiday: { ...this.rootHoliday }, instance: instanceClone
                  })
              } else {
                  this.onSaveHoliday([instanceClone], false)
              }
          }
      },
      onCloneHolidayInstance (instance, event) {
          if (!this.disableActions) {
              const instanceClone = Object.assign({}, _.cloneDeep(instance))
              instanceClone.id = 'temp-' + this.count
              this.instances.push(instanceClone)
              this.count++
              if (this.editing) {
                this.tempInstances.push(instanceClone)
              }
          }
      },
      handleSavedInstance (instance) {
          return instance?.saving ? 'saved-row' : ''
      },
      onAddDate (event) {
          this.openCalendarData.unavailableDates.push({
              id: this.unAvailCounter,
              date: null
          })
          this.unAvailCounter++
      },
      onCloneUnAvailDate (unDate) {
          const clonedDate = Object.assign({}, unDate)
          clonedDate.id = this.unAvailCounter
          this.instances[0].unavailableDates.push(clonedDate)
          this.unAvailCounter++
      },
      onDeleteUnAvailDate (id) {
          this.instances[0].unavailableDates = this.instances[0].unavailableDates.filter(date => date.id !== id)
      },
      onChangeDateFrom (_instance, event) {
          const instanceIndex = this.instances.findIndex(inst => inst.id === _instance.id)
          if (instanceIndex > -1) {
              this.instances[instanceIndex].date = this.formatDate(event.target.value)
              this.instances[instanceIndex].availableDates.from = this.formatDate(event.target.value)
              this.instances[instanceIndex].availableDates.to = this.addDaysToDate(event.target.value, _instance.days)
          }
      },
      onOpenCalendarDateFrom (event) {
          this.openCalendarData.date = event.target.value
      },
      onChangeDateTo () {
          const diff = this.datesDiff(this.instances[0].availableDates.from, this.instances[0].availableDates.to)
          if (diff) this.instances[0].days = diff
      },
      validateHolidayData (holidays) {
          var isValid = true
          if (holidays.length > 0) {
              const invalidDates = holidays.filter(
                  (holiday) => holiday?.availableDates?.from === '' && holiday?.date === ''
              )
              if (invalidDates.length > 0) isValid = false
          }
          return isValid
      },
      isHolidayValid (data) {
          if (data) {
              if (this.holidayName && this.validateHolidayData(data)) {
                  return true
              }
          }
          return false
      },
      formatDate (date) {
          return moment(date).format('YYYY-MM-DD')
      },
      addDaysToDate (date, daysToAdd) {
          return moment(date).add(daysToAdd, 'day').format('YYYY-MM-DD')
      },
      datesDiff (d1, d2) {
          const date1 = new Date(d1)
          const date2 = new Date(d2)
          const diffTime = Math.abs(date2 - date1)
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
          return diffDays
      },
      async handleTempInstances () {
          if (this.editing) {
              if (this.tempInstances.length > 0) {
                  eventHub.$emit('start-finish-edit', true)
                  for (let index = 0; index < this.tempInstances.length; index++) {
                      const fInstance = this.rootHoliday.instances.find(
                          (inst) => inst.reference === this.tempInstances[index].reference
                      )
                      const { id } = fInstance || {}
                      if (id) {
                          await this.onDeleteInstance(id)
                      }
                  }
                  eventHub.$emit('end-finish-edit', true)
              }
          }
      },
      getInstance () {
          this.instanceModel.availableDates = {
              from: this.formatDate(moment().format('YYYY-MM-DD')),
              to: ''
          }
          this.instanceModel.unavailableDates = []
          const instanceClone = Object.assign({}, this.instanceModel)
          instanceClone.id = 'temp-' + this.count
          instanceClone.date = this.formatDate(moment().format('YYYY-MM-DD'))
          this.count++
          return instanceClone
      },
      compoundHolidayInstance (_instance, parent) {
          const { id, seats, price, currency, deposit, status } = _instance
          const toDate = this.addDaysToDate(_instance?.date_start, Number(_instance?.number_days || 0))
          return {
              id: id,
              date: _instance?.date_start || '',
              places: seats,
              days: _instance?.number_days || 0,
              price: price || 0,
              currency: currency || 'GBP',
              deposit: deposit || 0,
              status: status === undefined ? parent?.holiday_status : status,
              availableDates: {
                  from: _instance?.date_start,
                  to: toDate
              },
              unavailableDates: []
          }
      },
      compoundOpenCalendar (holiday) {
          // eslint-disable-next-line camelcase
          const { id, holiday_duration, holiday_status } = holiday
          var instance
          if (holiday?.instances?.length > 0) {
              instance = holiday?.instances[0]
          }
          this.openCalendarData = {
              id: id,
              date: instance?.date_start || '',
              places: instance?.seats || 0,
              days: holiday_duration,
              price: instance?.price || 0,
              currency: instance?.currency || '',
              deposit: instance?.deposit || 0,
              status: holiday_status,
              availableDates: {
                  from: instance?.date_start,
                  to: ''
              },
              unavailableDates: []
          }
      },
      populate (data) {
          this.rootHoliday = { ...data }
          this.instances = []
          this.comName = data.company_name
          eventHub.$emit('set-company', { company: data.company_name })
          this.holidayName = data.holiday_name
          this.numberDays = data.holiday_duration
          this.currentCurrency = data.holiday_currency
          this.openCalendarId = data.calendar_id
          if (data && data.instances.length > 0) {
              data.instances.forEach((instance, index) => {
                  const compInst = this.compoundHolidayInstance(instance, data)
                  this.instances.push(compInst)
              })
          }
      },
      reset () {
          this.rootHoliday = null
          this.msgerror = ''
          this.errormsg = false
          this.holidayName = null
          this.numberDays = 0
          this.currentCurrency = 'GBP'
          this.openCalendarId = null
          const instanceClone = this.getInstance()
          this.instances = [instanceClone]
          this.openCalendarData = null
          this.isFormInit = false
          this.disableActions = false
          this.tempInstances = []
          this.deleteInstances = []
      }
  },
  watch: {
      async openModal (newValue, oldValue) {
          if (!newValue) {
              this.reset()
          }
      },
      dataType (newValue, oldValue) {

      },
      companies (newValue, oldValue) {
          this.companyObject = newValue
          this.companyObject = this.companyObject.filter(function (a) {
               return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
}, Object.create(null))
          for (const company of this.companyObject) {
              this.companyName.push(company[1])
          }
      }
  },
  unmounted () {
      eventHub.$off('edit-holiday-data')
      eventHub.$off('specific-status')
      eventHub.$off('open-calendar-status')
      eventHub.$off('enable-actions')
      eventHub.$off('add-temp-instance')
  },
   async mounted () {
    this.loggedUser = JSON.parse(localStorage.getItem('userData'))
    const { status } = this.loggedUser || {}
    if (status === 1 || status === '1') await this.requestCurrencyData(true)
  var _this = this
  eventHub.$on('set-company', (e) => {
    for (const company of this.companyObject) {
      if (company[1] === e.company) {
        _this.companyId = parseInt(company[0])
      }
    }
  })
}
}
</script>

<style lang="scss">
  #add-holidays {

      .temp-hidden {
          display: none !important;
      }

      .modal {

          &__dialog {
              // min-width: 65vw;  // before
              min-width: 55vw;
          }

          &__body {

              .ah-wrapper {
                  width: 100%;

                  .instance-errors {
                      width: 100%;
                      font-size: 10px;
                      color: #EF6565;
                      padding: 5px 8px 0;
                      display: none;
                  }

                  .row.body-header {
                      margin: 0;
                  }

                  .holiday-type {
                      padding: 0;
                      display: flex;
                      justify-content: flex-end;
                  }

                  .holiday-commons {
                      display: flex;
                      flex-flow: column;
                      width: 100%;

                      input, select {
                          height: 40px;
                      }
                  }

                  .custom-input {
                      height: 45px;

                      input[type=radio] ~ label {
                          border: 1px solid transparent;
                          color: $secondary-gray;
                          font-size: 13px !important;
                      }
                  }
                  .custom-input > input[type=radio] ~ label {
                      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
                  }
                  .custom-input > input[type=radio]:checked ~ label {
                      background-color: $secondary-gray;
                      color: #ffffff;

                      svg.open-calendar-data {
                          fill: $secondary-gray !important;
                          stroke: #ffffff;
                      }
                      svg {
                          fill: #ffffff !important;
                      }
                  }
                  .form-group.add-operator {
                      margin-right: 20px;

                      select {
                          width: 160px;
                          height: 40px;
                      }
                  }
                  .form-group label {
                      font-size: 12px !important;
                      color: $secondary-gray;
                  }
                  .form-control {
                      font-size: 14px;
                  }
                  .form-group .custom-input label {
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      height: 45px;
                      background-color: #EFEDFD;
                  }
                  .form-group label.specific-data svg {
                      width: 16px;
                      fill: $secondary-gray;
                      margin-right: 4px;
                  }
                  .form-group label.open-calendar-data svg {
                      width: 22px;
                      stroke: $secondary-gray;
                      fill: #efedfd !important;
                      margin-right: 4px;
                  }

                  .r-action {
                      width: 33px;
                      height: 33px;
                      border-radius: 50%;
                      margin: 0 5px;
                      cursor: pointer;
                  }
                  .r-action.action-disabled {
                      opacity: 0.4;
                  }
                  .t-add-holidays {
                      margin: 25px 0 0;
                  }
                  .table-add-holidays {
                      width: 100%;

                      &-header {
                          display: flex;
                          flex-direction: row;
                          padding: 6px 10px;

                          .t-th:nth-child(1) {
                              // width: 19%;
                              width: 22% !important;
                          }
                          .t-th:not(.row-status) {
                              // width: 12%;
                              width: 16%;
                              padding: 0 5px;
                          }
                          .t-th.row-status {
                              width: 12%;
                              // margin-left: 20px;
                          }
                          .t-th.t-actions {
                              // width: 14%;
                              width: 18%;
                              padding: 0 5px;
                          }
                      }

                      &-body {
                          display: flex;
                          flex-direction: column;
                          max-height: 50vh;
                          overflow-y: auto;
                      }

                      &-row {
                          display: flex;
                          // flex-direction: row;
                          flex-direction: column;
                          border: 1px solid rgba(126, 132, 163, 30%);
                          border-radius: 6px;
                          padding: 6px 0px;
                          margin-bottom: 8px;
                          position: relative;

                          .t-row--wrapper {
                              width: 100%;
                              display: flex;
                              flex-flow: row;
                          }

                          .t-row, .t-actions {

                              .form-control {
                                  height: 40px;
                              }
                              select.form-control {
                                  padding: 0;
                                  word-wrap: unset;
                                  overflow: hidden !important;
                                  text-overflow: ellipsis;
                                  width: 100%;
                                  white-space: nowrap;
                              }
                          }

                          .t-row:nth-child(1) {
                              // width: 17%;
                              width: 22%;
                          }
                          .t-row {
                              // width: 12%;
                              width: 16%;
                              padding: 0 5px;
                          }
                          .t-row.row-status {
                              width: 12%;
                          }
                          .t-actions {
                              // width: 14%;
                              width: 18%;
                              padding: 0 5px;
                              display: inline-flex;
                              justify-content: flex-end;
                              align-items: center;

                              .a-save {
                                  border: 1px solid #60CE5B;
                                  background: url('../assets/img/true.svg');
                                  background-repeat: no-repeat;
                                  background-position-x: center;
                                  background-position-y: center;
                              }
                              .a-remove {
                                  border: 1px solid #EF6565;
                                  background: url('../assets/img/false.svg');
                                  background-repeat: no-repeat;
                                  background-position-x: center;
                                  background-position-y: center;
                              }
                              .a-clone {
                                  border: 1px solid $secondary-gray;
                                  background: url('../assets/img/clone.svg');
                                  background-repeat: no-repeat;
                                  background-position-x: center;
                                  background-position-y: center;
                              }
                          }
                      }

                      &-row.saved-row {
                          // box-shadow: 0px 0px 3px 0px #60ce5b;
                          // box-shadow: 0px 0px 3px 0px #49a649;
                          box-shadow: 0px 0px 2px 1px #0ebb0e;

                          .saving-msm {
                              position: absolute;
                              font-size: 12px;
                              color: #0ebb0e;
                              right: 7px;
                              bottom: -20px;
                          }
                      }
                  }

                  .open-calendar {
                      width: 100%;

                      label.text-uppercase {
                          color: $secondary-gray;
                          font-size: 12px !important;
                      }

                      .un-dates-wrapper {
                          min-height: 50px;
                          border-radius: 10px;
                          background-color: #f9f9f9;
                          padding: 12px;

                          .unavail-dates {
                              margin-bottom: 15px;

                              &-row {
                                  width: 100%;
                                  display: inline-flex;
                                  flex-direction: row;
                                  padding: 8px;
                                  background-color: #ffffff;
                                  border-radius: 8px;
                                  margin-top: 5px;
                                  border: 1px solid #d8d8d8;

                                  .ud-actions {
                                      width: 40%;
                                      display: inline-flex;
                                      align-items: center;

                                      .ud-clone {
                                          border: 1px solid #d8d8d8;
                                          background: url('../assets/img/clone.svg');
                                          background-repeat: no-repeat;
                                          background-position-x: center;
                                          background-position-y: center;
                                      }
                                      .ud-delete {
                                          border: 1px solid #d8d8d8;
                                          background: url('../assets/img/trash.svg');
                                          background-repeat: no-repeat;
                                          background-position-x: center;
                                          background-position-y: center;
                                      }
                                  }
                              }
                          }

                          .btn-addDate {
                              width: 100%;
                              height: 42px;
                              background-color: #EFEDFD;
                              color: $secondary-gray;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              font-size: 15px;

                              svg {
                                  margin-right: 6px;
                                  width: 14px;
                                  height: 14px;
                                  fill: $secondary-gray;
                              }
                          }
                      }
                  }

                  input[type="date"] {
                      padding: 5px;
                  }
                  input[type="date"]::-webkit-calendar-picker-indicator {
                      width: 16px;
                      height: 22px;
                      background: url('../assets/img/calendar.svg');
                      background-repeat: no-repeat;
                  }

                  .deposit-header {
                      font-size: 10px;
                      text-transform: lowercase;
                  }
              }
          }

          &__footer {

              .modal-footer {
                  // justify-content: space-between;
                  padding: 0;
                  border-top: none;
                  margin-top: 25px;

                  .btn-add, .btn-primary {
                      font-size: 15px;
                  }
                  .btn-save-holiday,
                  .btn-add {
                      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
                  }
                  .btn-add {
                      background-color: #EFEDFD;
                      color: $secondary-gray;
                      display: flex;
                      align-items: center;

                      svg {
                          margin-right: 6px;
                          width: 14px;
                          height: 14px;
                          fill: $secondary-gray;
                      }
                      div.i--add-instance {
                          -webkit-mask-image: url('../assets/img/add-holiday/add-instance.svg');
                          mask-image: url('../assets/img/add-holiday/add-instance.svg');
                          background-color: $secondary-gray;
                          background-repeat: no-repeat;
                          background-position-x: center;
                          background-position-y: center;
                          margin-right: 6px;
                          width: 14px;
                          height: 14px;
                      }
                  }
                  .btn-add:hover {
                      background-color: #e0e1ed;
                  }
                  .btn-add-holiday {
                      cursor: no-drop;
                  }
              }
          }
      }

      input.type-number::-webkit-outer-spin-button,
      input.type-number::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }

      input[type=number].type-number {
          -moz-appearance: textfield;
      }
  }
</style>
