<template>
    <div class="wrapper w-100" id="sf-holidays-table" ref="holidaysView">
      <div class="d-flex w-100 p-4">
        <h1 v-if="loggedUser?.role.toLowerCase() === 'superadmin'" class="page-title">Holidays</h1>
        <h1 v-else class="page-title">My Holidays</h1>
        <div class="d-flex ml-auto">
          <div class="input-group search-control">
            <input name="search"
              v-model="searchInput" type="text"
              class="form-control search-input h-48 rounded-right-0 rounded-left-0 w-auto"
              placeholder="Search by anything...">
            <div class="input-group-append h-48">
              <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
          </div>
          <!-- <a class="btn btn-outline-primary h-48 w-auto mx-3 btn-inner shadow-sm">
            <i class="fas fa-filter mr-2"></i>Filters
          </a> -->
          <a v-if="loggedUser?.role.toLowerCase() === 'operator' | loggedUser?.role.toLowerCase() === 'superadmin' && selectedRows.length > 0"
            @click="onCalendarizeHolidays" class="btn btn-outline-secondary h-48 w-auto mx-3 btn-inner shadow-sm">
            <i class="fas fa-calendar-alt mr-2"></i>Calendarize
          </a>
          <a v-if="loggedUser?.role.toLowerCase() === 'operator' | loggedUser?.role.toLowerCase() === 'superadmin' && selectedRows.length > 0"
              @click="onDeleteHolidays"
              class="btn btn-outline-delete h-48 w-auto mx-3 btn-inner shadow-sm">
            <i class="fas fa-trash-alt mr-2"></i>Delete Holidays
          </a>
          <a v-if="loggedUser.role.toLowerCase() === 'operator' | loggedUser.role.toLowerCase() === 'superadmin' | loggedUser.role.toLowerCase() === 'agent'"
              @click="onAddHolidays"
              class="btn btn-primary h-48 w-auto btn-inner shadow-sm">
            <i class="fas fa-plus-circle mr-2"></i>Add Holiday
          </a>
        </div>
      </div>
      <div class="px-4 pb-4">
        <table-lite
          :has-checkbox="tableData.hasCheckbox"
          :columns="tableData.columns"
          :rows="filteredData"
          :user-role="userRole"
          :messages="tableData.messages"
          :sortable="tableData.sortable"
          :total="tableData.totalRecords"
          @get-now-page="onPageChange"
          @return-checked-rows="updateCheckedRows">
        </table-lite>
      </div>
    </div>
    <modal :openModal="openModal" modalName="embed-holidays-modal" :rowData="rowData" :langs="formatedLangs"></modal>
    <AddHolidaysModal :openModal="openAddHolidays" modalName="add-holidays-modal" :userRole="userRole" :companies="companies" :editing="editing" />
    <CalendarizeHolidaysModal :openModal="openCalendarizeHolidays" modalName="calendarize-holidays-modal" :userRole="userRole"
    :holidays="selectedRows" />
</template>

<script>
import TableLite from '../components/table/TableLite.vue'
import _ from 'lodash'
import AddHolidaysModal from '../components/AddHolidays.vue'
import CalendarizeHolidaysModal from '../components/CalendarizeHolidays.vue'
import EmbedHolidayVue from '../components/EmbedHoliday.vue'
import Utils from '../utils/Utils'
import eventHub from '../utils/eventHub'

export default {
  name: 'holidays',
  props: [],
  components: {
    modal: EmbedHolidayVue,
    AddHolidaysModal,
    CalendarizeHolidaysModal,
    TableLite
  },
  data () {
    const userData = JSON.parse(localStorage.getItem('userData'))
    return {
      token: localStorage.getItem('accessToken'),
      openModal: false,
      openAddHolidays: false,
      openCalendarizeHolidays: false,
      editing: false,
      searchInput: '',
      observer: null,
      loggedUser: userData,
      userRole: null,
      isLoading: false,
      rowData: null,
      companie_id: 0,
      companies: undefined,
      companiesObject: undefined,
      selectedRows: [],
      tableData: {
        columns: [
          {
            label: '',
            field: 'id',
            sort_path: 'id',
            width: '3%',
            sortable: false,
            isKey: true
          },
          {
            label: 'Name',
            field: 'holiday_name',
            sort_path: 'holiday_name',
            width: '17%',
            sortable: true,
            isKey: false
          },
          {
            label: 'Date',
            field: 'date_start',
            sort_path: 'instances[0].date_start',
            width: '14%',
            sortable: true,
            isKey: false
          },
          {
            label: 'Places',
            field: 'seats',
            sort_path: 'instances[0].seats',
            width: '9%',
            sortable: true,
            isKey: false
          },
          {
            label: 'Days',
            field: 'holiday_duration',
            sort_path: 'holiday_duration',
            width: '9%',
            sortable: true,
            isKey: false
          },
          {
            label: 'Price',
            field: 'price',
            sort_path: 'instances[0].price',
            width: '12%',
            sortable: true,
            isKey: false
          },
          {
            label: 'Operator',
            field: 'company_name',
            sort_path: 'company_name',
            width: '11%',
            sortable: true,
            isKey: false
          },
          {
            label: 'Status',
            field: 'holiday_status',
            sort_path: 'instances[0].status',
            width: '9%',
            sortable: true,
            isKey: false
          },
          {
            label: 'ACTIONS',
            field: 'actions',
            width: '14%'
          }
        ],
        rows: [],
        totalRecords: 0,
        messages: {
          pagingInfo: 'Showing {0}-{1} of {2}',
          pageSizeChangeLabel: 'Holidays per page ',
          gotoPageLabel: 'Go to page:',
          noDataAvailable: 'No data'
        },
        hasCheckbox: true,
        sortable: {
          order: 'id',
          sort: 'desc'
        }
      },
      filteredData: [],
      originalData: [],
      langs: null,
      formatedLangs: [],
      expandedRows: []
    }
  },
  methods: {
    async requestHolidaysData (withLoading) {
      let tempHolidays = []
      const companyId = this.loggedUser.company_id
      const payload = new FormData()

      if (withLoading) {
        this.loader = this.$loading.show()
        this.isLoading = true
      }

      payload.append('company_id', companyId)

      const reqResult = await this.axios
        .post(process.env.VUE_APP_API + '/holiday/list', payload)
        .then(result => {
          const { status } = result
          if ((status === 200 || status === 201) && result?.data) {
            const holidays = result.data['0']?.collection
            const langs = result.data['0']?.languages
          this.companies = holidays.map(function (item) {
            return [item.company_id, item.company_name]
          })
            return { ok: true, data: holidays, status, langs }
          }
          return { ok: false, data: [], status, langs: {} }
        })
        .catch(Utils.$handleHttpErrors)

      if (reqResult?.ok && reqResult?.data.length > 0) {
        tempHolidays = _.cloneDeep([...reqResult.data])
        this.originalData = _.cloneDeep([...reqResult.data])
        this.langs = { ...reqResult.langs }
        const tempHolidaysInstances = {}
        for (let index = 0; index < tempHolidays.length; index++) {
          tempHolidaysInstances[tempHolidays[index].id] = [...tempHolidays[index].instances]
        }
        this.filteredData = this.mergeInstancesToHolidays(
          [...tempHolidays],
          { ...tempHolidaysInstances }
        )
        this.tableData.rows = _.cloneDeep([...this.filteredData])
        this.tableData.totalRecords = this.filteredData.length
      } else {

      }

      if (withLoading) {
        this.loader.hide()
        this.isLoading = false
      }
    },
    async requestDelete (holidayId) {
      return await this.axios
        .delete(`${process.env.VUE_APP_API}/holiday/delete/${holidayId}`)
        .then(response => {
          if (response?.status === 200 || response?.status === 201) return { ok: true, response }
          return { ok: false, response }
        })
        .catch(Utils.$handleHttpErrors)
    },
    async requestDeleteInstance (instanceId) {
      return await this.axios
        .delete(`${process.env.VUE_APP_API}/holiday/deleteavail/${instanceId}`)
        .then(response => {
          if (response?.status === 200 || response?.status === 201) return { ok: true, response }
          return { ok: false, response }
        })
        .catch(Utils.$handleHttpErrors)
    },
    async requestUpdateHoliday (payload, current, instance) {
      return await this.axios
        .post(process.env.VUE_APP_API + '/holiday/update', payload)
        .then(result => {
            return { ok: true, data: result }
        })
        .catch((error) => {
          if (error?.response?.data) {
            this.$toast.error('Unable to find error, check the filled data again.', {
              position: 'top-right',
              duration: 2000
            })
          }
          if (error?.response?.data?.message === 'Unauthenticated.') {
            return eventHub.$emit('logout', true)
          }
          console.log('Error: ', error)
          return { ok: false, error }
        })
    },
    async requestUpdateInstance (payload) {
      return await this.axios
        .post(process.env.VUE_APP_API + '/holiday/createavail', payload)
        .then(result => {
            return { ok: true, data: result }
        })
        .catch(Utils.$handleHttpErrors)
    },
    async cloneHolidayRow (payload) {
      return await this.axios
        .post(process.env.VUE_APP_API + '/holiday/create', payload)
        .then(result => {
          return { ok: true, data: result }
        })
        .catch(Utils.$handleHttpErrors)
    },
    async requestUpdateSeats (payload) {
      return await this.axios
        .post(process.env.VUE_APP_API + '/holiday/seats', payload)
        .then(result => {
          return { ok: true, data: result }
        })
        .catch(Utils.$handleHttpErrors)
    },
    generatePayload (data, cloning) {
        const { instances } = data
        var formData = new FormData()
        formData.append('name_holiday', data.holiday_name)
        formData.append('status', data.holiday_status)
        formData.append('company_id', this.loggedUser.company_id)
        formData.append('activity_id', data.activity_id)
        formData.append('duration_days', data.holiday_duration)
        formData.append('currency', data.holiday_currency)
        formData.append('calendar_id', data.calendar_id ?? '')
        if (!cloning) {
          formData.append('holiday_id', data.id)
          formData.append('_method', 'PUT')
        }

        if (instances && instances.length > 0) {
            for (let index = 0; index < instances.length; index++) {
                Object.keys(instances[index]).forEach(prop => {
                  if (prop !== 'date_end') formData.append(`instances[${index}][${prop}]`, instances[index][prop])
                })
            }
        }

        return formData
    },
    generateRowPayload (data, newData, instIndex) {
      const { instances } = data
        var formData = new FormData()
        formData.append('name_holiday', data.holiday_name)
        formData.append('holiday_id', data.id)
        formData.append('status', data.holiday_status)
        formData.append('company_id', this.loggedUser.company_id)
        formData.append('activity_id', data.activity_id)
        formData.append('duration_days', data.holiday_duration)
        formData.append('currency', data.holiday_currency)
        formData.append('calendar_id', data.calendar_id ?? '')
        formData.append('_method', 'PUT')

        if (instances && instances.length > 0) {
          Object.keys(instances[instIndex]).forEach(prop => {
            if (prop !== 'date_end') {
              formData.append(
                `instances[${instIndex}][${prop}]`,
                // eslint-disable-next-line no-prototype-builtins
                newData.hasOwnProperty(prop) ? newData[prop] : instances[instIndex][prop]
              )
            }
          })
        }
        return formData
    },
    generateAgentRowPayload (instance, seats) {
      var formData = new FormData()
      formData.append('instance_id', instance.id)
      formData.append('seats', seats)

      return formData
    },
    generateCloneInstancePayload (data, rowData) {
        var formData = new FormData()
        formData.append('holiday_id', data.id)
        formData.append('seats', rowData?.seats || rowData?.places)
        formData.append('date_start', rowData?.date_start || rowData?.date)
        formData.append('multiple_dates', 0)
        formData.append('price', rowData?.price)
        formData.append('deposit', rowData?.deposit)
        formData.append('status', rowData?.status)

        return formData
    },
    mergeInstancesToHolidays (holidays, instances) {
      const newHolidaysFormat = [...holidays]
      return newHolidaysFormat
    },
    onAddHolidays () {
      this.editing = false
      this.openAddHolidays = true
    },
    onEditHoliday (rowData) {
      this.editing = true
      this.openAddHolidays = true
      const selectedRow = this.originalData.find(
        holiday => holiday.id === rowData.id
      )
      if (selectedRow) eventHub.$emit('edit-holiday-data', selectedRow)
    },
    onCalendarizeHolidays () {
      this.openCalendarizeHolidays = true
    },
    updateCheckedRows (rowsKey) {
      this.selectedRows = this.originalData.filter(instance => {
        return rowsKey.findIndex(
          // eslint-disable-next-line eqeqeq
          keyId => keyId == instance.id || instance.instances.findIndex(inst => inst.id == keyId) > -1
        ) > -1
      })
    },
    showAlert (type, layer) {
      const title = type === 'update' ? 'Save' : 'Delete'
      return this.$swal({
          icon: 'warning',
          title: `Do you really want to ${title} ${layer}?`,
          showCancelButton: true,
          confirmButtonText: 'Ok',
          confirmButtonColor: type === 'update' ? '#59c154' : '#ef6565'
      })
    },
    onPageChange (pageNo) {
      eventHub.$emit('clear-checked', true)
      this.closeExpandedRows()
    },
    scanRowMapper (prop, element) {
      switch (prop) {
        case 'holiday_status':
          return {
            value: element.checked ? 1 : 0,
            prop: 'status'
          }
        default:
          return {
            value: element.value,
            prop: prop
          }
      }
    },
    scanControllersRow (instanceId) {
      return document.querySelectorAll(`[data-row-id="${instanceId}"]`)
    },
    scanElementsRow (instanceId) {
      return document.querySelectorAll(`[data-row="${instanceId}"]`)
    },
    scanDataRow (instanceId) {
      const values = {}
      const elemFields = document.querySelectorAll(`[data-row="${instanceId}"]`)
      if (elemFields) {
        elemFields.forEach((elem) => {
          const field = elem.getAttribute('data-field')
          const mapResult = this.scanRowMapper(field, elem)
          values[mapResult.prop] = mapResult.value ?? ''
        })
      }
      return values
    },
    async handleSaveAgentInstance (holiday, instance) {
      let resp = null
      this.loader = this.$loading.show()
      this.isLoading = true
      const instanceClone = _.cloneDeep(instance)
      const fieldsData = this.scanDataRow(instanceClone.id)
      const { seats } = fieldsData
      const payload = this.generateAgentRowPayload(instance, seats)
      resp = await this.requestUpdateSeats(payload)
      if (resp.ok) {
        await this.requestHolidaysData()
      }
      this.loader.hide()
      this.isLoading = false
      return resp
    },
    async handleSaveInstance (holiday, instance) {
      let resp = null
      this.loader = this.$loading.show()
      this.isLoading = true
      const currentHoliday = _.cloneDeep(holiday)
      const instanceIndex = currentHoliday.instances.findIndex(
        item => item.id === instance.id
      )
      const fieldsData = this.scanDataRow(instance.id)
      if (instanceIndex > -1) {
        const payload = this.generateRowPayload(currentHoliday, fieldsData, instanceIndex)
        resp = await this.requestUpdateHoliday(payload, currentHoliday, instanceIndex)
        if (resp.ok) {
          await this.requestHolidaysData()
        }
      }
      this.loader.hide()
      this.isLoading = false
      return resp
    },
    async handleDeleteInstance (instanceId) {
      this.loader = this.$loading.show()
      this.isLoading = true
      const resp = await this.requestDeleteInstance(instanceId)
      if (resp.ok && resp?.response?.status === 201) {
        await this.requestHolidaysData()
      }
      this.loader.hide()
      this.isLoading = false
      return resp
    },
    async handleDeleteHoliday (holiday) {
      this.loader = this.$loading.show()
      this.isLoading = true
      const resp = await this.requestDelete(holiday.id)
      if (resp.ok && resp?.response?.status === 201) {
        await this.requestHolidaysData()
      }
      this.loader.hide()
      this.isLoading = false
    },
    async onDeleteHolidays () {
      if (this.userRole === 'operator' && this.selectedRows.length > 0) {
        this.showAlert('delete', 'selected holidays')
        .then(async (result) => {
          const { isConfirmed } = result
          if (isConfirmed) {
            this.loader = this.$loading.show()
            this.isLoading = true

            for (let index = 0; index < this.selectedRows.length; index++) {
              await this.requestDelete(this.selectedRows[index].id)
            }
            await this.requestHolidaysData(true)
            this.selectedRows = []
            eventHub.$emit('clear-checked', true)

            /* this.loader.hide()
            this.isLoading = false */
          }
        })
      }
    },
    async handleCloneHoliday (holiday) {
      const holidayClone = _.cloneDeep(holiday)
      this.$swal({
        icon: 'info',
        title: `Do you really want to clone holiday "${holidayClone.holiday_name}"?`,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#59c154'
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          this.loader = this.$loading.show()
          this.isLoading = true
          holidayClone.holiday_name += ' - (copy)'
          const payload = this.generatePayload(holidayClone, true)
          const resp = await this.cloneHolidayRow(payload)
          if (resp.ok) {
            await this.requestHolidaysData()
          }
          this.loader.hide()
          this.isLoading = false
        }
      })
    },
    handleBeforeSave (instanceId) {
      const saveBtn = document.querySelector(`#btn--save-${instanceId}`)
      if (saveBtn) {
        saveBtn.classList.add('action-hidden')
      }
    },
    checkRowDiffs (holidayId, instanceId) {
      let hasDiffs = false
      const currentHoliday = this.filteredData.find(
        item => item.id === holidayId
      )
      const originalHoliday = this.originalData.find(
        origin => origin.id === holidayId
      )
      const instanceIndex = currentHoliday.instances.findIndex(
        inst => inst.id === instanceId
      )
      if (instanceIndex > -1) {
        hasDiffs = !_.isEqual(
          currentHoliday.instances[instanceIndex], originalHoliday.instances[instanceIndex]
        )
      }
      return hasDiffs
    },
    formatAttributeFields (field, value) {
      switch (field) {
        case 'price':
        case 'seats':
          return Number(value)
        default:
          return value
      }
    },
    closeExpandedRows () {
      const elementsShow = document.querySelectorAll('.show')
      const elementsShowHeight = document.querySelectorAll('.show-height')
      const iconElemRefs = document.querySelectorAll('.ref-badge--icon')
      if (elementsShow) {
        elementsShow.forEach(elem => {
          elem.classList.toggle('show')
        })
      }
      if (elementsShowHeight) {
        elementsShowHeight.forEach(elem => {
          elem.classList.toggle('show-height')
        })
      }
      if (iconElemRefs) {
        iconElemRefs.forEach(elem => {
          const refElem = elem.parentNode.querySelector('.ref-badge--id')
          if (refElem) {
            elem.classList.remove('ref-badge--icon')
            refElem.classList.add('ref-hidden')
          }
        })
      }
    },
    prepareLangs (values) {
      return Object.keys(values).map((key) => {
        return {
          value: key.toLowerCase(),
          label: values[key]
        }
      })
    },
    async requestHolidaysLite () {
      const companyId = this.loggedUser.company_id
      const payload = new FormData()
      payload.append('company_id', companyId)

      const reqResult = await this.axios
        .post(process.env.VUE_APP_API + '/holiday/list', payload)
        .then(result => {
          const { status } = result
          if ((status === 200 || status === 201) && result?.data) {
            const holidays = result.data['0']?.collection
            return { ok: true, data: holidays, status }
          }
          return { ok: false, data: [], status }
        })
        .catch(Utils.$handleHttpErrors)

      return reqResult
    }
  },
  created () {
    eventHub.$on('embed-holidays-modal', () => {
      this.openModal = false
    })

    eventHub.$on('add-holidays-modal', () => {
      this.openAddHolidays = false
    })

    eventHub.$on('calendarize-holidays-modal', () => {
      this.openCalendarizeHolidays = false
    })

    eventHub.$on('refresh-holidays', (refresh) => {
      if (refresh) this.requestHolidaysData()
    })

    eventHub.$on('start-finish-edit', () => {
      this.loader = this.$loading.show()
      this.isLoading = true
    })

    eventHub.$on('end-finish-edit', async () => {
      await this.requestHolidaysData(false)
      this.loader.hide()
      this.isLoading = false
    })

    eventHub.$on('request-holidays', async ({ closeModal, request }) => {
      if (closeModal) this.openAddHolidays = false
      if (request) await this.requestHolidaysData(true)
    })

    eventHub.$on('on-embed-code-holiday', (holiday) => {
      this.rowData = _.cloneDeep(holiday)
      this.openModal = true
    })

    eventHub.$on('on-edit-holiday', (holiday) => {
      this.onEditHoliday(_.cloneDeep(holiday))
    })

    eventHub.$on('on-clone-holiday', (holiday) => {
      this.handleCloneHoliday(_.cloneDeep(holiday))
    })

    eventHub.$on('on-delete-holiday', (holiday) => {
      this.showAlert('delete', `"${holiday.holiday_name}"`)
        .then(async (result) => {
          const { isConfirmed } = result
          if (isConfirmed) this.handleDeleteHoliday(_.cloneDeep(holiday))
        })
    })

    eventHub.$on('change-instance--status', ({ holiday, instance, event }) => {
      const holidayIndex = this.filteredData.findIndex(
        holid => holid.id === holiday.id
      )
      if (holidayIndex > -1) {
        const instanceIndex = this.filteredData[holidayIndex].instances.findIndex(
          inst => inst.id === instance.id
        )
        if (instanceIndex > -1) {
          const instanceId = instance.id
          const { checked } = event.target
          this.filteredData[holidayIndex].instances[instanceIndex].status = checked ? 1 : 0
          const hasDiffs = this.checkRowDiffs(holiday.id, Number(instanceId))
          const saveBtn = document.querySelector(`[data-id="save"][child-id="${instanceId}"]`)
          if (saveBtn) {
            saveBtn.classList[hasDiffs ? 'remove' : 'add']('action-hidden')
          }
        }
      }
    })

    eventHub.$on('change-instance--state', ({ holiday, instance, event }) => {
      const instanceId = instance.id
      const { value } = event.target
      const attrField = event.target.getAttribute('data-field')
      const normalizedValue = this.formatAttributeFields(attrField, value)
      const hasDiffs = normalizedValue !== instance[attrField]
      const saveBtn = document.querySelector(`[data-id="save"][child-id="${instanceId}"]`)
      if (saveBtn) {
        saveBtn.classList[
          value !== null && value !== undefined && value !== '' ? (hasDiffs ? 'remove' : 'add') : 'add'
        ]('action-hidden')
      }
    })

    eventHub.$on('save--instance--clicked', ({ holiday, instance }) => {
      this.showAlert('update', 'changes')
          .then(async (result) => {
            const { isConfirmed } = result
            if (isConfirmed) {
              this.handleBeforeSave(instance.id)
              const resp = await this.handleSaveInstance(holiday, instance)
              // const resp = this.userRole === 'operator' ? await this.handleSaveInstance(
              //   holiday, instance
              // ) : await this.handleSaveAgentInstance(
              //   holiday, instance
              // )
              if (resp.ok) {
                // this.$toast.success('Instance saved successfully!', {
                //   position: 'top-right',
                //   duration: 2000
                // })
              }
            } else {
              const hIndex = this.filteredData.findIndex(holi => holi.id === holiday.id)
              const instIndex = this.filteredData[hIndex].instances.findIndex(inst => inst.id === instance.id)
              const originHoliday = this.originalData.find(holi => holi.id === holiday.id)
              this.handleBeforeSave(instance.id)
              this.filteredData[hIndex].instances[instIndex] = _.cloneDeep(originHoliday.instances[instIndex])
            }
          })
    })

    eventHub.$on('clone--instance--clicked', ({ holiday, instance }) => {
      this.$swal({
        icon: 'info',
        title: 'Do you really want to clone Instance?',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#59c154'
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          this.loader = this.$loading.show()
          this.isLoading = true
          const holidayClone = _.cloneDeep(holiday)
          const instanceClone = _.cloneDeep(instance)
          const payload = this.generateCloneInstancePayload(
            holidayClone, instanceClone
          )
          const resp = await this.requestUpdateInstance(payload)
          if (resp.ok) {
            await this.requestHolidaysData()
          }
          this.loader.hide()
          this.isLoading = false
        }
      })
    })

    eventHub.$on('edit-clone--instance', async ({ holiday, instance }) => {
      const holidayClone = _.cloneDeep(holiday)
      const instanceClone = _.cloneDeep(instance)
      const payload = this.generateCloneInstancePayload(
        holidayClone, instanceClone
      )
      await this.requestUpdateInstance(payload)
      // const resp = await this.requestUpdateInstance(payload)
      // if (resp.ok) {
      //   // await this.requestHolidaysData()
      //   // const selectedRow = this.originalData.find(
      //   //   holiday => holiday.id === holidayClone.id
      //   // )
      //   const updatedHolidays = await this.requestHolidaysLite()
      //   if (updatedHolidays.ok) {
      //     const selectedRow = updatedHolidays?.data ? updatedHolidays.data.find(
      //       holiday => holiday.id === holidayClone.id
      //     ) : null
      //     if (selectedRow) {
      //       eventHub.$emit('edit-holiday-data', selectedRow)
      //       eventHub.$emit('enable-actions', true)
      //       if (instanceClone?.isNew) {
      //         const { data } = resp?.data || {}
      //         eventHub.$emit('add-temp-instance', { instance: data?.instance })
      //       }
      //     }
      //   }
      // }
    })

    eventHub.$on('delete--instance--clicked', ({ holiday, instance }) => {
      this.showAlert('delete', `instance with reference "${instance.reference}"`)
        .then(async (result) => {
          const { isConfirmed } = result
          if (isConfirmed) {
            const resp = this.handleDeleteInstance(instance.id)
            if (resp.ok) {}
          }
        })
    })
  },
  watch: {
    searchInput (newValue, oldValue) {
      const filteredData = this.tableData.rows.filter(row => {
        if (Object.keys(row).length > 0) {
          for (let index = 0; index < Object.keys(row).length; index++) {
            if (row[Object.keys(row)[index]].toString().toLowerCase().includes(this.searchInput)) return true
          }
        }
        return false
      })
      this.filteredData = !this.searchInput || this.searchInput === '' ? this.tableData.rows : filteredData
    },
    loggedUser (newUser, oldUser) {
      const { role } = newUser
      this.userRole = role.toLowerCase()
    },
    langs (newValues, oldValues) {
      this.formatedLangs = this.prepareLangs(newValues)
    }
  },
  async mounted () {
    this.loggedUser = JSON.parse(localStorage.getItem('userData'))
    const { status } = this.loggedUser || {}
    if (status === 1 || status === '1') await this.requestHolidaysData(true)
    this.token = localStorage.getItem('accessToken')
  },
  updated () {

  },
  unmounted () {
    if (this.observer) this.observer.disconnect()
    if (this.isLoading && this.loader) this.loader.hide()
    this.selectedRows = []
    this.userRole = null

    eventHub.$off('embed-holidays-modal')
    eventHub.$off('add-holiday-modal')
    eventHub.$off('refresh-holidays')
    eventHub.$off('request-holiday')
    eventHub.$off('embed-generate-code')
    eventHub.$off('on-edit-holiday')
    eventHub.$off('on-clone-holiday')
    eventHub.$off('on-delete-holiday')
    eventHub.$off('request-holidays')
    eventHub.$off('change-instance--status')
    eventHub.$off('save--instance--clicked')
    eventHub.$off('clone--instance--clicked')
    eventHub.$off('delete--instance--clicked')
    eventHub.$off('edit-clone--instance')
    eventHub.$off('start-finish-edit')
    eventHub.$off('end-finish-edit')
  }
}
</script>

<style lang="scss">

#sf-holidays-table {

  .btn-secondary-outline i {
    pointer-events: none;
  }
  .search-control {
    margin-right: 25px;
  }
  .card {
    padding: 0;
    border: none;
    background-color: transparent;

    .card-body {
      padding: 0;

      .card-title {
        display: none;
      }

      #dataTables-holidays_wrapper {
        .thead-dark th {
          background-color: #eff2f7;
          color: #6c757d;
          border-color: transparent;
        }

        .row:nth-child(2) {
            .col-sm-12 {
                min-height: 65vh;
            }
        }
        .row.table-footer:nth-child(3) {
          margin-top: 20px;

          div:nth-child(1){
            order: 2;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            div {
              color: #131523;
              opacity: 0.35;
            }
          }
          div:nth-child(2){
            order: 3;
            text-align: right;

            span:nth-child(1) {
              font-size: 14px;
              color: #7E84A3;
            }
            select:nth-child(2) {
              margin-left: 10px;
              width: 57px;
              height: 36px;
              border: 1px solid #E2E2E2;
              border-radius: 5px;
            }
            span:nth-child(3), select:nth-child(4) {
              display: none;
            }
          }
          div:nth-child(3){
            order: 1;
            display: flex;
            justify-content: flex-start;

            .pagination {

              .page-item a {
                border: none;
                margin-left: 4px;
                background-color: transparent;
              }

              .page-item.disabled a:not([aria-label="Previous"]):not([aria-label="Next"]) {
                background-color: $primary;
                color: #ffffff;
                border-radius: 5px;
              }
              .page-item {
                display: flex;
                align-items: center;

                .page-link:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
              .page-item .page-link {
                padding: .5rem .85rem;
                color: #707070;
              }
              .page-item .page-link[aria-label="Previous"] {
                height: 30px;
                background-image: url('../assets/img/chevron-left.svg');
                background-repeat: no-repeat;
                margin-top: 5px;

                span {display: none;}
              }
              .page-item .page-link[aria-label="Next"] {
                height: 30px;
                background-image: url('../assets/img/chevron-right.svg');
                background-repeat: no-repeat;
                margin-top: 5px;

                span {display: none;}
              }

              li:first-child,
              li:last-child {
                display: none;
              }
            }
          }
        }

        .h-status-wrapper {
          width: 100%;
          display: flex;
          flex-flow: column;
          align-items: flex-start;

          .h-status--container {
            width: 100%;
            display: inline-flex;
            align-items: center;
          }

          .h-status {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 8px;
          }
          .live {
            background-color: #60CE5B;
          }
          .draft {
            background-color: #EF6565;
          }
          span {
            width: 100%;
            font-size: 0.65rem;

            .inst-mark {
              letter-spacing: 1px;
              color: #c7c7c7;
              margin-left: 4px;
            }
          }
        }
        .sub-status-wrapper {
            display: inline-flex;
            align-items: center;
            margin-top: 5px;

            .h-status {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 8px;
            }

            .live {
              background-color: #60CE5B;
            }
            .draft {
              background-color: #EF6565;
            }
            span {
              font-size: 0.6rem;
            }

            .switch--wrapper {

              .switch {
                position: relative;
                display: inline-block;
                width: 32px;
                height: 14px;
                margin-bottom: 0;
              }
              .switch input {
                opacity: 0;
                width: 0;
                height: 0;
              }
              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #EF6565;
                -webkit-transition: .4s;
                transition: .4s;
              }
              .slider:before {
                position: absolute;
                content: "";
                height: 12px;
                width: 12px;
                left: 3px;
                bottom: 1px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
              }
              input:checked + .slider {
                background-color: #60CE5B;
              }
              input:focus + .slider {
                box-shadow: 0 0 1px #55595c;
              }
              input:checked + .slider:before {
                -webkit-transform: translateX(13.5px);
                -ms-transform: translateX(13.5px);
                transform: translateX(13.5px);
              }
              .slider.round {
                border-radius: 34px;
              }
              .slider.round:before {
                border-radius: 50%;
              }

              .state--wrapper {
                position: relative;
                bottom: 1px;
                margin-left: 5px;
              }
            }

        }

        .r-operator-logo {
          display: flex;
          width: fit-content;
          background-color: #e1e8edad;
          padding: 5px 10px;
          border-radius: 6px;

          img {
            width: 1.05rem;
            height: 1.05rem;
            margin-right: 5px;
            border-radius: 50%;
          }
          span {
            font-size: 0.65rem;
            display: flex;
            align-items: flex-end;
            color: #202020;
          }
        }

        tbody {

          .r-date-wrapper {
            width: 70%;
          }

          .collapse-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              font-size: 18px;
              transform: rotate(0deg);
              transition-duration: 0.2s;
              transition-property: transform;
            }
          }
          .collapse-icon.w-instances {
            cursor: pointer;
          }

          .collapse-icon.w-instances:hover {
            background-color: #afb2b530;
          }
          .expanded-row.collapse-icon i {
            transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
          }
          .holy-name--wrapper,
          .inst-name--wrapper {
            display: inline-flex;
            overflow: hidden;
          }
          .ref-badge {
            margin-top: 3px;
            margin-left: 6px;
            font-size: 0.55rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            .h-name-mark {
              color: #b3c7d1;
            }
            .h-id-mark {
              width: 15px;
              height: 15px;
              border-radius: 5px;
              border: 1px solid #b3c7d1;
              padding: 2px 3px;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .ref-badge--icon {
              background-color: #b3c7d1;
              color: #FFF;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
            .ref-badge--id {
              height: 15px;
              width: auto;
              padding: 2px 12px 2px 5px;
              color: #b3c7d1;
              border: 1px solid #b3c7d1;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              margin-left: -2px;
              display: flex;
              align-items: center;
            }
            .ref-hidden {
              display: none;
            }
          }

          .h-int {
            font-size: 0.7rem;
            letter-spacing: 1px;
            margin-left: 6px;
            color: #c7c7c7;
            display: flex;
            align-items: center;
          }

          .m-left-5 {
            margin-left: 7px;
            position: relative;
          }
          .m-left-4 {
            margin-left: 4px;
            position: relative;
          }
          .m-left-3 {
            margin-left: 3px;
            position: relative;
          }
          .m-left-2 {
            margin-left: 2px;
            position: relative;
          }

          .form-control {
            height: 38px;
            font-size: 0.75rem;
            font-weight: 600 !important;
          }
          .form-control.date-input {
            width: 130px;
            padding: 5px 12px;
          }
          input[type="date"]::-webkit-calendar-picker-indicator {
            font-size: 12px;
            margin-left: 0;
            padding-left: 0;
            filter: opacity(0.4);
          }
          .form-control.price-input {
            width: 80px;
            position: relative;
            z-index: 1;
            padding-left: 22px;
          }
          .input-symbol {
            height: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            left: 10px;
            z-index: 2;
          }
          .form-control.places-input {
            width: 45px;
          }

        }

        .agent-type {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          input[type=checkbox] {
            visibility: hidden;
          }
        }

      }

      .table-bordered td, .table-bordered th {
        border: none;
        border-top: 1px solid #dee2e6;
        vertical-align: middle;
        background-color: #ffffff;

        .holidays-actions {
          display: flex;
          justify-content: flex-end;

          .btn-secondary-outline {
            border-radius: 50%;
            color: #919fab;
            border: 1px solid #919fab;
          }
          .btn-secondary-outline.action-delete:not(.action-disabled):hover {
            color: #EF6565;
            border-color: #EF6565;
          }
          .btn-secondary-outline.action-com:not(.action-disabled):hover {
            color: #425c70;
            border-color: #425c70;
          }
          .btn-secondary-outline.action-save {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.9rem;
          }
          .btn-secondary-outline.action-save:hover {
            color: #60CE5B;
            border-color: #60CE5B;
          }
          .btn-secondary-outline.action-hidden {
            display: none;
          }
          .btn-secondary-outline.custom-icon {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .a-clone {
            width: 13px;
            height: 13px;
            background: url('../assets/img/clone.svg');
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
          }
          .btn-secondary-outline:not(.action-disabled):hover .a-clone {
            background: url('../assets/img/clone-hover.svg');
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
          }
        }
      }
    }
  }

  .btn-outline-delete {
    color: #ef6565;

    i {
      color: #ef6565;
    }
  }
  .btn-outline-delete:hover {
    background-color: #ef6565;
    color: #ffffff;

    i {
      color: #ffffff;
    }
  }

}
</style>
