<template>
    <div class="main">
        <holidays v-if="activeTab == 'holidays'"></holidays>
        <accessView v-if="activeTab == 'access-view'"></accessView>
       <!--  <activity v-if="activeTab == 'activity'"></activity> -->
        <usersList v-if="activeTab == 'usersList'"></usersList>
        <logslist v-if="activeTab == 'logslist'"></logslist>
        <currencies v-if="activeTab == 'currencies'"></currencies>
    </div>
</template>

<script>
import eventHub from '../utils/eventHub'
import utils from '../utils/Utils'
import AccessView from './AccessView.vue'
import Holidays from './Holidays.vue'
/* import Activity from './Activity.vue' */
import UsersList from './UsersList.vue'
import LogsList from './LogsList.vue'
import Currencies from './Currencies.vue'

export default {
    name: 'dashboard',
    prop: ['tabActive'],
    components: {
        holidays: Holidays,
        accessView: AccessView,
   /*      activity: Activity, */
        usersList: UsersList,
        logslist: LogsList,
        currencies: Currencies
    },
    data () {
        return {
            activeTab: 'holidays',
            token: localStorage.getItem('accessToken')
        }
    },
    methods: {
        async refreshTokenRequest () {
            const data = new FormData()
            const reqResult = await this.axios.post(process.env.VUE_APP_API + '/session/token-refresh', data, {
                headers: {

                }
            }).then(resp => {
                const newToken = resp?.data?.access_token
                localStorage.setItem('accessToken', newToken)
                return resp
            }).catch(utils.$handleHttpErrors)
            return reqResult
        }
    },
    created () {
        utils.$startWatchInactivity()
        this.token = localStorage.getItem('accessToken')

        eventHub.$on('refresh-token', (value) => {
            this.refreshTokenRequest()
        })
    },
    mounted () {
        this.token = localStorage.getItem('accessToken')
        eventHub.$on('selected-tab', (tabOption) => {
            if (tabOption) {
              this.activeTab = tabOption
            }
        })
    },
    unmounted () {
        utils.$stopWatchInactivity()
        this.token = null
    }
}
</script>

<style lang="scss" scoped>

</style>
